import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Skeleton } from '@mui/material';
import { useAuth } from "../Hooks/AuthProvider";
const PrivateRoute = () => {
    const auth = useAuth();
    const loading = auth.loading;
    const isAuthenticated = !!localStorage.getItem('user');
    const isAuthorized = auth.isAuthorized;
    if(loading) {
        <Skeleton/> 

     } else if (!isAuthenticated && !isAuthorized) {
        return <Navigate to="/401" />;

    } else {

        return <Outlet />;
    }
};

export default PrivateRoute;
