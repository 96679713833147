import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, Select, MenuItem, Skeleton, Alert } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import appColor from '../../Style/appColor';
import EditIcon from '@mui/icons-material/Edit';
import { ApiUrl } from '../../ApiUrl';
import { CheckListStatus } from '../../Model/Enum/CheckListStatus';
import { CheckListType } from '../../Model/Enum/CheckListType';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import * as XLSX from 'xlsx';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { CheckListStatusDto } from '../../Model/Dto/CheckListStatusDto';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoIcon from '@mui/icons-material/Info';
import CheckListViewDetail from './CheckListViewDetail';
import { GetApp } from '@mui/icons-material';

function CheckListStandardPage() {
    const navigate = useNavigate();

    const [checkLists, setCheckLists] = useState([]);
    const [checkListStatuses, setCheckListStatuses] = useState([]);
    const [checkListSelectedCode, setCheckListSelectedCode] = useState("");
    const [checkListSelectedCurrentStatus, setCheckListSelectedCurrentStatus] = useState("");
    const [checkListSelectedId, setCheckListSelectedId] = useState(0);
    const [checkListSelectedStatus, setCheckListSelectedStatus] = useState("");
    const [typeAlert, setTypeAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");

    const [isUpdateStatusModalOpen, setIsUpdateStatusModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const handleUpdateStatusModalOpen = (row) => {
        getAllCheckListStatus();
        setCheckListSelectedId(row.id);
        setCheckListSelectedCode(row.code);
        setCheckListSelectedCurrentStatus(row.status);
        setCheckListSelectedStatus(CheckListStatus[row.status]);
        setIsUpdateStatusModalOpen(true);
    }

    const handleUpdateStatusModalClose = () => {setIsUpdateStatusModalOpen(false); setCheckListSelectedId(0); setCheckListSelectedCode(""); setCheckListSelectedCurrentStatus(""); setCheckListSelectedStatus(""); }

    const handleDeleteModalOpen = (row) => { setIsDeleteModalOpen(true); setCheckListSelectedId(row.id); }
    const handleDeleteModalClose = () => { getCheckListList(); setIsDeleteModalOpen(false); setCheckListSelectedId(0); }

    const handleUpdateModalOpen = (row) => { setIsDetailModalOpen(true); setCheckListSelectedId(row.id); }
    const handleUpdateModalClose = () => { setIsDetailModalOpen(false); setCheckListSelectedId(0); }

    const handleCheckListStatusUpdate = () => {
        const entries = Object.entries(CheckListStatus);
        const checkListStatusDto = new CheckListStatusDto();
        console.log("Entries: ", entries);
        const foundEntry = entries.find(([_, value]) => value === checkListSelectedStatus);

        if (!foundEntry) {
            handleUpdateStatusModalClose();
            console.error("CheckListStatus entry not found for the selected status:", checkListSelectedStatus);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating checklist: status not found");
            return;
        }

        checkListStatusDto.checkListId = checkListSelectedId;
        checkListStatusDto.statusId = foundEntry[0];

        fetch(ApiUrl.checkListEndpoint + "/CheckListStatus", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(checkListStatusDto),
        })
        .then(() => {
            handleUpdateStatusModalClose();
            getCheckListList();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Checklist updated successfully");
        })
        .catch(error => {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating checklist");
        });
    }

    const handleCheckListDelete = () => {
        fetch(ApiUrl.checkListEndpoint + "/" + checkListSelectedId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(() => {
            handleDeleteModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Checklist deleted successfully");
        })
        .catch(error => {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting checklist");
        });
    }

    useEffect(getCheckListList, []);

    function getCheckListList() {
        setLoading(true);
        fetch(ApiUrl.checkListEndpoint)
            .then(response => response.json())
            .then(json => {
                setCheckLists(json);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }

    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function getAllCheckListStatus() {
        fetch(ApiUrl.checkListEndpoint + "/CheckListStatus")
            .then(response => response.json())
            .then(json => setCheckListStatuses(json))
            .catch(error => console.error(error));
    }

    const navigateToCreateCheckListPage = () => navigate('create-checklist');

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                 <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcel()}>
                    <GetApp style={{marginRight: 10}}/>
                    EXPORT
                </Button>
            </GridToolbarContainer>
        );
    }

    const exportToExcel = () => {
        const headers = columns.filter(column => column.field !== 'action').map(column => column.headerName);
  
        const worksheet = XLSX.utils.json_to_sheet([headers, ...checkLists.map(rowData =>
        columns.map(column => {
            switch (column.field) {
                case 'status':
                    return CheckListStatus[rowData[column.field]];
                case 'type':
                    return CheckListType[rowData[column.field]];
                default:
                    return column.valueGetter ? column.valueGetter({ row: rowData }) : rowData[column.field];
            }            
        })
        )], { skipHeader: true });
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Checklist');
        XLSX.writeFile(workbook, 'Checklist.xlsx');
      };  

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 100
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 150
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => CheckListStatus[params.value]
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            renderCell: (params) => CheckListType[params.value]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 100,
            valueGetter: (params) => params.row.subject.name
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 350
        },
        {
            field: 'ownerUser',
            headerName: 'Owner',
            width: 150,
            valueGetter: (params) => params.row.ownerUser.name + " " + params.row.ownerUser.surname
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 220,
            renderCell: (params) => (
                <div>
                    <InfoIcon style={{ cursor: 'pointer' }} onClick={() => handleUpdateModalOpen(params.row)} />
                    <VisibilityIcon style={{ cursor: 'pointer', marginLeft: 15 }} onClick={() => navigate('view-checklist/' + params.row.id)} />
                    <EditIcon style={{ cursor: 'pointer', marginLeft: 15 }} onClick={() => navigate('edit-checklist/' + params.row.id)} />
                    <TaskAltIcon style={{ cursor: 'pointer', marginLeft: 15 }} onClick={() => handleUpdateStatusModalOpen(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 15 }} onClick={() => handleDeleteModalOpen(params.row)} />
                </div>
            )
        }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const styleDetail = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1150,
        bgcolor: 'background.paper',
        boxShadow: 24,
        maxHeight: '600px',
        overflow: 'auto'
    };

    return (
        <Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography variant="h4" style={{ marginRight: 30 }}>CheckList Standard</Typography>
                <Button variant="contained" disableElevation onClick={navigateToCreateCheckListPage}>
                    <AddIcon style={{ marginRight: '15px' }} />Add new CheckList
                </Button>
            </Box>

            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>
            ) : (
                checkLists.length === 0 ?
                <Typography variant="h6" style={{ marginRight: 30 }}>No CheckLists yet</Typography>
                :
                <DataGrid
                    style={{ backgroundColor: appColor.white }}
                    rows={checkLists}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    pageSizeOptions={[10]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                />)
            }

            <Modal
                open={isUpdateStatusModalOpen}
                onClose={handleUpdateStatusModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>Update this CheckList status</Typography>
                    <p style={{ marginBottom: 20 }}>CheckList Code: {checkListSelectedCode} | Current status: {CheckListStatus[checkListSelectedCurrentStatus]}</p>
                    <Select
                        fullWidth
                        value={checkListSelectedStatus}
                        onChange={(event) => setCheckListSelectedStatus(event.target.value)}
                    >
                        {checkListStatuses.map((status) => <MenuItem key={status} value={status}>{status}</MenuItem>)}
                    </Select>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListStatusUpdate}>
                            <SaveIcon style={{ marginRight: '15px' }} />Update
                        </Button>
                        <Button variant="contained" color="error" disableElevation onClick={handleUpdateStatusModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDeleteModalOpen}
                onClose={handleDeleteModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>This operation will delete sensible data</Typography>
                    <p style={{ marginBottom: 15 }}><strong>[ ! ] Pay attention, this operation will delete sensible data [ ! ]</strong></p>
                    <p>By confirming this operation you will delete all occurrencies of this data with ID {checkListSelectedId}:</p>
                    <ul style={{ marginLeft: 20 }}>
                        <li>CheckList</li>
                        <li>CheckList Indicator</li>
                        <li>CheckList Specific Indicator</li>
                        <li>CheckList Rate</li>
                        <li>CheckList added to Audit</li>
                    </ul>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListDelete}>
                            <SaveIcon style={{ marginRight: '15px' }} />Confirm
                        </Button>
                        <Button variant="contained" color="error" disableElevation onClick={handleDeleteModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isDetailModalOpen}
                onClose={handleUpdateModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleDetail}>
                    <CheckListViewDetail id={checkListSelectedId} isView={true} />
                    <Box marginTop={3} marginLeft={3} marginBottom={3}>
                        <Button variant="contained" color="error" disableElevation onClick={handleUpdateModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default CheckListStandardPage;
