export class UserWithRole {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, name, surname, email, roleName, timeZone) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.name = name;
        this.surname = surname;
        this.email = email;
        this.roleName = roleName;
        this.timeZone = timeZone;
    }

    static fromJSON(json) {
        return new UserWithRole(
            json.id,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.name,
            json.surname,
            json.email,
            json.roleName,
            json.timeZone
        );
    }
}