import MainLayout from "./Components/MainLayout/MainLayout";
import Home from './Pages/Home';
import AuditCalendarPage from './Pages/AuditReporting/AuditCalendarPage';
import PlantLayoutPage from './Pages/Configuration/PlantLayoutPage';
import ProfilePage from "./Pages/Configuration/ProfilePage";
import CheckListStandardPage from "./Pages/Libraries/CheckListStandardPage";
import CreateCheckListPage from "./Pages/Libraries/CreateCheckListPage";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuditProgrammingPage from "./Pages/Planning/AuditProgrammingPage";
import CreateAuditPage from "./Pages/Planning/CreateAuditPage";
import './App.css';
import RolePermissionPage from "./Pages/Libraries/RolePermissionPage";
import SubjectPage from "./Pages/Libraries/SubjectPage";
import AuditInProgressPage from "./Pages/AuditReporting/AuditInProgressPage";
import AuditCorrectivePage from "./Pages/AuditReporting/AuditCorrectivePage";
import ArchiveAuditPage from "./Pages/Archive/ArchiveAuditPage";
import ViewCheckListPage from "./Pages/Libraries/ViewCheckListPage";
import EditCheckListPage from "./Pages/Libraries/EditCheckListPage";
import CompileAuditCheckListPage from "./Pages/AuditReporting/CompileAuditCheckListPage";
import ViewAuditCheckListPage from "./Pages/AuditReporting/ViewAuditCheckListPage";
import EditAuditCheckListPage from "./Pages/AuditReporting/EditAuditCheckListPage";
import EditAuditPage from "./Pages/Planning/EditAuditPage";
import NotAuthorized from "./Pages/NotAuthorized";
import AuthProvider from "./Hooks/AuthProvider";
import PrivateRoute from "./Hooks/PrivateRoute";
import Index from "./Pages/Planning/Index";
function App() {
    return (
        <Router>
            <AuthProvider>      
                <MainLayout>
                    <Routes>
                        <Route path='/401' element={<NotAuthorized />} />
                        <Route path='/' element={<Home />} />
                        <Route element={<PrivateRoute />}>
                            <Route path='audit-calendar' element={<AuditCalendarPage />} />
                            <Route path='configuration-plant' element={<PlantLayoutPage />} />
                            <Route path='configuration-user' element={<ProfilePage />} />
                            <Route path='libraries-standard' element={<CheckListStandardPage />} />
                            <Route path='libraries-standard/create-checklist' element={<CreateCheckListPage />} />
                            <Route path='libraries-standard/view-checklist/:checkListId' element={<ViewCheckListPage />} />
                            <Route path='libraries-standard/edit-checklist/:checkListId' element={<EditCheckListPage />} />
                            <Route path='libraries-subject' element={<SubjectPage />} />
                            <Route path='libraries-role-permission' element={<RolePermissionPage />} />
                            <Route path='planning-programming' element={<AuditProgrammingPage />} />
                            <Route path='audit-progress' element={<AuditInProgressPage />} />
                            <Route path='audit-corrective' element={<AuditCorrectivePage />} />
                            <Route path='audit-progress/compile-checklist/:auditId' element={<CompileAuditCheckListPage />} />
                            <Route path='audit-progress/view-checklist/:auditId' element={<ViewAuditCheckListPage />} />
                            <Route path='audit-progress/edit-checklist/:auditId/:readOnly' element={<EditAuditCheckListPage />} />
                            <Route path='archive-audit' element={<ArchiveAuditPage />} />
                            <Route path='planning-programming/create-audit/:auditId' element={<Index />} />
                            {/*<Route path='planning-programming/edit-audit/:auditId' element={<EditAuditPage />} />*/}
                            <Route path='planning-programming/edit-audit/:auditId' element={<Index />} />
                            <Route path='audit-progress/create-audit' element={<CreateAuditPage />} />
                            <Route path='archive-audit/create-audit' element={<CreateAuditPage />} />
                        </Route>
                    </Routes>
                </MainLayout>
            </AuthProvider>
        </Router>
  );
}

export default App;
