export class Department {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, name, code, description, plant) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.name = name;
        this.code = code;
        this.description = description;
        this.plant = plant;
    }

    static fromJSON(json) {
        return new Department(
            json.id,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.name,
            json.code,
            json.description,
            json.plant
        );
    }
}