export class AuditCheckListBestPractice {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, auditCheckListId, indicatorId, date, auditorRemarks, image,
        areaId, lineId, machineId, asset, description, previousSituation, nextSituation, expectedBenefit) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.auditCheckListId = auditCheckListId;
        this.indicatorId = indicatorId;
        this.date = date;
        this.auditorRemarks = auditorRemarks;
        this.image = image;
        this.areaId = areaId;
        this.lineId = lineId;
        this.machineId = machineId;
        this.asset = asset;
        this.description = description;
        this.previousSituation = previousSituation;
        this.nextSituation = nextSituation;
        this.expectedBenefit = expectedBenefit;
    }

    static fromJSON(json) {
        return new AuditCheckListBestPractice(
            json.id,
            json.created,
            json.createdBy,
            json.createdByEmail,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.auditCheckListId,
            json.indicatorId,
            json.date,
            json.image,
            json.areId,
            json.lineId,
            json.machineId,
            json.asset
        );
    }
}
