import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import appColor from '../../Style/appColor';
import { ApiUrl } from '../../ApiUrl';
import * as XLSX from 'xlsx';
import { AuditType } from '../../Model/Enum/AuditType';
import { AuditStatus } from '../../Model/Enum/AuditStatus';
import LoadingButton from '@mui/lab/LoadingButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { GetApp } from '@mui/icons-material';
import { convertDate } from '../../Utils/convertDate';

function ArchiveAuditPage() {
    const navigate = useNavigate();
    const [audits, setAudits] = useState([]);
    const [loadingExport, setLoadingExport] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(getAuditList, []);

    function getAuditList() {
        setLoading(true);
        fetch(ApiUrl.auditEndpoint + "/GetAllFromView?statuses=4,7,8")
            .then(response => response.json())
            .then(json => {
                setAudits(json)
                setLoading(false)
            })
            .catch(error => {
                console.error(error)
                setLoading(false)
            });
    }

    function exportPDF(auditId) {
        setLoadingExport(prevState => ({...prevState, [auditId]: true}));
        fetch(`${ApiUrl.reportEndpoint}/${auditId}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `Report_Audit${auditId}_${new Date().toISOString().slice(0, 10)}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
            })
            .catch(error => console.error('Fetch error:', error))
            .finally(() => setLoadingExport(prevState => ({...prevState, [auditId]: false})));
    }
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcel()}>
                    <GetApp style={{ marginRight: 10 }} />
                    EXPORT
                </Button>
            </GridToolbarContainer>
        );
    }

    const exportToExcel = () => {
        const headers = columns.filter(column => column.field !== 'action').map(column => column.headerName);

        const worksheet = XLSX.utils.json_to_sheet([headers, ...audits.map(rowData =>
            columns.map(column => {
                switch (column.field) {
                    case 'status':
                        return AuditStatus[rowData[column.field]];
                    case 'type':
                        return AuditType[rowData[column.field]];
                    default:
                        return column.valueGetter ? column.valueGetter({ row: rowData }) : rowData[column.field];
                }         
            })
        )], { skipHeader: true });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'ArchiveAudit');
        XLSX.writeFile(workbook, 'archiveAudit.xlsx');
    };

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 70,
            renderCell: (params) => params.row.auditId
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 150,
            renderCell: (params) => params.row.auditName
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => params.row.auditStatus
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 90,
            renderCell: (params) => params.row.auditType
        },
        {
            field: 'plant',
            headerName: 'Plant',
            width: 120,
            valueGetter: (params) => params.row.plantName
        },
        {
            field: 'department',
            headerName: 'Department',
            width: 120,
            valueGetter: (params) => params.row.departmentName
        },
        {
            field: 'area',
            headerName: 'Area',
            width: 120,
            valueGetter: (params) => params.row.areaNames
        },
        {
            field: 'line',
            headerName: 'Line',
            width: 120,
            valueGetter: (params) => params.row.lineNames
        },
        {
            field: 'machine',
            headerName: 'Machine',
            width: 120,
            valueGetter: (params) => params.row.machineNames
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.auditStartDate)
        },
        {
            field: 'endDate',
            headerName: 'End Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.endDate)
        },
        {
            field: 'dateCompleted',
            headerName: 'Date Completed',
            width: 120,
            valueGetter: (params) => convertDate(params.row.dateCompleted)
        },
        {
            field: 'filingDate',
            headerName: 'Filing Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.filingDate)
        },
        {
            field: 'leadAuditor',
            headerName: 'Lead Auditor',
            width: 150,
            valueGetter: (params) => params.row.leadAuditorEmail
        },
        {
            field: 'countdown',
            headerName: 'Countdown',
            width: 130,
            valueGetter: (params) => params.row.countdown + " days"
        },
        {
            field: 'checkListCount',
            headerName: 'N° CheckList',
            width: 120
        },
        {
            field: 'action',
            headerName: 'Export PDF',
            width: 130,
            renderCell: (params) => (
                <div>
                    {loadingExport[params.row.auditId] ? (
                        <LoadingButton loading loadingPosition='start' startIcon={<PictureAsPdfIcon />} style={{color: 'blue'}}/>
                    ) : (
                        <PictureAsPdfIcon style={{ cursor: 'pointer' }} onClick={() => exportPDF(params.row.auditId)} />
                    )}
                </div>
            )
        }
    ];

    return (
        <Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography variant="h4" style={{ marginRight: 30 }}>Archive Audit</Typography>
            </Box>
            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>

            ) : (
                <DataGrid
                    style={{ backgroundColor: appColor.white }}
                    rows={audits}
                    columns={columns}
                    getRowId={(row) => row.auditId}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    pageSizeOptions={[10]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                />)}
        </Box>
    );
}

export default ArchiveAuditPage;
