export class AuditCheckListDto {
    constructor(auditId, checkListId, indicators, deleted) {
        this.auditId = auditId;
        this.checkListId = checkListId;
        this.indicators = indicators;
        this.deleted = deleted
    }

    static fromJSON(json) {
        return new AuditCheckListDto(
            json.auditId,
            json.checkListId,
            json.indicators,
            json.deleted
        );
    }
}