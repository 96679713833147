import React, { useEffect}  from 'react';
import { Box } from '@mui/material';
import homeLogo from '../Style/logo/home-audix-logo.png'
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/AuthProvider";
import NotAuthorized from './NotAuthorized';
function Home() {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const tkn = searchParams.get('tkn');
    const accessTkn = searchParams.get('accsTkn');
    const sanitizedAccessToken = decodeURIComponent(accessTkn);
    const sanitizedToken = decodeURIComponent(tkn);
    const auth = useAuth();

    useEffect(() => {
        if (sanitizedToken && sanitizedToken !== 'null') {
            if (sanitizedAccessToken && sanitizedAccessToken !== 'null') {
                localStorage.setItem('accessToken', sanitizedAccessToken);
            }
            auth.login(sanitizedToken);
            navigate("/");
        } else {
            if (localStorage.getItem('user')) {
                auth.login(localStorage.getItem('user'))
                
            }
        }
    }, [sanitizedToken]);


    return (
        <div>
            {auth.user ? (
                <Box>
                    <p style={{ marginBottom: 50 }}>Home</p>
                    <img src={homeLogo} alt='Audix Logo' height='100%' />
                </Box>
            ) : 
                <NotAuthorized />
            }
        </div>
    );
}

export default Home;