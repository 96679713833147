export class CheckListStatusDto {
    constructor(checkListId, statusId) {
        this.checkListId = checkListId;
        this.statusId = statusId;
    }

    static fromJSON(json) {
        return new CheckListStatusDto(
            json.checkListId,
            json.statusId
        );
    }
}