import React, { useState, useContext } from 'react';
import BaseTreeItem from './BaseTreeItem';
import { Box, Typography, Modal, Button, TextField, TextareaAutosize, Skeleton, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import AdjustIcon from '@mui/icons-material/Adjust';
import { Line } from '../../Model/Line';
import LineItem from './LineItem';
import { TreeContext } from './TreeContext';
import { Area } from '../../Model/Area';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import { ApiUrl } from '../../ApiUrl';
import { useAuth } from "../../Hooks/AuthProvider";


const AreaItem = ({ label, nodeId, areaId, onEdit }) => {
    const auth = useAuth();
    const [lines, setLines] = useState([]);
    const { expanded, setExpanded } = useContext(TreeContext);
    const [isApiCalled, setIsApiCalled] = useState(false);

    const [isLineModalOpen, setIsLineModalOpen] = useState(false);
    const [isLineModaEdit, setIsisLineModaEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [lineEditId, setLineEditId] = useState(0);
    const handleOpen = () => { setIsLineModalOpen(true); setIsisLineModaEdit(false)};
    const handleClose = () => {
        setIsLineModalOpen(false);
        setIsisLineModaEdit(false);
      setFormName('');
      setFormCode('');
      setFormDesc('');
      setFormNameError(false);
    };

    const handleEditModalOpen = (line) => {
        setIsLineModalOpen(true);
        setIsisLineModaEdit(true);
        setFormCode(line.code);
        setFormName(line.name);
        setFormDesc(line.description)
    }

  const [formName, setFormName] = useState('');
  const [formCode, setFormCode] = useState('');
  const [formDesc, setFormDesc] = useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [formNameError, setFormNameError] = useState(false);

  const handleNameChange = (event) => {
    setFormName(event.target.value);
    };

    const handleCloseSnackbar = (reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setOpen(false);
  };

    const handleCodeChange = (event) => {
        setFormCode(event.target.value);
    };

    const handleDescChange = (event) => {
        setFormDesc(event.target.value);
    };

    const handleEdit = () => {
        onEdit(areaId)
    }
    const handleChildEdit = (lineId) => {
        setLineEditId(lineId)
        const line = lines.find(x => x.id === lineId)
        handleEditModalOpen(line)
    }

  const handleLineSave = async () => {
    const line = new Line();
    const area =  new Area();
    area.id = areaId;
    line.area = area;
    line.name = formName;
    line.code = formCode;
    line.description = formDesc;
      if (auth) {
          line.createdBy = auth.user?.id;
      }
    let isNameError = false;

    if(!formName.trim()) {
      setFormNameError(true);
      isNameError = true;
    } else {
      setFormNameError(false);
      isNameError = false;
    }

    if(isNameError) {
      return;
    }

    fetch(ApiUrl.lineEndpoint, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(line),
    })
    .then(() => {
      handleClose();
      getData();
      setOpen(true);
      setTypeAlert("success");
      setMessageAlert("Line added successfully");
    })
    .catch(error => {
      console.error(error);
      setOpen(true);
      setTypeAlert("error");
      setMessageAlert("Error adding Line");
    });
  }

  const handleLineUpdate = async () => {
      const line = new Line();
      const area = new Area();
      area.id = areaId;
      line.area = area;
        line.id = lineEditId;
      line.name = formName;
      line.code = formCode;
      line.description = formDesc;
      if (auth) {
          line.modifiedBy = auth.user?.id;
      }
    let isNameError = false;

    if(!formName.trim()) {
      setFormNameError(true);
      isNameError = true;
    } else {
      setFormNameError(false);
      isNameError = false;
    }

    if(isNameError) {
      return;
    }

    fetch(ApiUrl.lineEndpoint, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(line),
    })
    .then(() => {
      handleClose();
      getData();
      setOpen(true);
      setTypeAlert("success");
      setMessageAlert("Line updated successfully");
    })
    .catch(error => {
      console.error(error);
      setOpen(true);
      setTypeAlert("error");
      setMessageAlert("Error updating Line");
    });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  function getData() {
    fetch(`${ApiUrl.lineEndpoint}/GetAllByArea/${areaId}`)
      .then(response => response.json())
      .then(json => setData(json))
      .catch(error => console.error(error));
  }

  const handleClick = async () => {
    if (!isApiCalled) {
      getData();
    }

    const currentIndex = expanded.indexOf(nodeId);
    const newExpanded = [...expanded];

    if (currentIndex === -1) {
      newExpanded.push(nodeId);
    } else {
      newExpanded.splice(currentIndex, 1);
    }

    setExpanded(newExpanded);
  };

  function setData(json) {
      const lines = json.map(item => Line.fromJSON(item));
      setIsApiCalled(true);
      setLines(lines);
      setExpanded((prevExpanded) => [...prevExpanded, String(nodeId)]);
  }

  const itemLabel = (
    <Box display="flex" alignItems="center" style={{ padding: '15px' }}>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <AdjustIcon />
        <Typography variant="body1" style={{ marginLeft: 10 }}>{label}</Typography>
      </Box>
      <Tooltip placement="bottom" title="Add a new Line">
        <AddIcon onClick={handleOpen} style={{ marginRight: "15px" }} />
      </Tooltip>
      <Tooltip placement="bottom" title="Edit this Area">
        <EditIcon onClick={handleEdit} style={{ marginRight: "15px" }} />
      </Tooltip>
    </Box>
  );

  return (
    <Box>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert severity={typeAlert}>
            {messageAlert}
        </Alert>
      </Snackbar>

      <BaseTreeItem nodeId={nodeId} label={itemLabel} onClick={handleClick}>
            {lines.map(line => (
            <LineItem onEdit={handleChildEdit} key={`3${line.id}`} nodeId={`3${line.id}`} lineId={line.id} label={`${line.name}${line.code ? ` (${line.code})` : ''}`} />
          ))}
      </BaseTreeItem>
      <Modal
        open={isLineModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h5" component="h2">{isLineModaEdit ? "Edit Line" : "Add a new Line"}</Typography>
          <form>
            <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
              <TextField
                error={formNameError}
                helperText={formNameError ? "Name is required" : ""}
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginRight: 15 }}
                value={formName}
                onChange={handleNameChange}
              />
            </Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
                <TextField
                label="Code"
                variant="outlined"
                fullWidth
                style={{ marginRight: 15 }}
                value={formCode}
                onChange={handleCodeChange}
                />
            </Box>
            <Box display="flex" alignItems="center" style={{ marginTop: 16 }}>
                <TextareaAutosize
                    placeholder="Description"
                    variant="outlined"
                    fullWidth
                    style={{
                        width: '100%',
                        marginRight: 15,
                        minHeight: '56px',
                        padding: '8px',
                        border: '1px solid #ced4da',
                        borderRadius: '4px',
                        resize: 'none',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                    }}
                    value={formDesc}
                    onChange={handleDescChange}
                />
            </Box>
            <Box marginTop={3} display="flex" alignItems="center">
                {isLineModaEdit ?
                    <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleLineUpdate}>
                        <SaveIcon style={{ marginRight: '15px' }} />Update
                    </Button> :
                    <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleLineSave}>
                        <SaveIcon style={{ marginRight: '15px' }} />Save
                    </Button>
                }
              <Button variant="contained" color="error" disableElevation onClick={handleClose}>Close</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default AreaItem;
