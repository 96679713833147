import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, TextField, RadioGroup, FormControlLabel, Radio, Skeleton, Alert } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import appColor from '../../Style/appColor';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ApiUrl } from '../../ApiUrl';
import * as XLSX from 'xlsx';
import { Subject } from '../../Model/Subject';
import { useAuth } from "../../Hooks/AuthProvider";
import { GetApp } from '@mui/icons-material';

function SubjectPage() {
    const auth = useAuth();
    const [subjectList, setSubjectList] = useState([]);
    const [isSubjectModalOpen, setIsSubjectModalOpen] = useState(false);
    const [isSubjectModalEdit, setIsSubjectModalEdit] = useState(false);
    const [formSubjectNameError, setFormSubjectNameError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [formSubjectId, setFormSubjectId] = useState('');
    const [formSubjectName, setFormSubjectName] = useState('');
    const [formSubjectType, setFormSubjectType] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [messageAlert, setMessageAlert] = useState('');
    
    const handleFormSubjectNameChange = (event) => { setFormSubjectName(event.target.value); }
    const handleFormSubjectTypeChange = (event) => { setFormSubjectType(event.target.value); }
    const handleSubjectModalClose = () => { setIsSubjectModalOpen(false); setFormSubjectName(''); setFormSubjectNameError(false); }
    const handleSubjectModalOpen = () => { setIsSubjectModalOpen(true); setIsSubjectModalEdit(false); }
    const handleSubjectEditModalOpen = (auditSubject) => {
        setIsSubjectModalOpen(true);
        setIsSubjectModalEdit(true);
        setFormSubjectName(auditSubject.name);
        setFormSubjectId(auditSubject.id);
        setFormSubjectType(auditSubject.type)
    }

    const handleSubjectSave = async () => {
        try {            
            let isNameError = false;

            if(!formSubjectName.trim()) {
                setFormSubjectNameError(true);
                isNameError = true;
            } else {
                setFormSubjectNameError(false);
                isNameError = false;
            }

            if(isNameError) {
                return;
            }

            const subject = new Subject();
            subject.name = formSubjectName;
            subject.type = formSubjectType;
            if (auth) {
                subject.createdBy = auth.user?.id;
            }

            fetch(ApiUrl.subjectEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(subject),
            })
            .then(getSubjectList)
            .catch(error => {
                console.log(error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error method post");
            })

            handleSubjectModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Subject added successfully");

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding subject");
        }
    }

    const handleSubjectUpdate = async () => {
       try {
            let isNameError = false;

            if(!formSubjectName.trim()) {
                setFormSubjectNameError(true);
                isNameError = true;
            } else {
                setFormSubjectNameError(false);
                isNameError = false;
            }

            if(isNameError) {
                return;
            }

            const subject = new Subject();
            subject.id = formSubjectId;
            subject.type = formSubjectType;
            subject.name = formSubjectName;
            if (auth) {
                subject.modifiedBy = auth.user?.id;
            }

            fetch(ApiUrl.subjectEndpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(subject),
            })
            .then(getSubjectList)
            .catch(error => {
                console.log(error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error method put");
            })

            handleSubjectModalClose();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Subject updated successfully");

       } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating subject");
       }
    }

    useEffect(() => {
        getSubjectList();
    }, []);

    function getSubjectList() {
        setLoading(true);
        fetch(ApiUrl.subjectEndpoint)
            .then(response => response.json())
            .then(json => {
                setSubjectList(json);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }

    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    function CustomToolbar() {
        return (
             <GridToolbarContainer>
                 <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcel()}>
                    <GetApp style={{marginRight: 10}}/>
                    EXPORT
                </Button>
            </GridToolbarContainer>
        );
    }

    const exportToExcel = () => {
        const headers = columns.filter(column => column.field !== 'action').map(column => column.headerName);
  
        const worksheet = XLSX.utils.json_to_sheet([headers, ...subjectList.map(rowData =>
        columns.map(column => {
            if (column.valueGetter) {
                return column.valueGetter({ row: rowData });
            } else {
                return rowData[column.field];
            }  
        })
        )], { skipHeader: true });
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'SubjectList');
        XLSX.writeFile(workbook, 'Subjectlist.xlsx');
      }; 

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 80
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 300
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            valueGetter: (params) => params.row.type === 1 ? "Audit" : "CheckList"
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => handleSubjectEditModalOpen(params.row)} />
                </div>
            )
        }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>            
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography variant="h4" style={{ marginRight: 30 }}>Subject</Typography>
                <Button variant="contained" disableElevation onClick={handleSubjectModalOpen}>
                    <AddIcon style={{ marginRight: '15px' }} />Add new Subject
                </Button>
            </Box>
            <Box>

                {loading ? (
                    <>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </>

                ) : (
                    subjectList.length === 0 ?
                    <Typography variant="h6" style={{ marginRight: 30 }}>No Audit Subject yet</Typography>
                :
                    <DataGrid
                        style={{ backgroundColor: appColor.white }}
                        rows={subjectList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        slots={{
                            toolbar: CustomToolbar,
                        }}
                        pageSizeOptions={[10]}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableRowSelectionOnClick
                    />
                )}
            </Box>

            <Modal
                open={isSubjectModalOpen}
                onClose={handleSubjectModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {isSubjectModalEdit ?
                        <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Subject</Typography> :
                        <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Subject</Typography>
                    }
                    <form>
                        <TextField
                            error={formSubjectNameError}
                            helperText={formSubjectNameError ? "Name is required" : ""}
                            label="Name"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={formSubjectName}
                            onChange={handleFormSubjectNameChange}
                        />
                        <p style={{marginTop: 15, marginBottom: 5}}>Subject Type</p>
                        <RadioGroup
                            value={formSubjectType}
                            onChange={handleFormSubjectTypeChange}
                        >
                            <FormControlLabel value="1" control={<Radio />} label="Audit" />
                            <FormControlLabel value="2" control={<Radio />} label="CheckList" />
                        </RadioGroup>
                        <Box marginTop={3} display="flex" alignItems="center">
                            {isSubjectModalEdit ?
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleSubjectUpdate}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Update
                                </Button> :
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleSubjectSave}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Save
                                </Button>
                            }
                            <Button variant="contained" color="error" disableElevation onClick={handleSubjectModalClose}>Close</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </Box>
    );
}

export default SubjectPage;
