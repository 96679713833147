export class CreateRolePermisionDto {
    constructor(roleId, permissionId) {
        this.roleId = roleId;
        this.permissionId = permissionId;
    }

    static fromJSON(json) {
        return new CreateRolePermisionDto(
            json.roleId,
            json.permissionId
        );
    }
}