import { useContext, createContext, useState, useEffect } from "react";
import { ApiUrl } from '../ApiUrl';
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";
import SplashScreen from "../Components/SplashScreen";
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();
    const debug = false;
    useEffect(() => {
        const storedToken = localStorage.getItem('user');
        if (storedToken) {
            const decryptedEmail = CryptoJS.AES.decrypt(storedToken, "audit-tool").toString(CryptoJS.enc.Utf8);
            getUser(decryptedEmail);
        } else {
            if (debug) {
                getUser('usertest6@radamdm.onmicrosoft.com');
            }
        }

    }, []);

    const login = (token) => {
        const decryptedEmail = CryptoJS.AES.decrypt(token, "audit-tool").toString(CryptoJS.enc.Utf8);
        getUser(decryptedEmail);
    };
    const logOut = () => {
        setUser(null);
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        window.location.href = `${ApiUrl.authRedirectUri}`;
    };

    const getUser = (email) => {
        setLoading(true)
        fetch(`${ApiUrl.userEndpoint}/getByEmail/${email}`)
            .then(response => response.json())
            .then(json => {
                if (json) {
                    if (json.email) {
                        setUser(json);
                        const encrypted = CryptoJS.AES.encrypt(json.email, "audit-tool").toString();
                        localStorage.setItem("user", encrypted);
                        setIsAuthorized(true)
                    }
                    else {
                        setIsAuthorized(false)
                        setUser(null);
                        localStorage.removeItem("user");
                        localStorage.removeItem("accessToken");
                        navigate('/401')
                    }
                }

            })
            .catch(error => {
                console.error('sono errore',error);
                setUser(null);
                setIsAuthorized(false)
                localStorage.removeItem("user");
                localStorage.removeItem("accessToken");
                navigate('/401')
            })
            .finally(() => {
                setLoading(false);
            });
    }
    if (!isAuthorized && loading) {
        return <SplashScreen />;
    }
    return (
        <AuthContext.Provider value={{ user, login, logOut, isAuthorized, loading }}>
            {children}
        </AuthContext.Provider>
    );

};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};