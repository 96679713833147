export class CheckListRate {
    constructor(checkListIndicator, checkListSpecificIndicator, rate, description, extendedDescription, id, isNew) {
        this.checkListIndicator = checkListIndicator;
        this.checkListSpecificIndicator = checkListSpecificIndicator;
        this.rate = rate;
        this.description = description;
        this.extendedDescription = extendedDescription;
        this.id = id;
        this.isNew = isNew;
    }

    static fromJSON(json) {
        return new CheckListRate(
            json.checkListIndicator,
            json.checkListSpecificIndicator,
            json.rate,
            json.description,
            json.extendedDescription,
            json.id,
            json.isNew
        )
    }
}