export const CheckListStatus = {
    1: "Draft",
    2: "Canceled",
    3: "Completed",
    4: "Copy",
    5: "Approved",
    6: "Expire",
    7: "New Edition",
    8: "Approved New Edition",
    10: "Completed New Edition",
}
