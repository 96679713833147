import React from 'react';
import { Box, Button } from '@mui/material';
import { ApiUrl } from '../ApiUrl';
function NotAuthorized() {
    const login = () => {
        window.location.href = `${ApiUrl.authRedirectUri}`;
    }

    return (
        <Box>
            <p style={{ marginBottom: 50 }}>You're either logged out or not authorized on this plant. Click below to Login</p>
            <Button variant="contained" color="primary" disableElevation onClick={login}>
                Login
            </Button>
        </Box>
    );
}

export default NotAuthorized;