import React from 'react';
import BaseTreeItem from './BaseTreeItem';
import { Box, Typography } from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

const MachineItem = ({ nodeId, machineId, label, onEdit }) => {
    const handleEdit = () => {
        onEdit(machineId)
    }

  const itemLabel = (
    <Box display="flex" alignItems="center" style={{ padding: '15px' }}>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <PrecisionManufacturingIcon />
        <Typography variant="body1" style={{ marginLeft: 10 }}>{label}</Typography>
      </Box>
      <Tooltip placement="bottom" title="Edit this Machine">
        <EditIcon onClick={handleEdit} style={{ marginRight: "15px" }} />
      </Tooltip>
    </Box>
  );

  return (
    <BaseTreeItem nodeId={nodeId} label={itemLabel} />
  );
};

export default MachineItem;
