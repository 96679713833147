import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, Alert, AlertTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,FormControl, Select, TextField, MenuItem, Modal, Checkbox, ListItemText } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestoreIcon from '@mui/icons-material/Restore';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { ApiUrl } from '../../ApiUrl';
import { CheckList } from '../../Model/CheckList';
import { CheckListIndicator } from '../../Model/CheckListIndicator';
import { CheckListStatus } from '../../Model/Enum/CheckListStatus';
import { CheckListType } from '../../Model/Enum/CheckListType';
import { useParams } from "react-router";
import { User } from '../../Model/User';
import appColor from '../../Style/appColor';
import DeleteIcon from '@mui/icons-material/Delete';
import { Audit } from '../../Model/Audit';
import { AuditSubject } from '../../Model/AuditSubject';
import { Plant } from '../../Model/Plant';
import { Department } from '../../Model/Department';
import { Area } from '../../Model/Area';
import { Line } from '../../Model/Line';
import { Machine } from '../../Model/Machine';
import { AuditUserDto } from '../../Model/Dto/AuditUserDto';
import { AuditAreaDto } from '../../Model/Dto/AuditAreaDto';
import { AuditLineDto } from '../../Model/Dto/AuditLineDto';
import { AuditMachineDto } from '../../Model/Dto/AuditMachineDto';
import { AuditCheckListDto } from '../../Model/Dto/AuditCheckListDto';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import dayjs from 'dayjs';
import { useAuth } from "../../Hooks/AuthProvider";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
        </GridToolbarContainer>
    );
}

function EditAuditPage() {
    const { auditId } = useParams();
    const auth = useAuth();
    const navigate = useNavigate();
    const navigateBack = () => navigate('/planning-programming');

    const [checkLists, setCheckLists] = useState([]);
    const [checkListIndicators, setCheckListIndicators] = useState([]);
    const [indicators, setIndicators] = useState([]);

    const [plants, setPlants] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [areas, setAreas] = useState([]);
    const [lines, setLines] = useState([]);
    const [machines, setMachines] = useState([]);

    const [users, setUsers] = useState([]);

    const [formSubjectList, setFormSubjectList] = useState([]);

    const [formTitle, setFormTitle] = useState("");
    const [formNote, setFormNote] = useState("");
    const [typeAlert, setTypeAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");
    const [noteIndicators, setNoteIndicators] = useState({});
    const [checkListSelectedDesc, setCheckListSelectedDesc] = useState("");
    const [checkListSelectedId, setCheckListSelectedId] = useState(0);
    const [formStartDate, setFormStartDate] = useState("");
    const [formEndDate, setFormEndDate] = useState("");
    const [formDailyHoursVisible, setFormDailyHoursVisible] = useState(false);
    const [formDailyHours, setFormDailyHours] = useState(0);

    const [formSelectedType, setFormSelectedType] = useState("");
    const [formSelectedSubject, setFormSelectedSubject] = useState("");
    const [formSelectedPlant, setFormSelectedPlant] = useState("");
    const [formSelectedDepartment, setFormSelectedDepartment] = useState("");
    const [formSelectedArea, setFormSelectedArea] = useState([]);
    const [formSelectedLine, setFormSelectedLine] = useState([]);
    const [formSelectedMachine, setFormSelectedMachine] = useState([]);

    const [formSelectedAuditor1, setFormSelectedAuditor1] = useState("");
    const [formSelectedAuditor2, setFormSelectedAuditor2] = useState("");
    const [formSelectedAuditor3, setFormSelectedAuditor3] = useState("");
    const [formSelectedAuditor4, setFormSelectedAuditor4] = useState("");
    const [formSelectedAuditor5, setFormSelectedAuditor5] = useState("");

    const [formCheckListsAdded, setFormCheckListsAdded] = useState([]);

    const [formCheckListIndicatorsRemoved, setFormCheckListIndicatorsRemoved] = useState([]);

    const [formTitleError, setFormTitleError] = useState(false);
    const [formNoteIndicatorError, setFormNoteIndicatorError] = useState({});
    const [formHoursError, setFormHoursError] = useState(false);

    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
    const [isCheckListModalOpen, setIsCheckListModalOpen] = useState(false);
    const [isCheckListEditModalOpen, setIsCheckListEditModalOpen] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [open, setOpen] = useState(false);
    const handleCheckListModalOpen = () => { setIsCheckListModalOpen(true); getCheckListList();}
    
    const handleCheckListEditModalClose = (id) => {
        if (id) {
            const tempCheckListIndicatorRemoved = formCheckListIndicatorsRemoved.filter(checkListIndicatorAdded => checkListIndicatorAdded.checkListId !== id);
            setFormCheckListIndicatorsRemoved(tempCheckListIndicatorRemoved);
        }
        setIsCheckListEditModalOpen(false);
    };

    const handleCheckListModalClose = () => setIsCheckListModalOpen(false);
    const handleWarningModalClose = () => setIsWarningModalOpen(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const getAuditById = () => {
        fetch(ApiUrl.auditEndpoint + "/" + auditId)
            .then(response => response.json())
            .then(json => {
                console.log('json', json)
                getDepartmentsByPlant(json.plant.id);
                getAreasByDepartment(json.department.id)
                    .then(_ => {
                        if (json.area.length > 0) {
                            //json.line.forEach(area => {
                            //    getLinesByArea(json.area[0].id);
                            //})
                            getLinesByArea(json.area[0].id);
                        }
                    })
                        .then(_ => {
                            if (json.line.length > 0) {
                                //json.line.forEach(line => {
                                //    getMachinesByLine(line.id);
                                //})
                                getMachinesByLine(json.line[0].id);
                            }
                        })
                            .then(_ => {
                                setFormTitle(json.title);
                                setFormNote(json.note);
                                setFormStartDate(dayjs(json.startDate));
                                setFormEndDate(dayjs(json.endDate));
                                setFormSelectedType(json.type);
                                setFormSelectedSubject(json.subject.id);
                                setFormSelectedPlant(json.plant.id);
                                setFormSelectedDepartment(json.department.id);
                                setFormSelectedAuditor1(json.leadAuditor.id);
                                setFormSelectedAuditor2(json.auditor2.id);
                                setFormSelectedAuditor3(json.auditor3.id);
                                setFormSelectedAuditor4(json.auditor4.id);
                                setFormSelectedAuditor5(json.auditor5.id);
                                if(json.area.length > 0) {
                                    const areaNames = json.area.map(area => area.name);
                                    setFormSelectedArea(areaNames);
                                }
                                if(json.line.length > 0) {
                                    const lineNames = json.line.map(line => line.name);
                                    setFormSelectedLine(lineNames);
                                }
                                if(json.machine.length > 0) {
                                    const macineNames = json.machine.map(machine => machine.name);
                                    setFormSelectedMachine(macineNames);
                                }
                            });
                getCheckListList();
                getCheckListsByAudit();
            })
            .catch(error => {
                console.error(error);
                setOpen(true);
                setTypeAlert("Error");
                setMessageAlert("Error get Audit by id");
            });
    }

    function getCheckListsByAudit() {
        fetch(ApiUrl.auditEndpoint + "/CheckListAudit/" + auditId)
            .then(response => response.json())
            .then(json => {
                const tempInPromises = json.map(async checkListId => {
                    const response = await fetch(ApiUrl.checkListEndpoint + "/" + checkListId);
                    return await response.json();
                });
                return Promise.all(tempInPromises);
            })
            .then(allCheckList => {
                const newFormCheckListsAdded = [];
                allCheckList.forEach(checkList => {
                    const addCheckList = new CheckList();
                    addCheckList.id = checkList.id;
                    addCheckList.code = checkList.code;
                    addCheckList.type = checkList.type;
                    addCheckList.subject = checkList.subject;
                    addCheckList.description = checkList.description;
                    addCheckList.created = checkList.created;

                    const profile = new User();
                    profile.name = checkList.ownerUser.name;
                    profile.surname = checkList.ownerUser.surname;
                    addCheckList.ownerUser = profile;

                    newFormCheckListsAdded.push(addCheckList)

                });
                setFormCheckListsAdded(newFormCheckListsAdded)
            })
            .catch(error => console.error(error));
    }

    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleAuditUpdate = async () => {
        setLoadingButton(true);
        let isFormTitleError = false;
        let isFormStartDateError = false;
        let isFormEndDateError = false;
        let isFormSelectedTypeError = false;
        let isFormSelectedSubjectError = false;
        let isFormSelectedPlantError = false;
        let isFormSelectedDepartmentError = false;
        let isFormSelectedAreaError = false;
        let isFormSelectedAuditor1Error = false;
        let isFormDailyHoursError = false;
        let isFormCheckListsAddedError = false;

        if(!formTitle.trim()) {
            setFormTitleError(true);
            isFormTitleError = true;
        } else {
            setFormTitleError(false);
            isFormTitleError = false;
        }

        if(formSelectedType === "") {
            isFormSelectedTypeError = true;
        } else {
            isFormSelectedTypeError = false;
        }

        if(formSelectedSubject === "") {
            isFormSelectedSubjectError = true;
        } else {
            isFormSelectedSubjectError = false;
        }

        if(formSelectedPlant === "") {
            isFormSelectedPlantError = true;
        } else {
            isFormSelectedPlantError = false;
        }

        if(formSelectedDepartment === "") {
            isFormSelectedDepartmentError = true;
        } else {
            isFormSelectedDepartmentError = false;
        }

        if(formSelectedArea.length === 0) {
            isFormSelectedAreaError = true;
        } else {
            isFormSelectedAreaError = false;
        }

        if(formSelectedAuditor1 === "") {
            isFormSelectedAuditor1Error = true;
        } else {
            isFormSelectedAuditor1Error = false;
        }

        if(formStartDate === formEndDate && formDailyHours === 0) {
            setFormHoursError(true);
            isFormDailyHoursError = true;
        } else {
            setFormHoursError(false);
            isFormDailyHoursError = false;
        }

        if(formCheckListsAdded.length === 0) {
            isFormCheckListsAddedError = true;
        } else {
            isFormCheckListsAddedError = false;
        }

        if(isFormTitleError || isFormStartDateError || isFormEndDateError || isFormSelectedSubjectError || isFormSelectedPlantError || isFormCheckListsAddedError
            || isFormSelectedDepartmentError || isFormSelectedAreaError || isFormSelectedAuditor1Error || isFormSelectedTypeError || isFormDailyHoursError) {
            setIsWarningModalOpen(true);
            return;
        }

        const audit = new Audit();
        audit.id = auditId;
        if (auth) {
            audit.modifiedBy = auth.user?.id;
        }
        audit.status = 1;
        audit.type = parseInt(formSelectedType);
        audit.title = formTitle;
        audit.startDate = formStartDate;
        audit.endDate = formEndDate;
        audit.note = formNote;

        const auditSubject = new AuditSubject();
        auditSubject.id = formSelectedSubject;
        audit.subject = auditSubject;

        const auditPlant = new Plant();
        auditPlant.id = formSelectedPlant;
        audit.plant = auditPlant;

        const auditDepartment = new Department();
        auditDepartment.id = formSelectedDepartment;
        audit.department = auditDepartment;

        let isAreaLenghtMoreThanTwo = false;
        let isLineLenghtMoreThanTwo = false;

        if(formSelectedArea.length === 1) {
            let singleArea = areas.find(singleArea => singleArea.name === formSelectedArea[0]);
            const auditArea = new Area();
            auditArea.id = singleArea.id;
            audit.area = [auditArea];
        } else {
            audit.line = [];
            audit.machine = [];
            setFormSelectedLine([]);
            setFormSelectedMachine([]);
            isAreaLenghtMoreThanTwo = true;
        }
        
        if(formSelectedLine.length === 1 && !isAreaLenghtMoreThanTwo) {
            let singleLine = lines.find(singleLine => singleLine.name === formSelectedLine[0]);
            const auditLine = new Line();
            auditLine.id = singleLine.id;
            audit.line = [auditLine];
        } else {
            audit.machine = [];
            setFormSelectedMachine([]);
            isLineLenghtMoreThanTwo = true;
        }

        if(formSelectedMachine.length === 1 && !isLineLenghtMoreThanTwo) {
            let singleMachine = machines.find(singleMachine => singleMachine.name === formSelectedMachine[0]);
            const auditMachine = new Machine();
            auditMachine.id = singleMachine.id;
            audit.machine = [auditMachine];
        }

        if(formStartDate === formEndDate) {
            audit.hours = formDailyHours;
        }

        try {    
            fetch(ApiUrl.auditEndpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(audit),
            })
            .then(response => response.json())
            .then(data => audit.id = data.id)
            .then(async () => {
                fetch(ApiUrl.auditEndpoint + "/AuditUser/" + audit.id, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .catch(error => {
                    console.log(error);
                    setLoadingButton(false);
                    setOpen(true);
                    setTypeAlert("Error");
                    setMessageAlert("Error in delete method");
                })
            
                const auditUser = new AuditUserDto();
                auditUser.auditId = audit.id;
                auditUser.userId = formSelectedAuditor1;
    
                await fetch(ApiUrl.auditEndpoint + "/AuditUser", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(auditUser),
                })
                .catch(error => {
                    console.log(error);
                    setOpen(true);
                    setTypeAlert("Error");
                    setMessageAlert("Error during post method");
                })

                if (formSelectedAuditor2 > 0) {
                    const auditUser2 = new AuditUserDto();
                    auditUser2.auditId = audit.id;
                    auditUser2.userId = formSelectedAuditor2;
    
                    await fetch(ApiUrl.auditEndpoint + "/AuditUser", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(auditUser2),
                    })
                    .catch(error => {
                        console.log(error);
                        setOpen(true);
                        setTypeAlert("Error");
                        setMessageAlert("Error during post method for auditor 2");
                    });
                }

                if (formSelectedAuditor3 > 0) {
                    const auditUser3 = new AuditUserDto();
                    auditUser3.auditId = audit.id;
                    auditUser3.userId = formSelectedAuditor3;
    
                    await fetch(ApiUrl.auditEndpoint + "/AuditUser", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(auditUser3),
                    })
                    .catch(error => {
                        console.log(error);
                        setOpen(true);
                        setTypeAlert("Error");
                        setMessageAlert("Error during post method for auditor 3");
                    });
                }

                if (formSelectedAuditor4 > 0) {
                    const auditUser4 = new AuditUserDto();
                    auditUser4.auditId = audit.id;
                    auditUser4.userId = formSelectedAuditor4;
    
                    await fetch(ApiUrl.auditEndpoint + "/AuditUser", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(auditUser4),
                    })
                    .catch(error => {
                        console.log(error);
                        setOpen(true);
                        setTypeAlert("Error");
                        setMessageAlert("Error during post method for auditor 4");
                    });
                }

                if (formSelectedAuditor5 > 0) {
                    const auditUser5 = new AuditUserDto();
                    auditUser5.auditId = audit.id;
                    auditUser5.userId = formSelectedAuditor5;
    
                    await fetch(ApiUrl.auditEndpoint + "/AuditUser", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(auditUser5),
                    })
                    .catch(error => {
                        console.log(error);
                        setOpen(true);
                        setTypeAlert("Error");
                        setMessageAlert("Error during post method for auditor 5");
                    });
                }
    
                formCheckListsAdded.forEach(checkList => {
                    const auditCheckList = new AuditCheckListDto();
                    auditCheckList.auditId = audit.id;
                    auditCheckList.checkListId = checkList.id;
                    const checkListIndicatorsTemp = indicators.filter(ind => ind.checkListId === checkList.id);
                    let jsonIndicators = {    
                    };

                    checkListIndicatorsTemp.forEach(ind => {
                        const matchingItem = formCheckListIndicatorsRemoved.find(item =>
                            item.checkListId === checkList.id && item.id === ind.id
                        );
                        let jsonIndicator = {};
                        jsonIndicator[ind.id] = {
                            "Enabled": matchingItem ? false : true,
                            "Note": matchingItem ? matchingItem.note: ""
                        } 
                        jsonIndicators[ind.id] = jsonIndicator[ind.id];
                    });
    
                    auditCheckList.indicators = jsonIndicators;
    
                    fetch(ApiUrl.auditEndpoint + "/AuditCheckList", {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(auditCheckList),
                    }) 
                    .catch(error => {
                        console.log(error);
                        setOpen(true);
                        setTypeAlert("Error");
                        setMessageAlert("Error during put method for checkList");
                    });
                })
    
                if(formSelectedArea.length > 1) {
                    formSelectedArea.forEach(area => {
                        let selectedArea = areas.find(selArea => selArea.name === area);
                        const auditArea = new AuditAreaDto();
                        auditArea.auditId = audit.id;
                        auditArea.areaId = selectedArea.id;
        
                        fetch(ApiUrl.auditEndpoint + "/AuditArea", {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(auditArea),
                        }) 
                        .catch(error => {
                            console.log(error);
                            setOpen(true);
                            setTypeAlert("Error");
                            setMessageAlert("Error during put method for Area");
                        });
                    });
                }
    
                if(formSelectedLine.length > 1) {
                    formSelectedLine.forEach(line => {
                        let selectedLine = lines.find(selLine => selLine.name === line);
                        const auditLine = new AuditLineDto();
                        auditLine.auditId = audit.id;
                        auditLine.lineId = selectedLine.id;
        
                        fetch(ApiUrl.auditEndpoint + "/AuditLine", {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(auditLine),
                        }) 
                        .catch(error => {
                            console.log(error);
                            setOpen(true);
                            setTypeAlert("Error");
                            setMessageAlert("Error during put method for Line");
                        });
                    });
                }
    
                if(formSelectedMachine.length > 1) {
                    formSelectedMachine.forEach(machine => {
                        let selectedMachine = machines.find(selMach => selMach.name === machine);
                        const auditMachine = new AuditMachineDto();
                        auditMachine.auditId = audit.id;
                        auditMachine.machineId = selectedMachine.id;
        
                        fetch(ApiUrl.auditEndpoint + "/AuditMachine", {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(auditMachine),
                        }) 
                        .catch(error => {
                            console.log(error);
                            setOpen(true);
                            setTypeAlert("Error");
                            setMessageAlert("Error during put method for Machine");
                        });
                    });
                }
                setTypeAlert("Success");
                setMessageAlert("Update successfull");
                setOpen(true);
                setTimeout(() => {
                    setLoadingButton(false);
                }, 2000);
                
            });

        } catch (error) {
            console.log(error);
            setOpen(true);
            setTypeAlert("Error");
            setMessageAlert("Error during update");
            setLoadingButton(false);
        }       
    }

    const handleFormTitleChange = (event) => {
        setFormTitle(event.target.value);
    };
    const handleFormIndicatorNoteChange = (event, id) => {
        setNoteIndicators(prevState => ({
            ...prevState,
            [id]: event.target.value
        }));
    };
    const handleFormNoteChange = (event) => {
        setFormNote(event.target.value);
    };
    const handleFormStartDateChange = (event) => {
        setFormStartDate(event);
    };
    const handleFormEndDateChange = (event) => {
        setFormEndDate(event);
    };
    const handleFormDailyHoursChange = (event) => {
        setFormDailyHours(parseInt(event.target.value));
    };
    const handleSameStartEndDate = () => {
        if(formStartDate === "" || formEndDate === "") {
            return;
        }
        if(formStartDate === formEndDate) {
            setFormDailyHoursVisible(true);
        } else {
            setFormDailyHoursVisible(false);
        }
    };
    useEffect(() => {
        handleSameStartEndDate();
    }, [formEndDate]);

    const handleFormSelectedTypeChange = (event) => {
        setFormSelectedType(event.target.value);
    };
    const handleFormSelectedSubjectChange = (event) => {
        setFormSelectedSubject(event.target.value);
    };

    const handleFormSelectedPlantChange = (event) => {
        //permission.name = permissionsList.find(item => item.id === parseInt(event.target.value)).name;
        setFormSelectedDepartment("");
        setFormSelectedArea([]);
        setFormSelectedLine([]);
        setFormSelectedMachine([]);
        getDepartmentsByPlant(parseInt(event.target.value));
        setFormSelectedPlant(event.target.value);
    };

    const handleFormSelectedDepartmentChange = (event) => {
        //permission.name = permissionsList.find(item => item.id === parseInt(event.target.value)).name;
        setFormSelectedArea([]);
        setFormSelectedLine([]);
        setFormSelectedMachine([]);
        getAreasByDepartment(parseInt(event.target.value));
        setFormSelectedDepartment(event.target.value);
    };

    const handleFormSelectedAreaChange = (event) => {
        const selected = event.target.value;

        if (event.target.value.length > 0) {
            let firstFormSelectedArea = areas.find(area => area.name === event.target.value[0]);
            getLinesByArea(parseInt(firstFormSelectedArea.id));
        }else {
            setFormSelectedLine([]);
            setFormSelectedMachine([]);
        }
        setSelectedCheckboxes(selected);
        setFormSelectedArea(event.target.value);
    };

    const handleCheckListEditModalOpen = (params) => {
        getCheckListIndicatorList(params.id)
        setCheckListSelectedDesc(params.description)
        setCheckListSelectedId(params.id)
        setIsCheckListEditModalOpen(true);
    }

    const handleFormSelectedLineChange = (event) => {
        const selected = event.target.value;

        if (event.target.value.length > 0) {
            //let firstFormSelectedLine = lines.find(line => line.name === event.target.value[0]);
            selected.forEach(sel => {
                let firstFormSelectedLine = lines.find(line => line.name === sel);
                getMachinesByLine(parseInt(firstFormSelectedLine.id));

            })
        }else {
            setFormSelectedMachine([]);
        }
        setSelectedCheckboxes(selected);
        setFormSelectedLine(event.target.value);
    };

    const handleFormSelectedMachineChange = (event) => {
        const selected = event.target.value;
        setSelectedCheckboxes(selected);
        setFormSelectedMachine(event.target.value);
    };

    const handleFormelectedAuditor1Change = (event) => {
        //permission.name = permissionsList.find(item => item.id === parseInt(event.target.value)).name;
        setFormSelectedAuditor1(event.target.value);
    };
    const handleFormelectedAuditor2Change = (event) => {
        //permission.name = permissionsList.find(item => item.id === parseInt(event.target.value)).name;
        setFormSelectedAuditor2(event.target.value);
    };
    const handleFormelectedAuditor3Change = (event) => {
        //permission.name = permissionsList.find(item => item.id === parseInt(event.target.value)).name;
        setFormSelectedAuditor3(event.target.value);
    };
    const handleFormelectedAuditor4Change = (event) => {
        //permission.name = permissionsList.find(item => item.id === parseInt(event.target.value)).name;
        setFormSelectedAuditor4(event.target.value);
    };
    const handleFormelectedAuditor5Change = (event) => {
        //permission.name = permissionsList.find(item => item.id === parseInt(event.target.value)).name;
        setFormSelectedAuditor5(event.target.value);
    };

    const handleFormCheckListsAddedAdd = (checkList) => {
        if (formCheckListsAdded.some(checkListAdded => checkListAdded.id === checkList.id)) {
            const tempCheckListAdded = formCheckListsAdded.filter(checkListAdded => checkListAdded.id !== checkList.id);
            setFormCheckListsAdded(tempCheckListAdded);
        }
        else {
            const addCheckList = new CheckList();
            addCheckList.id = checkList.id;
            addCheckList.code = checkList.code;
            addCheckList.type = checkList.type;
            addCheckList.subject = checkList.subject;
            addCheckList.description = checkList.description;
            addCheckList.created = checkList.created;

            const profile = new User();
            profile.name = checkList.ownerUser.name;
            profile.surname = checkList.ownerUser.surname;
            addCheckList.ownerUser = profile;

            setFormCheckListsAdded([...formCheckListsAdded, addCheckList])
        }
    }

    const handleCheckListIndicatorRemove = (checkListIndicator, noteIndicator) => {
        let isFormNoteIndicatorError = false;
        if (noteIndicator === undefined || noteIndicator === null || noteIndicator === "" || noteIndicator.lenght < 2) {
            setFormNoteIndicatorError(prevState => ({
                ...prevState,
                [checkListIndicator.id]: true
            }));
            isFormNoteIndicatorError = true;
        }
        else {
            setFormNoteIndicatorError(prevState => ({
                ...prevState,
                [checkListIndicator.id]: false
            }));
        }
        if (isFormNoteIndicatorError) {
            return;
        }

        if (formCheckListIndicatorsRemoved.some(checkListIndicatorAdded => checkListIndicatorAdded.id === checkListIndicator.id)) {
            const tempCheckListIndicatorRemoved = formCheckListIndicatorsRemoved.filter(checkListIndicatorAdded => checkListIndicatorAdded.id !== checkListIndicator.id);
            setFormCheckListIndicatorsRemoved(tempCheckListIndicatorRemoved);
            setNoteIndicators(prevState => ({
                ...prevState,
                [checkListIndicator.id]: null
            }));
        }
        else {
            const addCheckListIndicator = new CheckListIndicator();
            addCheckListIndicator.id = checkListIndicator.id;
            addCheckListIndicator.checkListId = checkListIndicator.checkListId;
            addCheckListIndicator.item = checkListIndicator.item;
            addCheckListIndicator.description = checkListIndicator.description;
            addCheckListIndicator.weight = checkListIndicator.weight;
            addCheckListIndicator.numberOfRatings = checkListIndicator.numberOfRatings;
            addCheckListIndicator.note = noteIndicator;

            setFormCheckListIndicatorsRemoved([...formCheckListIndicatorsRemoved, addCheckListIndicator])
        }
    }

    const handleFormCheckListsAddedDelete = (checkList) => {
        const tempFormCheckListsAdded = formCheckListsAdded.filter(checkListAdded => checkListAdded.id !== checkList.id);
        const auditCheckList = new AuditCheckListDto();

        auditCheckList.auditId = auditId;
        auditCheckList.checkListId = checkList.id;
        auditCheckList.deleted = true;
        fetch(ApiUrl.auditEndpoint + "/AuditCheckList", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(auditCheckList),
        }).then(() => {
            setFormCheckListsAdded(tempFormCheckListsAdded);
        })
        .catch(error => {
            console.log(error);
            setOpen(true);
            setTypeAlert("Error");
            setMessageAlert("Somenthing went wrong with checklist");
        })
    }

    const convertDateToString = (date) => {
        if (date.$D < 10 && date.$M + 1 < 10) {
            return date.$y + "-0" + (date.$M + 1) + "-0" + date.$D;
        } else if (date.$D < 10 && date.$M + 1 > 9) {
            return date.$y + "-" + (date.$M + 1) + "-0" + date.$D;
        } else if (date.$M + 1 < 10) {
            return date.$y + "-0" + (date.$M + 1) + "-" + date.$D;
        } else {
            return date.$y + "-" + (date.$M + 1) + "-" + date.$D;
        }
    }

    useEffect(() => {
        getPlantList();
        getUsersList();
        getIndicators()
        getAuditSubjectList();
        getAuditById();
    }, [auditId]);

    const getAuditSubjectList = () => {
        fetch(ApiUrl.subjectEndpoint + "/Type/1")
            .then(response => response.json())
            .then(json => setFormSubjectList(json))
            .catch(error => console.error(error));
    }
    const getPlantList = () => {
        fetch(ApiUrl.plantEndpoint)
            .then(response => response.json())
            .then(json => setPlants(json))
            .catch(error => console.error(error));
    }
    function getDepartmentsByPlant(plantId) {
        fetch(`${ApiUrl.departmentEndpoint}/GetAllByPlant/${plantId}`)
            .then(response => response.json())
            .then(json => setDepartments(json))
            .catch(error => console.error(error));
    }
    const getAreasByDepartment = async (departmentId) => {
        fetch(`${ApiUrl.areaEndpoint}/GetAllByDepartment/${departmentId}`)
            .then(response => response.json())
            .then(json => setAreas(json))
            .catch(error => console.error(error));
    }
    const getLinesByArea = async (areaId) => {
        fetch(`${ApiUrl.lineEndpoint}/GetAllByArea/${areaId}`)
            .then(response => response.json())
            .then(json => setLines(json))
            .catch(error => console.error(error));
    }
    const getMachinesByLine = async (lineId) => {
        fetch(`${ApiUrl.machineEndpoint}/GetAllByLine/${lineId}`)
            .then(response => response.json())
            .then(json => setMachines(json))
            .catch(error => console.error(error));
    }

    function getUsersList() {
        fetch(ApiUrl.userEndpoint)
            .then(response => response.json())
            .then(json => setUsers(json))
            .catch(error => console.error(error));
    }

    const getCheckListList = async () => {
        fetch(ApiUrl.checkListEndpoint)
            .then(response => response.json())
            .then(json => setCheckLists(json))
            .catch(error => console.error(error));
    }

    function getIndicators() {
        fetch(ApiUrl.checkListIndicatorEndpoint)
            .then(response => response.json())
            .then(json => setIndicators(json))
            .catch(error => console.error(error));
    }
    function getCheckListIndicatorList(checkListId) {
        fetch(`${ApiUrl.checkListIndicatorEndpoint}/GetAllByCheckList/${checkListId}`)
            .then(generalResponse => generalResponse.json())
            .then(generalJson => {
                fetch(`${ApiUrl.auditEndpoint}/AuditCheckList/${auditId}/${checkListId}`)
                    .then(response => response.json())
                    .then(json => {
                        const disabledIds = Object.keys(json).filter(key => json[key].Enabled === false);
                        const filtered = generalJson.filter(indicator => !disabledIds.includes(indicator.id.toString()));
                        setCheckListIndicators(filtered);
                    })
                    .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
    }

    const columns = [
        {
            width: 75,
            headerName: '',
            field: '',
            renderCell: (params) => (
                <div>
                    <Checkbox checked={formCheckListsAdded.filter((checkList) => params.row.id === checkList.id).length > 0} key={params.row.id} onChange={() => handleFormCheckListsAddedAdd(params.row)} />
                </div>
            )
        },
        {
            field: 'id',
            headerName: 'Id',
            hidden:true,
            width: 100
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 200
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            hidden: true,
            renderCell: (params) => CheckListStatus[params.value]
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            renderCell: (params) => CheckListType[params.value]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 100,
            valueGetter: (params) => params.row.subject.name
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300
        },
        {
            field: 'ownerUser',
            headerName: 'Owner',
            width: 150,
            valueGetter: (params) => params.row.ownerUser.name + " " + params.row.ownerUser.surname
        }
    ];

    const auditCheckListColumns = [
        {
            field: 'id',
            headerName: 'Id',
            hidden: true,
            width: 100
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 200
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            renderCell: (params) => CheckListType[params.value]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 150,
            valueGetter: (params) => params.row.subject.name
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 400
        },
        {
            field: 'ownerUser',
            headerName: 'Owner',
            width: 200,
            valueGetter: (params) => params.row.ownerUser.name + " " + params.row.ownerUser.surname
        },
        {
            field: 'created',
            headerName: 'Date',
            width: 200,
            valueGetter: (params) => moment(params.row.dateValue).format('DD-MM-YYYY HH:mm:ss')
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => handleCheckListEditModalOpen(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleFormCheckListsAddedDelete(params.row)} />
                </div>
            )
        }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert.toLowerCase()}>
                    <AlertTitle>{typeAlert}</AlertTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" alignItems="center">
                            <span>{messageAlert}</span>
                        </Box>
                        <Button onClick={navigateBack} style={{ marginLeft: 'auto' }}>
                            Back
                        </Button>
                    </Box>
                </Alert>
            </Snackbar>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Button variant="contained" disableElevation onClick={navigateBack}>
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" style={{ marginLeft: 30 }}>Edit this Audit</Typography>
            </Box>
            <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                {formDailyHoursVisible ? <TableCell>Duration (h)</TableCell> : ""}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                    <TableCell style={{ width: 200 }}>
                                        <Select
                                            value={formSelectedType}
                                            onChange={handleFormSelectedTypeChange}
                                            fullWidth
                                        >
                                            <MenuItem key={1} value="1">A</MenuItem>
                                            <MenuItem key={2} value="2">B</MenuItem>
                                            <MenuItem key={3} value="3">C</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell style={{ width: 400,  paddingTop: 8 }}>
                                        <TextField
                                            label="Title"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            error={formTitleError}
                                            helperText={formTitleError ? "Title is required" : ""}
                                            value={formTitle}
                                            onChange={handleFormTitleChange}
                                        />
                                    </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    <Select 
                                        value={formSelectedSubject}
                                        onChange={handleFormSelectedSubjectChange}
                                        fullWidth
                                    >
                                        {formSelectedSubject !== "" && <MenuItem value={""}>Reset</MenuItem>}
                                        {formSubjectList.map((formSubject) => <MenuItem key={formSubject.id} value={formSubject.id}>{formSubject.name}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TableCell style={{ width: 200 }}>
                                        <DatePicker
                                            label="Start Date"
                                            onChange={handleFormStartDateChange}
                                            value={formStartDate}
                                        />
                                    </TableCell>
                                    <TableCell style={{ width: 200 }}>
                                        <DatePicker
                                            label="End Date"
                                            onChange={handleFormEndDateChange}
                                            value={formEndDate}
                                        />
                                    </TableCell>
                                </LocalizationProvider>
                                    {formDailyHoursVisible ?
                                    <TableCell style={{ width: 200, paddingTop: 8 }}>
                                            <TextField
                                                label="Hours"
                                                variant="outlined"
                                                margin="normal"
                                                type="number"
                                                fullWidth
                                                error={formHoursError}
                                                helperText={formHoursError ? "Hours must be > 0" : ""}
                                                value={formDailyHours}
                                                onChange={handleFormDailyHoursChange}
                                            />
                                        </TableCell>
                                    : ""}
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Plant</TableCell>
                                <TableCell>Department</TableCell>
                                <TableCell>Area</TableCell>
                                <TableCell>Line</TableCell>
                                <TableCell>Machine</TableCell>
                                <TableCell>Note</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ width: 200 }}>
                                    <Select
                                        fullWidth
                                        value={formSelectedPlant}
                                        onChange={handleFormSelectedPlantChange}
                                    >
                                        {plants.map((plant) => <MenuItem key={plant.id} value={plant.id}>{plant.code}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    <Select
                                        fullWidth
                                        value={formSelectedDepartment}
                                        onChange={handleFormSelectedDepartmentChange}
                                    >
                                        {departments.map((department) => <MenuItem key={department.id} value={department.id}>{department.name}</MenuItem>)}
                                    </Select>                             
                                </TableCell>
                                <TableCell style={{  width: 200 }}>
                                    <Select
                                        fullWidth
                                        multiple
                                        value={formSelectedArea}
                                        onChange={handleFormSelectedAreaChange}
                                        renderValue={(selected) => selected.length === 1 && areas.length > 0
                                            ? areas.find((area) => area.name === selected[0]).name
                                            : `${selected.length} selected`}
                                    >
                                        {areas.map((area) => <MenuItem key={area.id} value={area.name}><Checkbox checked={formSelectedArea.indexOf(area.name) > -1} /><ListItemText primary={area.name} /></MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell style={{  width: 200 }}>
                                    <Select
                                        disabled={formSelectedArea.length > 1}
                                        fullWidth
                                        multiple
                                        value={formSelectedLine}
                                        onChange={handleFormSelectedLineChange}
                                        renderValue={(selected) => {
                                            if (formSelectedArea.length > 1) {
                                                return '';
                                            }
                                            else if (selected.length > 1)
                                            {
                                            return `${selected.length} selected`
                                            }
                                            else {
                                                return selected.join(', ');
                                            }
                                        }}
                                    >
                                        {lines.map((line) => <MenuItem key={line.id} value={line.name}><Checkbox checked={formSelectedLine.indexOf(line.name) > -1} /><ListItemText primary={line.name} /></MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell style={{  width: 200 }}>
                                    <Select
                                        disabled={formSelectedArea.length > 1 || formSelectedLine.length > 1}
                                        fullWidth
                                        multiple
                                        value={formSelectedMachine}
                                        onChange={handleFormSelectedMachineChange}
                                        renderValue={(selected) => {
                                            if (formSelectedLine.length > 1 || formSelectedArea.length > 1) {
                                                return '';
                                            }
                                            else if (selected.length > 1)
                                            {
                                                return `${selected.length} selected`
                                            }
                                            else {
                                                return selected.join(', ');
                                            }
                                        }}
                                    >
                                        {machines.map((machine) => <MenuItem key={machine.id} value={machine.name}><Checkbox checked={formSelectedMachine.indexOf(machine.name) > -1} /><ListItemText primary={machine.name} /></MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell style={{ width: 400, paddingTop: 8 }}>
                                    <TextField
                                        label="Note"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formNote}
                                        onChange={handleFormNoteChange}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Lead Auditor</TableCell>
                                <TableCell>Auditor 2</TableCell>
                                <TableCell>Auditor 3</TableCell>
                                <TableCell>Auditor 4</TableCell>
                                <TableCell>Auditor 5</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>                        
                            <TableRow>
                                <TableCell style={{ width: 280 }}>
                                    <Select
                                        fullWidth
                                        value={formSelectedAuditor1}
                                        onChange={handleFormelectedAuditor1Change}
                                    >
                                        {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell style={{ width: 280 }}>
                                    <Select
                                        fullWidth
                                        value={formSelectedAuditor2}
                                        onChange={handleFormelectedAuditor2Change}
                                    >
                                        {formSelectedAuditor2 !== "" && <MenuItem value={""}>Reset</MenuItem>}
                                        {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell style={{ width: 280 }}>
                                    <Select
                                        fullWidth
                                        value={formSelectedAuditor3}
                                        onChange={handleFormelectedAuditor3Change}
                                    >
                                        {formSelectedAuditor3 !== "" && <MenuItem value={""}>Reset</MenuItem>}
                                        {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell style={{ width: 280 }}>
                                    <Select
                                        fullWidth
                                        value={formSelectedAuditor4}
                                        onChange={handleFormelectedAuditor4Change}
                                    >
                                        {formSelectedAuditor4 !== "" && <MenuItem value={""}>Reset</MenuItem>}
                                        {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell style={{ width: 280 }}>
                                    <Select
                                        fullWidth
                                        value={formSelectedAuditor5}
                                        onChange={handleFormelectedAuditor5Change}
                                    >
                                        {formSelectedAuditor5 !== "" && <MenuItem value={""}>Reset</MenuItem>}
                                        {users.map((user) => <MenuItem key={user.id} value={user.id}>{user.name} {user.surname}</MenuItem>)}
                                    </Select>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>CheckList Audit</Typography>
                <Button variant="contained" size="small" disableElevation onClick={handleCheckListModalOpen}>
                    <AddIcon style={{ marginRight: '10px' }} />Add CheckLists
                </Button>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={formCheckListsAdded}
                columns={auditCheckListColumns.filter(x => !x.hidden)}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
                fullWidth
            />
            
            <LoadingButton 
                style={{marginTop: 40}}
                loading={loadingButton}
                loadingPosition='start' 
                startIcon={<SaveIcon />}
                color='success'
                variant='contained'
                disableElevation
                onClick={handleAuditUpdate}
                >
                Update
            </LoadingButton>

            <Modal
                open={isCheckListModalOpen}
                onClose={handleCheckListModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">Add CheckLists</Typography>
                    <Box marginTop={3}>
                        <DataGrid
                            style={{ backgroundColor: appColor.white }}
                            rows={checkLists.filter(x => x.status === 5)}
                            columns={columns.filter(x => !x.hidden)}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            pageSizeOptions={[10]}
                            disableColumnFilter
                            disableColumnSelector
                            disableDensitySelector
                            disableRowSelectionOnClick
                            fullWidth
                        />
                    </Box>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="error" disableElevation onClick={handleCheckListModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={isCheckListEditModalOpen}
                onClose={handleCheckListEditModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">Select CheckList Indicator for CheckList: {checkListSelectedDesc}</Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Weighting (%)</TableCell>
                                    <TableCell>Number Of Ratings</TableCell>
                                    <TableCell>Note</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {checkListIndicators.sort((a, b) => a.item.localeCompare(b.item)).map((indicator) =>
                                    <React.Fragment key={`fr${indicator.id}`}>
                                        <TableRow key={indicator.id}>
                                            <TableCell style={{ width: 100, fontWeight: 'bold' }}>{indicator.item}</TableCell>
                                            <TableCell style={{ width: 250, fontWeight: 'bold' }}>{indicator.description}</TableCell>
                                            <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.weight}%</TableCell>
                                            <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.numberOfRatings}</TableCell>
                                            <TableCell style={{ width: 400, fontWeight: 'bold' }}>
                                                <FormControl size="small" fullWidth>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        helperText={formNoteIndicatorError[indicator.id] ? "Please insert a note" : ""}
                                                        error={formNoteIndicatorError[indicator.id]}
                                                        value={formCheckListIndicatorsRemoved.filter((ind) => indicator.id === ind.id).length === 0 ? noteIndicators[indicator.id] || "" : (formCheckListIndicatorsRemoved.find((ind) => indicator.id === ind.id) || {}).note || ""}
                                                        onChange={(event) => handleFormIndicatorNoteChange(event, indicator.id)}
                                                        InputProps={{ style: { height: '30px' } }}
                                                        style={{ marginBottom: 10 }}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                {formCheckListIndicatorsRemoved.filter((ind) => indicator.id === ind.id).length === 0 ?
                                                    <DeleteIcon key={indicator.id} onClick={() => handleCheckListIndicatorRemove(indicator, noteIndicators[indicator.id])} /> :
                                                    <RestoreIcon key={indicator.id} onClick={() => handleCheckListIndicatorRemove(indicator, noteIndicators[indicator.id])} />
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="success" disableElevation onClick={handleCheckListEditModalClose} style={{ marginRight: 15 }}><SaveIcon style={{ marginRight: 15 }} />Save</Button>
                        <Button variant="contained" color="error" disableElevation onClick={() => handleCheckListEditModalClose(checkListSelectedId)}>Cancel</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isWarningModalOpen}
                onClose={handleWarningModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" marginBottom={2}>Warning</Typography>
                    <p>Before saving this Audit you have to compile all the mandatory fields and add at least one CheckList.</p>
                    <Box marginTop={3} display="flex" alignItems="center">
                        <Button variant="contained" color="error" disableElevation onClick={handleWarningModalClose}>Close</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}

export default EditAuditPage;