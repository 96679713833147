export class CheckListSpecificIndicator {
    constructor(checkListIndicator, item, description, numberOfRatings, weight, id, isNew) {
        this.checkListIndicator = checkListIndicator;
        this.item = item;
        this.description = description;
        this.numberOfRatings = numberOfRatings;
        this.weight = weight;
        this.id = id;
        this.isNew = isNew;
    }

    static fromJSON(json) {
        return new CheckListSpecificIndicator(
            json.checkListIndicator,
            json.item,
            json.description,
            json.numberOfRatings,
            json.weight,
            json.id,
            json.isNew
        )
    }
}