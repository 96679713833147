import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { ApiUrl } from '../../ApiUrl';

const CheckListViewDetail = ({ id }) => {
    const [checkListIndicatorList, setCheckListIndicatorList] = useState([]);
    const [checkListSpecificIndicatorList, setCheckListSpecificIndicatorList] = useState([]);

    const getCheckListIndicatorByCheckList = () => {
        fetch(ApiUrl.checkListIndicatorEndpoint + "/GetAllByCheckList/" + id)
            .then(response => response.json())
            .then(json => {
                setCheckListIndicatorList(json);

                const tempSpInPromises = json.map(async indicator => {
                    const response = await fetch(ApiUrl.checkListSpecificIndicatorEndpoint + "/GetAllByCheckListIndicator/" + indicator.id);
                    return await response.json();
                });
        
                return Promise.all(tempSpInPromises);
            })
            .then(tempSpIn => {
                const specificIndicator = tempSpIn.flat();
                setCheckListSpecificIndicatorList(specificIndicator);
            })
            .catch(error => console.error(error));
    }

    useEffect(getCheckListIndicatorByCheckList, [id]);

    return (
        <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>N</TableCell>
                            <TableCell>Indicator</TableCell>
                            <TableCell>Weighting (%)</TableCell>
                            <TableCell>Number Of Ratings</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {checkListIndicatorList.sort((a, b) => a.item.localeCompare(b.item)).map((indicator) =>
                            <React.Fragment key={`fr${indicator.id}`}>
                                <TableRow key={indicator.id} style={{ backgroundColor: '#DDDDDD' }}>
                                    <TableCell style={{ width: 100, fontWeight: 'bold' }}>{indicator.item}</TableCell>
                                    <TableCell style={{ width: 250, fontWeight: 'bold' }}>{indicator.description}</TableCell>
                                    <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.weight}%</TableCell>
                                    <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.numberOfRatings}</TableCell>
                                </TableRow>
                                {checkListSpecificIndicatorList
                                    .filter(specificIndicator => specificIndicator.checkListIndicator.id === indicator.id)
                                    .sort((a, b) => a.item.localeCompare(b.item))
                                    .map((specificIndicator) => 
                                        <TableRow key={specificIndicator.id} style={{ backgroundColor: '#EEEEEE' }}>
                                            <TableCell style={{ width: 100 }}>{specificIndicator.item}</TableCell>
                                            <TableCell style={{ width: 250 }}>{specificIndicator.description}</TableCell>
                                            <TableCell style={{ width: 150 }}>{specificIndicator.weight}%</TableCell>
                                            <TableCell style={{ width: 150 }}>{indicator.numberOfRatings}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </React.Fragment>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default CheckListViewDetail;
