import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import VerticalMenu from '../Nav/VerticalMenu';
import { Box, Toolbar, Button } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import logo from '../../Style/logo/audix-logo.png';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';
import { useAuth } from "../../Hooks/AuthProvider";
import { Link as RouterLink } from 'react-router-dom';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));    

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));
function MainLayout({ children }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const auth = useAuth();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    const logout = () => {
        auth.logOut()

    }

    return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
            </IconButton>
            <Box>
                <RouterLink to={`/?tkn=${localStorage.getItem('user') }`}>
                    <img src={logo} alt='Audix Logo' height={67} style={{cursor:'pointer'} } />
                </RouterLink>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ ml: 2 }}>
                <p> {auth.user?.email ? `Welcome! ${auth.user?.name} ${auth.user?.surname} [${auth.user?.email}]` : ''}</p>
            </Box>
            <Box sx={{ ml: 2 }}>
                {auth.user && (
                    <Button variant="contained" color="error" disableElevation style={{ marginLeft: 15 }} onClick={logout}>
                        Logout
                    </Button>
                )}
            </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {auth.user && (

            <VerticalMenu />
        ) }
        </Drawer>
        <Main open={open}>
            <DrawerHeader />
            <Box style={{ padding: '20px 30px'}}>
                {children}
            </Box>
      </Main>
    </Box>
    );
}

export default MainLayout;
