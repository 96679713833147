export const AuditStatus = {
    1: "Draft",
    2: "Completed",
    3: "Suspended",
    4: "Canceled",
    5: "Approved",
    6: "In Progress",
    7: "Finished",
    8: "Archived"
}
