import React, { useState, useEffect } from 'react';
import { MenuItem, Select, Box, Typography, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ApiUrl } from '../../ApiUrl';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import appColor from '../../Style/appColor';
import { useParams } from "react-router";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
        </GridToolbarContainer>
    );
}

function ViewCheckListPage() {
    const { checkListId } = useParams();

    const navigate = useNavigate();
    const navigateBack = () => navigate('/libraries-standard');

    // CheckList components
    const [checkListIndicatorList, setCheckListIndicatorList] = useState([]);
    const [checkListSpecificIndicatorList, setCheckListSpecificIndicatorList] = useState([]);
    const [checkListRateList, setCheckListRateList] = useState([]);

    // CheckList form fields
    const [formGlobalCode, setFormGlobalCode] = useState("");
    const [formGlobalSubject, setFormGlobalSubject] = useState("");
    const [formGlobalDescription, setFormGlobalDescription] = useState("");
    const [formGlobalCreatedBy, setFormGlobalCreatedBy] = useState("");
    const [formGlobalSubjectList, setFormGlobalSubjectList] = useState([]);

    // Selected rows
    const [indicatorIdSelectedRow, setIndicatorIdSelectedRow] = useState(0);
    const [specificIndicatorIdSelectedRow, setSpecificIndicatorIdSelectedRow] = useState(0);

    const getCheckListSubjectList = () => {
        fetch(ApiUrl.subjectEndpoint + "/Type/2")
            .then(response => response.json())
            .then(json => setFormGlobalSubjectList(json))
            .catch(error => console.error(error));
    }

    const getCheckListById = () => {
        fetch(ApiUrl.checkListEndpoint + "/" + checkListId)
            .then(response => response.json())
            .then(json => {
                setFormGlobalCode(json.code);
                setFormGlobalDescription(json.description);
                setFormGlobalSubject(json.subject.id);
                setFormGlobalCreatedBy(json.ownerUser.name + " " + json.ownerUser.surname);
            })
            .catch(error => console.error(error));
    }

    const getCheckListIndicatorByCheckList = () => {
        fetch(ApiUrl.checkListIndicatorEndpoint + "/GetAllByCheckList/" + checkListId)
            .then(response => response.json())
            .then(json => setCheckListIndicatorList(json))
            .catch(error => console.error(error));
    }

    const getCheckListSpecificIndicatorByCheckListIndicator = (checkListIndicatorId) => {
        fetch(ApiUrl.checkListSpecificIndicatorEndpoint + "/GetAllByCheckListIndicator/" + checkListIndicatorId)
            .then(response => response.json())
            .then(json => setCheckListSpecificIndicatorList(json))
            .catch(error => console.error(error));
    }

    const getCheckListRateByCheckListIndicator = (checkListIndicatorId) => {
        fetch(ApiUrl.checkListRateEndpoint + "/GetAllByCheckListIndicator/" + checkListIndicatorId)
            .then(response => response.json())
            .then(json => setCheckListRateList(json))
            .catch(error => console.error(error));
    }

    const getCheckListRateByCheckListSpecificIndicator = (checkListSpecificIndicatorId) => {
        if (checkListSpecificIndicatorId === undefined) { return; }
        fetch(ApiUrl.checkListRateEndpoint + "/GetAllByCheckListSpecificIndicator/" + checkListSpecificIndicatorId)
            .then(response => response.json())
            .then(json => setCheckListRateList(json))
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getCheckListSubjectList();
        getCheckListById();
        getCheckListIndicatorByCheckList();
    }, []);

    const checkListIndicatorColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'item',
            headerName: 'Item',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 600
        },
        {
            field: 'numberOfRatings',
            headerName: 'Number Of Ratings',
            width: 150
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 150
        }
    ];

    const checkListSpecificIndicatorColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'item',
            headerName: 'Item',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 600
        },
        {
            field: 'numberOfRatings',
            headerName: 'Number Of Ratings',
            width: 150
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 150
        }
    ];

    const checkListRateColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50
        },
        {
            field: 'rate',
            headerName: 'Rate',
            width: 100
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 300
        },
        {
            field: 'extendedDescription',
            headerName: 'Extended Description',
            width: 600
        }
    ];

    const handleIndicatorRowSelected = (row) => {
        setIndicatorIdSelectedRow(row[0]);
        getCheckListSpecificIndicatorByCheckListIndicator(row[0]);
        getCheckListRateByCheckListIndicator(row[0]);
        setSpecificIndicatorIdSelectedRow(0);
    }
    const handleSpecificIndicatorRowSelected = (row) => {
        setSpecificIndicatorIdSelectedRow(row[0]);
        getCheckListRateByCheckListSpecificIndicator(row[0]);
    }

    return (
        <Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Button variant="contained" disableElevation onClick={navigateBack}>
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" style={{ marginLeft: 30 }}>View this CheckList</Typography>
            </Box>

            <Typography variant="h6" style={{ marginBottom: 5 }}>Header</Typography>
            <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Type</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Created by</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ width: 100 }}>
                                    <TextField
                                        value={"B"}
                                        disabled
                                        label="Type"
                                        margin="normal"
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    <TextField
                                        disabled
                                        label="Code"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formGlobalCode}
                                    />
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                    <Select
                                        disabled
                                        value={formGlobalSubject}
                                        fullWidth
                                    >
                                        {formGlobalSubjectList.map((formSubject) => <MenuItem key={formSubject.id} value={formSubject.id}>{formSubject.name}</MenuItem>)}
                                    </Select>
                                </TableCell>
                                <TableCell>{formGlobalCreatedBy}</TableCell>
                                <TableCell style={{ width: 400 }}>
                                    <TextField
                                        disabled
                                        label="Description"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={formGlobalDescription}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button variant="outlined">DRAFT</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Indicator</Typography>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListIndicatorList}
                columns={checkListIndicatorColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'item', sort: 'asc' }],
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    handleIndicatorRowSelected(newRowSelectionModel);
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
            />

            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Specific Indicator</Typography>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.checkListIndicator.id === indicatorIdSelectedRow)}
                columns={checkListSpecificIndicatorColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'item', sort: 'asc' }],
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    handleSpecificIndicatorRowSelected(newRowSelectionModel);
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
            />
            
            <Box display="flex" alignItems="center" style={{ marginTop: 40, marginBottom: 20 }}>
                <Typography variant="h6" style={{ marginRight: 20 }}>Rate</Typography>
            </Box>
            <DataGrid
                style={{ backgroundColor: appColor.white }}
                sx={{ 
                    '& .css-qvtrhg-MuiDataGrid-virtualScroller': { 
                        overflow: 'unset', 
                        minHeight: '50px' 
                    } 
                }}
                rows={checkListRateList.filter(rate => rate.checkListSpecificIndicator === undefined ? rate.checkListIndicator.id === indicatorIdSelectedRow : rate.checkListSpecificIndicator.id === specificIndicatorIdSelectedRow)}
                columns={checkListRateColumns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'rate', sort: 'asc' }],
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
                columnVisibilityModel={{
                    id: false
                }}
                pageSizeOptions={[10]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableRowSelectionOnClick
            />
        </Box>
    );
}

export default ViewCheckListPage;
