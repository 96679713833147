export class TimeZone {
    constructor(id, code, name, value, created, createdBy, modified, modifiedBy, deleted) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.value = value;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
    }

    static fromJSON(json) {
        return new TimeZone(
            json.id,
            json.name,
            json.code,
            json.value,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
        );
    }
}