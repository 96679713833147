import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, TextField, FormControl, Select, MenuItem, Skeleton, Alert } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import Snackbar from '@mui/material/Snackbar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import * as XLSX from 'xlsx';
import appColor from '../../Style/appColor';
import { User } from '../../Model/User';
import { UserWithRole } from '../../Model/UserWithRole';
import { CreateUserRoleDto } from '../../Model/Dto/CreateUserRoleDto';
import { TimeZone } from '../../Model/TimeZone';
import { ApiUrl } from '../../ApiUrl';
import { useAuth } from "../../Hooks/AuthProvider";
import { GetApp } from '@mui/icons-material';

function ProfilePage() {
    const auth = useAuth();
    const [users, setUsers] = useState([]);
    const [rolesList, setRolesList] = useState([]);

    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isProfileModalEdit, setIsProfileModalEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleEditOpen = (profile) => {
        setIsProfileModalOpen(true);
        setIsProfileModalEdit(true);
        setFormId(profile.id);
        setFormName(profile.name);
        setFormSurname(profile.surname);
        setFormEmail(profile.email);
        setFormRoleName(profile.roleName);
        const role = rolesList.find(x => x.name === profile.roleName); 
        setFormRoleId(role.id)
        getRolesList();
    };

    const handleOpen = () => { setIsProfileModalOpen(true); setIsProfileModalEdit(false);  }
    const handleClose = () => {
        setIsProfileModalOpen(false);
        setFormNameError(false);
        setFormSurnameError(false);
        setFormEmailError(false);
        setFormName('');
        setFormSurname('');
        setFormEmail('');
        setFormRoleId(0);
    };

    const [formId, setFormId] = useState(0);
    const [formName, setFormName] = useState('');
    const [formSurname, setFormSurname] = useState('');
    const [formEmail, setFormEmail] = useState('');
    const [formRoleId, setFormRoleId] = useState(0);
    const [formRoleName, setFormRoleName] = useState('');
    const [typeAlert, setTypeAlert] = useState('');
    const [messageAlert, setMessageAlert] = useState('');
    
    const [formNameError, setFormNameError] = useState(false);
    const [formSurnameError, setFormSurnameError] = useState(false);
    const [formEmailError, setFormEmailError] = useState(false);

    function getProfilesList() {
        setLoading(true);
        fetch(ApiUrl.userEndpoint + "/UserRole")
            .then(response => response.json())
            .then(json => {
                setData(json);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }

    function getRolesList() {
        fetch(ApiUrl.roleEndpoint)
            .then(response => response.json())
            .then(json => setRolesList(json))
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getProfilesList();
        getRolesList();
    }, []);

    function setData(json) {
        const users = json.map(item => UserWithRole.fromJSON(item));
        setUsers(users);
    }
    
    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleProfileSave = async () => {
        const profile =  new User();
        profile.name = formName;
        profile.surname = formSurname;
        profile.email = formEmail;
        if (auth) {
            profile.createdBy = auth.user?.id;
        }

        
        const timeZone = new TimeZone();
        timeZone.id = 1;
        profile.timeZone = timeZone;

        let isNameError = false;
        let isSurnameError = false;
        let isEmailError = false;

        if(!formName.trim()) {
            setFormNameError(true);
            isNameError = true;
        } else {
            setFormNameError(false);
            isNameError = false;
        }

        if(!formSurname.trim()) {
            setFormSurnameError(true);
            isSurnameError = true;
        } else {
            setFormSurnameError(false);
            isSurnameError = false;
        }

        if(!formEmail.trim()) {
            setFormEmailError(true);
            isEmailError = true;
        } else {
            setFormEmailError(false);
            isEmailError = false;
        }

        if(isNameError || isSurnameError || isEmailError) {
            return;
        }

        fetch(ApiUrl.userEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profile),
        })
        .then(response => response.json())
        .then((json) => {
            const createUserRoleDto = new CreateUserRoleDto();
            createUserRoleDto.userId = json.id;
            createUserRoleDto.roleId = formRoleId;
            fetch(ApiUrl.userEndpoint + "/UserRole", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(createUserRoleDto),
            }).then(() => {
                handleClose();
                getProfilesList();
                setOpen(true);
                setTypeAlert("success");
                setMessageAlert("User added successfully");
            })
            .catch(error => {
                console.log(error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error added role");
            })
        })
        .catch(error => {
            console.error(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding user");
        });
    }

    const handleProfileEdit = async () => {
        const profile =  new User();
        profile.id = formId;
        profile.name = formName;
        profile.surname = formSurname;
        profile.email = formEmail;
        profile.roleName = formRoleName;
        if (auth) {
            profile.modifiedBy = auth.user?.id;
        }
        let isNameError = false;
        let isSurnameError = false;
        let isEmailError = false;

        if(!formName.trim()) {
            setFormNameError(true);
            isNameError = true;
        } else {
            setFormNameError(false);
            isNameError = false;
        }

        if(!formSurname.trim()) {
            setFormSurnameError(true);
            isSurnameError = true;
        } else {
            setFormSurnameError(false);
            isSurnameError = false;
        }

        if(!formEmail.trim()) {
            setFormEmailError(true);
            isEmailError = true;
        } else {
            setFormEmailError(false);
            isEmailError = false;
        }

        if(isNameError || isSurnameError || isEmailError) {
            return;
        }

        fetch(ApiUrl.userEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profile),
        })
        .then(() => {
            const createUserRoleDto = new CreateUserRoleDto();
            createUserRoleDto.userId = profile.id;
            createUserRoleDto.roleId = formRoleId;
            //createUserRoleDto.roleName = formRoleName;

            fetch(ApiUrl.userEndpoint + "/UserRole", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(createUserRoleDto),
            }).then(() => {
                handleClose();
                getProfilesList();
                setOpen(true);
                setTypeAlert("success");
                setMessageAlert("User updated successfully");
            })
            .catch(error => {
                console.log(error);
                setOpen(true);
                setTypeAlert("error");
                setMessageAlert("Error modified role");
            });
        })
        .catch(error => {
            console.error(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error with edit user");
        });
    }

    const handleProfileDelete = async (profile) => {
        fetch(ApiUrl.userEndpoint, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profile),
        })
        .then(() => {
            getProfilesList();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("User deleted successfully");
        })
        .catch(error => {
            console.error(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error deleting user");
        });
    }

    const handleNameChange = (event) => {
        setFormName(event.target.value);
    };

    const handleSurnameChange = (event) => {
        setFormSurname(event.target.value);
        if(!event.target.value.trim()) {
            setFormSurnameError(true);
        }
    };

    const handleEmailChange = (event) => {
        setFormEmail(event.target.value);
        if(!event.target.value.trim()) {
            setFormEmailError(true);
        }
    };

    const handleRoleChange = (event) => {
        setFormRoleName(event.target.value)
        setFormRoleId(event.target.value);
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcel()}>
                    <GetApp style={{ marginRight: 10 }} />
                    EXPORT
                </Button>
            </GridToolbarContainer>
        );
    }

    const exportToExcel = () => {
        const headers = columns.filter(column => column.field !== 'action').map(column => column.headerName);

        const worksheet = XLSX.utils.json_to_sheet([headers, ...users.map(rowData =>
            columns.map(column => {
                if (column.valueGetter) {
                    return column.valueGetter({ row: rowData });
                } else {
                    return rowData[column.field];
                }  
            })
        )], { skipHeader: true });

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'UserList');
        XLSX.writeFile(workbook, 'userList.xlsx');
    };      

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 100
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 200
        },
        {
            field: 'surname',
            headerName: 'Surname',
            width: 200
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250
        },
        {
            field: 'roleName',
            headerName: 'Role',
            width: 200
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => handleEditOpen(params.row)} />
                    <DeleteIcon style={{ cursor: 'pointer', marginLeft: 8 }} onClick={() => handleProfileDelete(params.row)} />
                </div>
            )
        }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>

            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography variant="h4" style={{ marginRight: 30 }}>Users</Typography>
                <Button variant="contained" disableElevation onClick={handleOpen}>
                    <AddIcon style={{ marginRight: '15px' }} />Add User
                </Button>
            </Box>

            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>
            ) : (
                users.length === 0 ?
                <Typography variant="h6" style={{ marginRight: 30 }}>No users yet</Typography>
                :
                <DataGrid
                    style={{ backgroundColor: appColor.white }}
                    rows={users}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 10,
                            },
                        },
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    pageSizeOptions={[10]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableRowSelectionOnClick
                />
            )
        }
            <Modal
                open={isProfileModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {isProfileModalEdit ?
                        <Typography id="modal-modal-title" variant="h5" component="h2">Edit current user</Typography> :
                        <Typography id="modal-modal-title" variant="h5" component="h2">Add a new user</Typography>
                    }
                    <FormControl fullWidth>
                        <Box display="flex" alignItems="center">
                            <TextField
                                error={formNameError}
                                helperText={formNameError ? "Name is required" : ""}
                                label="Name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                style={{ marginRight: 15 }}
                                value={formName}
                                onChange={handleNameChange}
                            />
                            <TextField
                                error={formSurnameError}
                                helperText={formSurnameError ? "Surname is required" : ""}
                                label="Surname"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                style={{ marginLeft: 15 }}
                                value={formSurname}
                                onChange={handleSurnameChange}
                            />
                        </Box>
                        <TextField
                            error={formEmailError}
                            helperText={formEmailError ? "Email is required" : ""}
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            value={formEmail}
                            onChange={handleEmailChange}
                            style={{ marginBottom: '15px' }}
                        />
                        <p style={{ marginBottom: '5px' }}>Select a Role for this User</p>
                        <Select
                            value={formRoleId}
                            onChange={handleRoleChange}
                        >
                            {rolesList.map((role) => <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>)}
                        </Select>
                        <Box marginTop={3} display="flex" alignItems="center">
                            {isProfileModalEdit ?
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleProfileEdit}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Update
                                </Button> :
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleProfileSave}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Save
                                </Button>
                            }
                            <Button variant="contained" color="error" disableElevation onClick={handleClose}>Close</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Modal>
        </Box>
    );
}

export default ProfilePage;