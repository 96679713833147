import React from 'react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

const BaseTreeItem = ({ nodeId, label, children, onClick }) => (
  <TreeItem nodeId={`${nodeId}`} label={label} onClick={onClick}>
    {children}
  </TreeItem>
);

export default BaseTreeItem;
