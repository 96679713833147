export class Machine {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, name, code, description, line) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.name = name;
        this.code = code;
        this.description = description;
        this.line = line;
    }

    static fromJSON(json) {
        return new Machine(
            json.id,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.name,
            json.code,
            json.description,
            json.line
        );
    }
}