import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, Button, Modal, TextField, Paper, TextareaAutosize, Skeleton, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Plant } from '../../Model/Plant';
import PlantItem from '../../Components/PlantLayout/PlantItem';
import { TreeContext } from '../../Components/PlantLayout/TreeContext';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { TimeZone } from '../../Model/TimeZone';
import { ApiUrl } from '../../ApiUrl';
import { useAuth } from "../../Hooks/AuthProvider";
function PlantLayout() {
    const auth = useAuth();
    const [expanded, setExpanded] = useState([]);
    const treeContextValue = useMemo(() => ({ expanded, setExpanded }), [expanded]);
    const [plants, setPlants] = useState([]);

    const [isPlantLayoutModalOpen, setIsPlantLayoutModalOpen] = useState(false);
    const [isPlantLayoutModaEdit, setIsisPlantLayoutModaEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [typeAlert, setTypeAlert] = useState("");
    const [messageAlert, setMessageAlert] = useState("");
    const [plantEditId, setPlantEditId] = useState(0);
    const handleOpen = () => {
        setIsPlantLayoutModalOpen(true);
        setIsisPlantLayoutModaEdit(false)
    };
    const handleEditModalOpen = (plant) => {
        setIsPlantLayoutModalOpen(true);
        setIsisPlantLayoutModaEdit(true);
        setFormCode(plant.code);
        setFormDesc(plant.description)
    }
    const handleClose = () => {
        setIsPlantLayoutModalOpen(false);
        setIsPlantLayoutModalOpen(false);
        setIsisPlantLayoutModaEdit(false);
        setFormCode('');
        setFormDesc('');
        setFormCodeError(false);
    };      

    const handleChildEdit = (plantId) => {
        setPlantEditId(plantId)
        const plant = plants.find(x => x.id === plantId)
        handleEditModalOpen(plant)
    }

    const [formCode, setFormCode] = useState('');
    const [formDesc, setFormDesc] = useState('');
    const [formCodeError, setFormCodeError] = useState(false);

    const handleCodeChange = (event) => {
        setFormCode(event.target.value);
    };

    const handleDescChange = (event) => {
        setFormDesc(event.target.value);
    };

    const handlePlantSave = async () => {
        const plant =  new Plant();
        plant.code = formCode;
        plant.description = formDesc;
        if (auth) {
            plant.createdBy = auth.user?.id;
        }
        const timeZone = new TimeZone();
        timeZone.id = 1;
        plant.timeZone = timeZone;

        let isCodeError = false;

        if(!formCode.trim()) {
            setFormCodeError(true);
            isCodeError = true;
        } else {
            setFormCodeError(false);
            isCodeError = false;
        }

        if(isCodeError) {
            return;
        }

        fetch(ApiUrl.plantEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(plant),
        })
        .then(() => {
            handleClose();
            getPlantList();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Plant added successfully");
        })
        .catch(error => {
            console.error(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding Plant");
        });
    }

    const handlePlantUpdate = async () => {
        const plant = new Plant();
        plant.id = plantEditId;
        plant.code = formCode;
        plant.description = formDesc;
        if (auth) {
            plant.modifiedBy = auth.user?.id;
        }
        const timeZone = new TimeZone();
        timeZone.id = 1;
        plant.timeZone = timeZone;

        let isCodeError = false;

        if(!formCode.trim()) {
            setFormCodeError(true);
            isCodeError = true;
        } else {
            setFormCodeError(false);
            isCodeError = false;
        }

        if(isCodeError) {
            return;
        }

        fetch(ApiUrl.plantEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(plant),
        })
        .then(() => {
            handleClose();
            getPlantList();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Plant updated successfully");
        })
        .catch(error => {
            console.error(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating plant");
        });
    }

    useEffect(getPlantList, []);

    function getPlantList() {
        setLoading(true);
        fetch(ApiUrl.plantEndpoint)
            .then(response => response.json())
            .then(json => {
                setData(json);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }

    function setData(json) {
        const plants = json.map(item => Plant.fromJSON(item));
        setPlants(plants);
    }

    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <TreeContext.Provider value={treeContextValue}>
             <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>
            
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography variant="h4" style={{ marginRight: 30 }}>Plant Layout</Typography>
                <Button variant="contained" disableElevation onClick={handleOpen}>
                    <AddIcon style={{ marginRight: '15px' }} />Add Plant
                </Button>
            </Box>

            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>
             ) : 
                plants.length === 0 ? <Typography variant="h6" style={{ marginRight: 30 }}>No plants yet</Typography> :
                <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
                    <TreeView
                        style={{ width: '70vh' }}
                        expanded={expanded}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {plants.map(plant => (
                            <PlantItem key={`0${plant.id}`} nodeId={`0${plant.id}`} plantId={plant.id} label={plant.code} onEdit={handleChildEdit} />
                        ))}
                    </TreeView>
                </Paper>
            }      
            <Modal
                open={isPlantLayoutModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">{isPlantLayoutModaEdit ? "Edit plant" : " Add a new Plant"}</Typography>
                    <form>
                        <Box display="flex" alignItems="center">
                            <TextField
                                error={formCodeError}
                                helperText={formCodeError ? "Code is required" : ""}
                                label="Code"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                style={{ marginRight: 15 }}
                                value={formCode}
                                onChange={handleCodeChange}
                            />
                        </Box>
                        <Box display="flex" alignItems="center">
                            <TextareaAutosize 
                                placeholder="Description"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                style={{
                                    width: '100%',
                                    marginRight: 15,
                                    minHeight: '56px',
                                    padding: '8px',
                                    border: '1px solid #ced4da', 
                                    borderRadius: '4px',
                                    resize: 'none',
                                    fontFamily: 'Roboto',
                                    fontSize: '16px',
                                }}
                                value={formDesc}
                                onChange={handleDescChange}
                            />
                        </Box>
                        <Box marginTop={3} display="flex" alignItems="center">
                            {isPlantLayoutModaEdit ?
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handlePlantUpdate}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Update
                                </Button> :
                                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handlePlantSave}>
                                    <SaveIcon style={{ marginRight: '15px' }} />Save
                                </Button>
                            }
                            <Button variant="contained" color="error" disableElevation onClick={handleClose}>Close</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </TreeContext.Provider>
    );
}

export default PlantLayout;