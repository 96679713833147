export class Subject {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, name, type) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.name = name;
        this.type = type;
    }

    static fromJSON(json) {
        return new Subject(
            json.id,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.name,
            json.type
        );
    }
}