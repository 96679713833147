import React, { useState, useEffect } from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import appColor from '../../Style/appColor';
import { ApiUrl } from '../../ApiUrl';

function AuditCalendarPage() {
    const [auditEvents, setAuditEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(getAuditList, []);

    const convertDateToString = (dateString) => {
        const date = new Date(dateString);
        if (date.getDate() < 10 && date.getMonth() + 1 < 10) {
            return date.getFullYear() + "-0" + (date.getMonth() + 1) + "-0" + date.getDate() + "T23:00:00";
        } else if (date.getDate() < 10 && date.getMonth() + 1 > 9) {
            return date.getFullYear() + "-" + (date.getMonth() + 1) + "-0" + date.getDate() + "T23:00:00";
        } else if (date.getMonth() + 1 < 10) {
            return date.getFullYear() + "-0" + (date.getMonth() + 1) + "-" + date.getDate() + "T23:00:00";
        } else {
            return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "T23:00:00";
        }
    }

    function getAuditList() {
        setLoading(true);
        fetch(ApiUrl.auditEndpoint + "/GetAllByCalendar")
            .then(response => response.json())
            .then(json => {
                const auditEventData = [];
                json.forEach(audit => {
                    if (audit.statusId !== 2 && audit.statusId !== 3) {
                        const auditTitle = audit.hours === undefined ? audit.title : audit.title + " (" + audit.hours + "h)";
                        auditEventData.push({
                            id: audit.id,
                            title: auditTitle,
                            start: audit.startDate,
                            end: convertDateToString(audit.endDate),
                            status: audit.statusId
                        });
                    }
                });
                setAuditEvents(auditEventData);
                setLoading(false);
            })
            .catch(error => {
                console.error(error)
                setLoading(false);
            });
    }

    const eventContent = (arg) => {
        return (
            <div style={{ backgroundColor: getEventColor(arg.event), borderColor: getEventColor(arg.event), color: 'white' }}>
                <div style={{ display: 'flex', alignItems: 'center',paddingLeft: 5 }}>
                    <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: 'white', marginRight: '5px' }}></div> 
                    <div style={{ paddingLeft: 5 }}>{arg.event.title}</div> 
                </div>
            </div>
        );
    };
    const getEventColor = (event) => {
        switch (event._def.extendedProps.status) {
            case 8:
                return '#78206E';
            case 7:
                return '#002060';
            case 6:
                return '#0070C0';
            case 5:
                return '#3B7D23';
            case 2:
                return '#747474';
            default:
                return '#D9D9D9';
        }
    };
    return (
        <Box>
            <Typography variant="h4" style={{ marginBottom: 20 }}>Audit Calendar</Typography>
            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>
            ) : (
                <Box style={{ minWidth: "1200px", padding: "15px", border: "1px solid lightgray" }} backgroundColor={appColor.white}>
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        headerToolbar={{
                            left: 'prev,next',
                            center: 'title',
                            right: 'today'
                        }}
                        initialView="dayGridMonth"
                        events={auditEvents}
                        eventContent={eventContent}
                    />
                </Box>
            )}
            
        </Box>
    );
}

export default AuditCalendarPage;