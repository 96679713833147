import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Select, InputLabel, Skeleton, getAlertTitleUtilityClass, AlertTitle, MenuItem, Modal, TextField } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Snackbar from '@mui/material/Snackbar';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import appColor from '../../Style/appColor';
import * as XLSX from 'xlsx';
import { ApiUrl } from '../../ApiUrl';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Tooltip from '@mui/material/Tooltip';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled } from '@mui/material/styles';
import { AuditCheckListCorrectiveAction } from '../../Model/AuditCheckListCorrectiveAction';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { useAuth } from "../../Hooks/AuthProvider";
import { GetApp } from '@mui/icons-material';
import { convertDate } from '../../Utils/convertDate';

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const buttonStyle = {
        height: 150,
        width: 150,
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: 1,
        '&:hover': {
            borderColor: '#000',
        },
    }

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

function AuditInProgressPage() {
    const auth = useAuth();
    const [auditCorrectives, setAuditCorrectives] = useState([]);
    const [audits, setAudits] = useState([]);
    const [checkLists, setCheckLists] = useState([]);
    const [assets, setAssets] = useState([]);
    const [users, setUsers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [isCompileModalOpen, setIsCompileModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingResetButton, setLoadingResetButton] = useState(false);
    const [formData, setFormData] = useState({
        id:null,
        auditCheckListId: null,
        auditTitle: null,
        checkListTitle: null,
        machineId: null,
        areaId: null,
        lineId: null,
        asset: '',
        indicatorId: '',
        indicatorItem: '',
        indicatorDescription: '',
        specificIndicatorItem: '',
        specificIndicatorDescription: '',
        leadAuditor: '',
        date: null,
        auditorRemarks: '',
        actionRequestedByAuditor: '',
        responsabile: '',
        dueDate: null,
        replyOfResponsibleOnAction: '',
        replyAcceptanceByAuditor: '',
        reasonForRejection: '',
        status: '',
        images: {
            image1: null,
            image2: null,
            image3: null,
            image4: null,
            image5: null
        }
    });

    const [formSearchData, setFormSearchData] = useState({
        auditFilter: '',
        checkListFilter: '',
        assetFilter: '',
        statusFilter: []
    });

    const [errors, setErrors] = useState({});
    useEffect(getAuditCorrectiveList, []);
    useEffect(getUsers, []);

    function getAuditCorrectiveList() {
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListCorrectiveActionTable")
            .then(response => response.json())
            .then(json => {
                setAuditCorrectives(json);
                setAudits(Array.from(new Set(json.map(x => x.auditTitle))));
                setCheckLists(Array.from(new Set(json.map(x => x.checkListTitle))));
                setAssets(Array.from(new Set(json.map(x => x.asset))));
                setStatuses(Array.from(new Set(json.map(x => x.status))));
                setLoading(false);
                setLoadingTable(false);
                setLoadingResetButton(false);
            })
            .catch(error => console.error(error));
    }

    function getUsers() {
        fetch(ApiUrl.userEndpoint)
            .then(response => response.json())
            .then(json => {
                setUsers(json)
            })
            .catch(error => console.error(error));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const handleSearchChange = (event) => {
        const { name, value } = event.target;

        //setFormSearchData(prevState => ({
        //    ...prevState,
        //    [name]: value,
        //}));

        setFormSearchData(prevState => ({
            ...prevState,
            [name]: value,
            ...(name === 'auditFilter' && { checkListFilter: '', assetFilter: '', statusFilter: [] }),
            ...(name === 'checkListFilter' && { assetFilter: '', statusFilter: [] }),
            ...(name === 'assetFilter' && { statusFilter: [] }),
        }));

        switch (name) {
            case 'auditFilter':
                const filteredCheckLists = auditCorrectives.filter(item => item.auditTitle === value).map(item => item.checkListTitle);
                const distinctCheckLists = [...new Set(filteredCheckLists)];
                setCheckLists(distinctCheckLists);
                break;
            case 'checkListFilter':
                const filteredAssets = auditCorrectives.filter(item => item.checkListTitle === value).map(item => item.asset);
                const distinctAsset = [...new Set(filteredAssets)];
                setAssets(distinctAsset);
                break;
            case 'assetFilter':
                const filteredStatus = auditCorrectives.filter(item => item.asset === value).map(item => item.status);
                const distinctStatus = [...new Set(filteredStatus)];
                setStatuses(distinctStatus);
                break;
        }
    };

    const handleSearchReset = () => {
        setFormSearchData({
            auditFilter: '',
            checkListFilter: '',
            assetFilter: '',
            statusFilter:  []
        });
        setLoadingResetButton(true);
        setLoadingTable(true);
        getAuditCorrectiveList()
    };

    const handleSearchSubmit = () => {
        console.log('formSearchData', formSearchData)
        setLoadingTable(true);
        setLoadingButton(true);
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListCorrectiveActionTable")
            .then(response => response.json())
            .then(json => {
                const filteredAuditCorrectives = json.filter(auditCorrective => {
                    const auditFilterMatch = !formSearchData.auditFilter || auditCorrective.auditTitle === formSearchData.auditFilter;
                    const checkListFilterMatch = !formSearchData.checkListFilter || auditCorrective.checkListTitle === formSearchData.checkListFilter;
                    const assetFilterMatch = !formSearchData.assetFilter || auditCorrective.asset === formSearchData.assetFilter;
                    const statusFilterMatch = formSearchData.statusFilter.length === 0 || formSearchData.statusFilter.includes(auditCorrective.status);
                    return auditFilterMatch && checkListFilterMatch && assetFilterMatch && statusFilterMatch;
                });
                setAuditCorrectives(filteredAuditCorrectives);
                setLoadingTable(false);
                setLoadingButton(false);
            })
            .catch(error => console.error(error));
    }
    
    const handleSubmit = (status) => {
        const newErrors = {};
        if (status === 'Created' && (!formData.reasonForRejection || (formData.reasonForRejection && formData.reasonForRejection.trim() === ''))) {
            newErrors.reasonForRejection = 'Reason for rejection is required';
        }
        if (formData.replyOfResponsibleOnAction.trim() === '' && status === 'Completed') {
            newErrors.replyOfResponsibleOnAction = 'Reply of responsible on action is required';
        }
        if (formData.replyAcceptanceByAuditor.trim() === '' && status === 'Finished'){
            newErrors.replyAcceptanceByAuditor = 'Reply acceptance by auditor is required';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }
        const auditCheckListCorrectiveAction = new AuditCheckListCorrectiveAction();

        auditCheckListCorrectiveAction.id = formData.id;
        auditCheckListCorrectiveAction.auditCheckListId = formData.auditCheckListId;
        auditCheckListCorrectiveAction.indicatorId = formData.indicatorId;
        auditCheckListCorrectiveAction.specificIndicatorId = formData.specificIndicatorId;
        auditCheckListCorrectiveAction.leadAuditor = formData.leadAuditor;
        auditCheckListCorrectiveAction.date = formData.date;
        auditCheckListCorrectiveAction.auditorRemarks = formData.auditorRemarks;
        auditCheckListCorrectiveAction.actionRequestedByAuditor = formData.actionRequestedByAuditor;
        auditCheckListCorrectiveAction.responsabile = formData.responsabile;
        auditCheckListCorrectiveAction.dueDate = formData.dueDate;
        auditCheckListCorrectiveAction.replyOfResponsibleOnAction = formData.replyOfResponsibleOnAction
        auditCheckListCorrectiveAction.replyAcceptanceByAuditor = formData.replyAcceptanceByAuditor;
        auditCheckListCorrectiveAction.reasonForRejection = formData.reasonForRejection;
        auditCheckListCorrectiveAction.image1 = formData.images.image1;
        auditCheckListCorrectiveAction.image2 = formData.images.image2;
        auditCheckListCorrectiveAction.image3 = formData.images.image3;
        auditCheckListCorrectiveAction.image4 = formData.images.image4;
        auditCheckListCorrectiveAction.image5 = formData.images.image5;
        auditCheckListCorrectiveAction.areaId = formData.areaId;
        auditCheckListCorrectiveAction.lineId = formData.lineId;
        auditCheckListCorrectiveAction.machineId = formData.machineId;
        auditCheckListCorrectiveAction.asset = formData.asset;
        auditCheckListCorrectiveAction.status = status;
        if (auth) {
            auditCheckListCorrectiveAction.modifiedBy = auth.user?.id;
        }

        fetch(ApiUrl.auditEndpoint + "/AuditCheckListCorrectiveAction", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(auditCheckListCorrectiveAction),
        })
        .then(response => response.json())
        .then(() => {
            setIsCompileModalOpen(false);
            setFormData({
                id: null,
                auditCheckListId: null,
                auditTitle: null,
                checkListTitle: null,
                machineId: null,
                areaId: null,
                lineId: null,
                asset: '',
                indicatorId: '',
                indicatorItem: '',
                indicatorDescription: '',
                specificIndicatorItem: '',
                specificIndicatorDescription: '',
                leadAuditor: '',
                date: null,
                auditorRemarks: '',
                actionRequestedByAuditor: '',
                responsabile: '',
                dueDate: null,
                replyOfResponsibleOnAction: '',
                replyAcceptanceByAuditor: '',
                reasonForRejection: '',
                status: '',
                images: {
                    image1: null,
                    image2: null,
                    image3: null,
                    image4: null,
                    image5: null
                }
            });
            getAuditCorrectiveList()
        })
        .catch(error => {
            console.error('Error submitting corrective action:', error);
        });
    }

    const handleCompileModalOpen = (item) => {

        const auditCheckList = auditCorrectives.find(x => x.id === item.id);
        console.log('auditCheckList', auditCheckList)
        if (auditCheckList) {
            setFormData({
                id: auditCheckList.id,
                auditCheckListId: auditCheckList.auditCheckListId,
                areaId: auditCheckList.areaId,
                machineId: auditCheckList.machineId,
                lineId: auditCheckList.lineId,
                asset: auditCheckList.asset,
                auditTitle: auditCheckList.auditTitle,
                checkListTitle: auditCheckList.checkListTitle,
                leadAuditor: auditCheckList.leadAuditor,
                indicatorId: auditCheckList.indicatorId ?? null,
                indicatorItem: auditCheckList.indicatorItem ?? null,
                indicatorDescription: auditCheckList.indicatorDescription ?? null,
                specificIndicatorId: auditCheckList.specificIndicatorId ?? null,
                specificIndicatorItem: auditCheckList.specificIndicatorItem ?? null,
                specificIndicatorDescription: auditCheckList.specificIndicatorDescription ?? null,
                date: dayjs(auditCheckList.date),
                auditorRemarks: auditCheckList.auditorRemarks,
                actionRequestedByAuditor: auditCheckList.actionRequestedByAuditor,
                responsabile: auditCheckList.responsabile,
                dueDate: dayjs(auditCheckList.dueDate),
                replyOfResponsibleOnAction: auditCheckList.replyOfResponsibleOnAction,
                replyAcceptanceByAuditor: auditCheckList.replyAcceptanceByAuditor,
                reasonForRejection: auditCheckList.reasonForRejection,
                status: auditCheckList.status,
                images: {
                    image1: `data:image/png;base64,${auditCheckList.image1}`,
                    image2: `data:image/png;base64,${auditCheckList.image2}`,
                    image3: `data:image/png;base64,${auditCheckList.image3}`,
                    image4: `data:image/png;base64,${auditCheckList.image4}`,
                    image5: `data:image/png;base64,${auditCheckList.image5}`
                }
            });
        }

        setIsCompileModalOpen(true);
        
    }

    const handleCompileModalClose = () => {
        setIsCompileModalOpen(false)
        setFormData({
            id: null,
            auditCheckListId: null,
            auditTitle: null,
            checkListTitle: null,
            machineId: null,
            areaId: null,
            lineId: null,
            asset: '',
            indicatorId: '',
            indicatorItem: '',
            indicatorDescription: '',
            specificIndicatorItem: '',
            specificIndicatorDescription: '',
            leadAuditor: '',
            date: null,
            auditorRemarks: '',
            actionRequestedByAuditor: '',
            responsabile: '',
            dueDate: null,
            replyOfResponsibleOnAction: '',
            replyAcceptanceByAuditor: '',
            reasonForRejection: '',
            status: '',
            images: {
                image1: null,
                image2: null,
                image3: null,
                image4: null,
                image5: null
            }
        })
        setErrors({});
    }

    const getCheckColor = (status) => {
        if (status === 'Created') {
            return '#db0a0a';
        }
        if (status === 'Completed') {
            return '#db6c0a';
        }
        if (status === 'Finished') {
            return '#2e7d32';
        }

        return '#2e7d32';
    }
    
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                 <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcel()}>
                    <GetApp style={{marginRight: 10}} />
                    EXPORT
                </Button>
            </GridToolbarContainer>
        );
    }

    const exportToExcel = () => {
        const headers = columns.filter(column => column.field !== 'action').map(column => column.headerName);
  
        const worksheet = XLSX.utils.json_to_sheet([headers, ...auditCorrectives.map(rowData =>
        columns.map(column => {
            if (column.field === 'indicator') {
                if (rowData.indicatorId) {
                    return `${rowData.indicatorItem} - ${rowData.indicatorDescription}`;
                } else if (rowData.specificIndicatorId) {
                    return `${rowData.specificIndicatorItem} - ${rowData.specificIndicatorDescription}`;
                }
            } else {
                return rowData[column.field];
            }
        })
        )], { skipHeader: true });
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'AuditCorrectiveList');
        XLSX.writeFile(workbook, 'auditCorrectiveList.xlsx');
    };      

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 70,
            hidden:true,
        },
        {
            field: 'auditTitle',
            headerName: 'Audit',
            width: 180
        },
        {
            field: 'checkListTitle',
            headerName: 'CheckList',
            width: 180
        },
        {
            field:'indicator',
            headerName: 'Indicator/Specific Indicator',
            width: 220,
            renderCell: (params) => (
                <div>
                    {params.row.indicatorId && (
                        <span>{`${params.row.indicatorItem} - ${params.row.indicatorDescription}`}</span>
                    )}
                    {params.row.specificIndicatorId && (
                        <span>{`${params.row.specificIndicatorItem} - ${params.row.specificIndicatorDescription}`}</span>
                    )}
                </div>
            )
        },
        {
            field: 'leadAuditor',
            headerName: 'Lead Auditor',
            width: 180,
        },
        {
            field: 'asset',
            headerName: 'Asset',
            width: 150,
        },
        {
            field: 'responsabile',
            headerName: 'Responsible',
            width: 180,
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.date)
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            width: 120,
            valueGetter: (params) => convertDate(params.row.dueDate)
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 100,
            renderCell: (params) => (
                <Tooltip title={params.row.status} arrow>
                    <TaskAltIcon style={{ color: getCheckColor(params.row.status) }} />
                </Tooltip>
            )
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 130,
            renderCell: (params) => (
                <div>
                    <Tooltip title='Edit Corrective Action' arrow>
                        <EngineeringIcon onClick={() => handleCompileModalOpen(params.row)} style={{ cursor: 'pointer', marginLeft: 15 }} />
                    </Tooltip>
                </div>
            )
        }
    ];

    return (
        <Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography variant="h4">Audit Corrective Actions</Typography>
            </Box>
            {loading ? (
                <>
                    <Skeleton animation="wave"/>
                    <Skeleton animation="wave"/>
                    <Skeleton animation="wave"/>
                </>
            ) : (
                auditCorrectives.length === 0 ?
                    <Box >
                        <Typography variant="h6" style={{ marginRight: 30 }}>No Audit Corrective Actions</Typography>
                        <LoadingButton 
                            loading={loadingResetButton}
                            loadingPosition='start' 
                            startIcon={<RestartAltIcon />}
                            color='error'
                            variant='contained'
                            disableElevation
                            onClick={handleSearchReset}
                            >
                            Reset
                        </LoadingButton>
                    </Box>
                :
                <>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Box display="flex" alignItems="center" justifyContent="start">
                            <Box>
                                <InputLabel style={{ fontSize: '0.7rem' }} id="demo-select-small-label">Audit</InputLabel>
                                <Select
                                    style={{ width: 300 }}
                                    fullWidth
                                    name="auditFilter"
                                    value={formSearchData.auditFilter}
                                    onChange={handleSearchChange}
                                >
                                    {audits.map((audit, index) => (
                                        <MenuItem key={index} value={audit}>{audit}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box marginLeft={2}>
                                <InputLabel style={{ fontSize: '0.7rem' }} id="demo-select-small-label">CheckList</InputLabel>
                                <Select
                                    style={{ width: 300 }}
                                    fullWidth
                                    name="checkListFilter"
                                    value={formSearchData.checkListFilter}
                                    onChange={handleSearchChange}
                                >
                                    {checkLists.map((checkList, index) => (
                                        <MenuItem key={index} value={checkList}>{checkList}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box marginLeft={2}>
                                <InputLabel style={{ fontSize: '0.7rem' }} id="demo-select-small-label">Asset</InputLabel>
                                <Select
                                    style={{ width: 300 }}
                                    fullWidth
                                    name="assetFilter"
                                    value={formSearchData.assetFilter}
                                    onChange={handleSearchChange}
                                >
                                    {assets.map((asset, index) => (
                                        <MenuItem key={index} value={asset}>{asset}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box marginLeft={2}>
                                <InputLabel style={{ fontSize: '0.7rem' }} id="demo-select-small-label">Status</InputLabel>
                                <Select
                                    style={{ width: 300 }}
                                    fullWidth
                                    name="statusFilter"
                                    onChange={handleSearchChange}
                                    multiple
                                    value={formSearchData.statusFilter}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {statuses.map((status, index) => (
                                        <MenuItem key={index} value={status}>
                                            <Checkbox checked={formSearchData.statusFilter.indexOf(status) > -1} />
                                            <ListItemText primary={status} />
                                        </MenuItem>
                                            ))}
                                    {/*{statuses.length > 0 ? (*/}
                                    {/*    statuses.map((status, index) => (*/}
                                    {/*        <MenuItem key={index} value={status}>*/}
                                    {/*            <Checkbox checked={formSearchData.statusFilter.indexOf(status) > -1} />*/}
                                    {/*            <ListItemText primary={status} />*/}
                                    {/*        </MenuItem>*/}
                                    {/*    ))*/}
                                    {/*) : (*/}
                                    {/*    <MenuItem disabled>No statuses</MenuItem>*/}
                                    {/*)}*/}
                                </Select>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="end">
                            <LoadingButton 
                                loading={loadingResetButton}
                                loadingPosition='start' 
                                startIcon={<RestartAltIcon />}
                                color='error'
                                variant='contained'
                                disableElevation
                                onClick={handleSearchReset}
                                >
                                Reset
                            </LoadingButton>

                             <LoadingButton 
                                style={{marginLeft: 15}}
                                loading={loadingButton}
                                loadingPosition='start' 
                                startIcon={<SearchIcon />}
                                color='primary'
                                variant='contained'
                                disableElevation
                                onClick={handleSearchSubmit}
                                >
                                Search
                            </LoadingButton>
                        </Box>
                    </Box>
                    {loadingTable ? (
                        <>
                            <Skeleton animation="wave"/>
                            <Skeleton animation="wave"/>
                            <Skeleton animation="wave"/>
                        </>
                    ) : (
                        <Box paddingTop={2}>
                            <DataGrid
                                style={{ backgroundColor: appColor.white }}
                                rows={auditCorrectives}
                                columns={columns.filter(x => !x.hidden)}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                                pageSizeOptions={[10]}
                                disableColumnFilter
                                disableColumnSelector
                                disableDensitySelector
                                disableRowSelectionOnClick
                            />
                        </Box>)
                    } 
                </>
            )

        }
            <Modal
                open={isCompileModalOpen}
                onClose={handleCompileModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography style={{ color: '#1976d2', fontWeight: 'bold' }} id="modal-modal-title" variant="h5" component="h5">{formData.checkListTitle ? formData.checkListTitle : ''}</Typography>
                        <Typography style={{ color: '#1976d2', fontWeight: 'bold' }} id="modal-modal-title" variant="h5" component="h5">Code: {formData.asset}</Typography>
                    </Box>
                    {formData.indicatorId && (
                        <Typography style={{ color: '#1976d2' }} id="modal-modal-title" variant="h6" component="h6">{`${formData.indicatorItem} - ${formData.indicatorDescription}`}</Typography>
                    )}
                    {formData.specificIndicatorId && (
                        <Typography style={{ color: '#1976d2' }} id="modal-modal-title" variant="h6" component="h6">{`${formData.specificIndicatorItem} - ${formData.specificIndicatorDescription}`}</Typography>
                    )}

                    <Box marginTop={3} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <TextField
                                        label="Lead Auditor"
                                        variant="outlined"
                                        margin="normal"
                                        readOnly
                                        style={{ width: 325 }}
                                        fullWidth
                                        value={formData.leadAuditor}
                                    />
                                </Box>
                                <Box marginLeft={3} paddingTop={1}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date"
                                            readOnly
                                            value={formData.date}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                            <Box>
                                <TextField
                                    label="Auditor Remarks"
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: 570 }}
                                    value={formData.auditorRemarks}
                                    readOnly
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    component="label"
                                    disabled
                                    tabIndex={-1}
                                >
                                    {formData.images.image1 !== null ?
                                        <img src={formData.images.image1} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }

                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" />
                                </Button>

                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    disabled
                                    component="label"
                                    tabIndex={-1}
                                >
                                    {formData.images.image2 !== null ?
                                        <img src={formData.images.image2} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }
                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file"/>
                                </Button>

                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    disabled
                                    component="label"
                                    tabIndex={-1}
                                >
                                    {formData.images.image3 !== null ?
                                        <img src={formData.images.image3} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }
                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" />
                                </Button>

                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    disabled
                                    component="label"
                                    tabIndex={-1}
                                >
                                    {formData.images.image4 !== null ?
                                        <img src={formData.images.image4} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }
                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file"/>
                                </Button>

                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    disabled
                                    component="label"
                                    tabIndex={-1}
                                >
                                    {formData.images.image5 !== null ?
                                        <img src={formData.images.image5} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }
                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" />
                                </Button>

                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Box>
                            <TextField
                                label="Action Requested By Auditor (syntetic)"
                                variant="outlined"
                                margin="normal"
                                name="actionRequestedByAuditor"
                                value={formData.actionRequestedByAuditor}
                                readOnly
                                style={{ width: 570 }}
                                fullWidth
                            />
                        </Box>
                        <Box marginLeft={3}>
                            <InputLabel style={{ fontSize: '0.7rem' }} id="demo-select-small-label">Responsible</InputLabel>
                            <Select
                                style={{ width: 325 }}
                                fullWidth
                                value={formData.responsabile}
                                name="responsabile"
                                readOnly
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {users.map((user) => <MenuItem key={user.id} value={user.email}>{user.email}</MenuItem>)}
                            </Select>
                        </Box>
                        <Box marginLeft={3} paddingTop={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Due Date"
                                    style={{ width: 200 }}
                                    value={formData.dueDate}
                                    readOnly
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center">

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <TextField
                                label="Reply of responsible on action"
                                variant="outlined"
                                margin="normal"
                                name="replyOfResponsibleOnAction"
                                style={{ width: 325, marginLeft: 15 }}
                                disabled={formData.status === 'Finished'}
                                onChange={handleChange}
                                value={formData.replyOfResponsibleOnAction }
                                fullWidth
                                error={Boolean(errors.replyOfResponsibleOnAction)}
                                helperText={errors.replyOfResponsibleOnAction}
                            />
                            {formData.status !== 'Finished' && formData.status !== 'Completed' ? (
                                <Button variant="contained" color="success" disableElevation onClick={() => handleSubmit('Completed')} style={{ marginLeft: 10, height:36, width: 125 }}><SaveIcon style={{ marginRight: 15 }} />
                                    Complete
                                </Button>
                            ) : <Button variant="contained" color="success" disableElevation disabled style={{ marginLeft: 10, height: 36, width: 125 }}><SaveIcon style={{ marginRight: 15 }} />
                                Complete
                            </Button>}
                        </Box>
                        <Box marginLeft={3} display="flex" justifyContent="space-between" alignItems="center">
                            <TextField
                                label="Reply acceptance by auditor"
                                variant="outlined"
                                margin="normal"
                                name="replyAcceptanceByAuditor"
                                style={{ width: 325 }}
                                disabled={formData.status === 'Finished'}
                                onChange={handleChange}
                                fullWidth
                                value={formData.replyAcceptanceByAuditor}
                                error={Boolean(errors.replyAcceptanceByAuditor)}
                                helperText={errors.replyAcceptanceByAuditor}
                            />
                            {formData.status === 'Completed' ? (
                                <Button variant="contained" color="primary" disableElevation onClick={() => handleSubmit('Finished')} style={{ marginLeft: 10, height: 36, width: 125 }}><SaveIcon style={{ marginRight: 15 }} />
                                    Finish
                                </Button>
                            ) : <Button variant="contained" color="primary" disableElevation disabled style={{ marginLeft: 10, height: 36, width: 125 }}><SaveIcon style={{ marginRight: 15 }} />
                                Finish
                            </Button>}
                        </Box>
                        <Box marginLeft={3} display="flex" justifyContent="space-between" alignItems="center">
                            <TextField
                                label="Reasons for Rejection"
                                variant="outlined"
                                margin="normal"
                                name="reasonForRejection"
                                style={{ width: 325 }}
                                onChange={handleChange}
                                fullWidth
                                value={formData.reasonForRejection}
                                error={Boolean(errors.reasonForRejection)}
                                helperText={errors.reasonForRejection}
                            />
                            <Button variant="contained" color="error" disableElevation onClick={() => handleSubmit('Created')} style={{ marginLeft: 10, height: 36, width: 125 }}><SaveIcon style={{ marginRight: 15 }} />
                                Reject
                            </Button>
                        </Box>
                    </Box>
                    {formData.status === 'Finished' ?
                        <Box marginTop={3} display="flex" alignItems="center">
                            <Button variant="contained" color="info" disableElevation onClick={handleCompileModalClose}>Close</Button>
                        </Box>
                        :
                        <Box marginTop={3} display="flex" alignItems="center">
                            <Button variant="contained" color="error" disableElevation onClick={handleCompileModalClose}>Cancel</Button>
                        </Box>
                    }

                </Box>
            </Modal>
        </Box>
    );
}

export default AuditInProgressPage;
