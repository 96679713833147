import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { ApiUrl } from '../../ApiUrl';
import CreateAuditPage from './CreateAuditPage';
function Index() {
    const { auditId } = useParams();
    const [audit, setAudit] = useState(null);

    useEffect(() => {
        if (auditId && auditId > 0) {
            fetch(ApiUrl.auditEndpoint + "/GetByAuditView/" + auditId)
                .then(response => response.json())
                .then(json => {
                    setAudit(json)
                })
                .catch(error => console.error(error));
        }
    }, [auditId]);



    return (
        <CreateAuditPage audit={audit} />
    );
}

export default Index;