export class CreateUserRoleDto {
    constructor(userId, roleId) {
        this.userId = userId;
        this.roleId = roleId;
    }

    static fromJSON(json) {
        return new CreateUserRoleDto(
            json.userId,
            json.roleId
        );
    }
}