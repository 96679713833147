import ListIcon from '@mui/icons-material/List';
import AssessmentIcon from '@mui/icons-material/Assessment';
import InventoryIcon from '@mui/icons-material/Inventory';
import BarChartIcon from '@mui/icons-material/BarChart';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FeedIcon from '@mui/icons-material/Feed';
import TimelineIcon from '@mui/icons-material/Timeline';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const menuItems = {
    pages: [
        {
            id: 'planning',
            name: 'Planning',
            icon: ListIcon,
            children: [
                {
                    id: 'planning-programming',
                    name: 'Audit Programming',
                    icon: DateRangeIcon
                }
            ]
        },
        {
            id: 'audit',
            name: 'Audit Reporting',
            icon: AssessmentIcon,
            children: [
                {
                    id: 'audit-calendar',
                    name: 'Audit Calendar',
                    icon: CalendarMonthIcon
                },
                {
                    id: 'audit-progress',
                    name: 'Audit In Progress',
                    icon: ListAltIcon
                },
                {
                    id: 'audit-corrective',
                    name: 'Corrective Actions',
                    icon: AssignmentTurnedInIcon
                }
            ]
        },
        {
            id: 'archive',
            name: 'Archive',
            icon: InventoryIcon,
            children: [
                {
                    id: 'archive-audit',
                    name: 'Archive Audit',
                    icon: FeedIcon
                }
            ]
        },
        {
            id: 'kpi',
            name: 'KPI',
            icon: BarChartIcon,
            children: [
                {
                    id: 'kpi-score',
                    name: 'Score',
                    icon: TimelineIcon
                },
                {
                    id: 'kpi-reports',
                    name: 'Graphic Reports',
                    icon: DonutLargeIcon
                }
            ]
        },
        {
            id: 'configuration',
            name: 'Configuration',
            icon: DisplaySettingsIcon,
            children: [
                {
                    id: 'configuration-plant',
                    name: 'Plant Layout',
                    icon: AccountTreeIcon
                },
                {
                    id: 'configuration-user',
                    name: 'Users',
                    icon: AccountCircleIcon
                }
            ]
        },
        {
            id: 'libraries',
            name: 'Libraries',
            icon: BookmarksIcon,
            children: [
                {
                    id: 'libraries-standard',
                    name: 'CheckList Standard',
                    icon: ListAltIcon
                },
                {
                    id: 'libraries-subject',
                    name: 'Subjects',
                    icon: FormatListBulletedIcon
                },
                {
                    id: 'libraries-role-permission',
                    name: 'Roles and Permissions',
                    icon: AccountCircleIcon
                }
            ]
        }
    ]
}

export default menuItems;