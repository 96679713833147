import React, { useState, useContext } from 'react';
import BaseTreeItem from './BaseTreeItem';
import { Box, Typography, Modal, Button, TextField, TextareaAutosize, Skeleton, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { Machine } from '../../Model/Machine';
import MachineItem from './MachineItem';
import { TreeContext } from './TreeContext';
import { Line } from '../../Model/Line';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import { ApiUrl } from '../../ApiUrl';
import { useAuth } from "../../Hooks/AuthProvider";

const LineItem = ({ nodeId, label, lineId, onEdit }) => {
    const auth = useAuth();
    const [machines, setMachines] = useState([]);
    const { expanded, setExpanded } = useContext(TreeContext);
    const [isApiCalled, setIsApiCalled] = useState(false);

    const [isMachineModalOpen, setIsMachineModalOpen] = useState(false);
    const [isMachineModaEdit, setIsissMachineModaEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [machineEditId, setMachineEditId] = useState(0);
    const handleOpen = () => {
        setIsMachineModalOpen(true);
        setIsissMachineModaEdit(false);
    };
    const handleClose = () => {
      setIsMachineModalOpen(false);
      setFormName('');
      setFormCode('');
      setFormDesc('');
      setFormNameError(false);
      };

    const handleEditModalOpen = (machine) => {
        setIsMachineModalOpen(true);
        setIsissMachineModaEdit(true);
        setFormCode(machine.code);
        setFormName(machine.name);
        setFormDesc(machine.description)
    }
    const handleChildEdit = (machineId) => {
        setMachineEditId(machineId)
        const machine = machines.find(x => x.id === machineId)
        handleEditModalOpen(machine)
    }
  const [formName, setFormName] = useState('');
  const [formCode, setFormCode] = useState('');
  const [typeAlert, setTypeAlert] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [formDesc, setFormDesc] = useState('');
  const [formNameError, setFormNameError] = useState(false);

  const handleNameChange = (event) => {
    setFormName(event.target.value);
    };

    const handleCodeChange = (event) => {
        setFormCode(event.target.value);
    };

    const handleDescChange = (event) => {
        setFormDesc(event.target.value);
    };
    const handleEdit = () => {
        onEdit(lineId)
    }

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }
      setOpen(false);
  };

  const handleMachineSave = async () => {
    const machine = new Machine();
    const line =  new Line();
    line.id = lineId;
    machine.line = line;
    machine.name = formName;
    machine.code = formCode;
    machine.description = formDesc;
      if (auth) {
          machine.createdBy = auth.user?.id;
      }
    let isNameError = false;

    if(!formName.trim()) {
      setFormNameError(true);
      isNameError = true;
    } else {
      setFormNameError(false);
      isNameError = false;
    }

    if(isNameError) {
      return;
    }

    fetch(ApiUrl.machineEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(machine),
    })
    .then(() => {
      handleClose();
      getData();
      setOpen(true);
      setTypeAlert("success");
      setMessageAlert("Machine added successfully");
    })
    .catch(error => {
      console.error(error);
      setOpen(true);
      setTypeAlert("error");
      setMessageAlert("Error adding Machine");
    });
  }

  const handleMachineUpdate = async () => {
    const machine = new Machine();
    const line =  new Line();
    line.id = lineId;
    machine.line = line;
    machine.id = machineEditId;
    machine.name = formName;
    machine.code = formCode;
    machine.description = formDesc;
      if (auth) {
          machine.modifiedBy = auth.user?.id;
      }
    let isNameError = false;

    if(!formName.trim()) {
      setFormNameError(true);
      isNameError = true;
    } else {
      setFormNameError(false);
      isNameError = false;
    }

    if(isNameError) {
      return;
    }

    fetch(ApiUrl.machineEndpoint, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(machine),
    })
    .then(() => {
      handleClose();
      getData();
      setOpen(true);
      setTypeAlert("success");
      setMessageAlert("Machine updated successfully");
    })
    .catch(error => {
      console.error(error);
      setOpen(true);
      setTypeAlert("error");
      setMessageAlert("Error updating Machine");
    });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  function getData() {
    fetch(`${ApiUrl.machineEndpoint}/GetAllByLine/${lineId}`)
      .then(response => response.json())
      .then(json => setData(json))
      .catch(error => console.error(error));
  }

  const handleClick = async () => {
    if (!isApiCalled) {
      getData();
    }   

    const currentIndex = expanded.indexOf(nodeId);
    const newExpanded = [...expanded];

    if (currentIndex === -1) {
      newExpanded.push(nodeId);
    } else {
      newExpanded.splice(currentIndex, 1);
    }

    setExpanded(newExpanded);
  };

  function setData(json) {
      const machines = json.map(item => Machine.fromJSON(item));
      setIsApiCalled(true);
      setMachines(machines);
      setExpanded((prevExpanded) => [...prevExpanded, String(nodeId)]);
  }

  const itemLabel = (
    <Box display="flex" alignItems="center" style={{ padding: '15px' }}>
      <Box display="flex" alignItems="center" flexGrow={1}>
        <LinearScaleIcon />
        <Typography variant="body1" style={{ marginLeft: 10 }}>{label}</Typography>
      </Box>
      <Tooltip placement="bottom" title="Add a new Machine">
        <AddIcon onClick={handleOpen} style={{ marginRight: "15px" }} />
      </Tooltip>
      <Tooltip placement="bottom" title="Edit this Line">
        <EditIcon onClick={handleEdit} style={{ marginRight: "15px" }} />
      </Tooltip>
    </Box>
  );

  return (
    <Box>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert severity={typeAlert}>
            {messageAlert}
        </Alert>
     </Snackbar>

      <BaseTreeItem nodeId={nodeId} label={itemLabel} onClick={handleClick}>
        {machines.map(machine => (
            <MachineItem onEdit={handleChildEdit} key={`4${machine.id}`} nodeId={`4${machine.id}`} machineId={machine.id} label={`${machine.name}${machine.code ? ` (${machine.code})` : ''}`} />
          ))}
      </BaseTreeItem>
      <Modal
        open={isMachineModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">{isMachineModaEdit ? "Edit Machine" : "Add a new Machine"}</Typography>
          <form>
            <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
              <TextField
                error={formNameError}
                helperText={formNameError ? "Name is required" : ""}
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginRight: 15 }}
                value={formName}
                onChange={handleNameChange}
              />
            </Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 8 }}>
              <TextField
                label="Code"
                variant="outlined"
                fullWidth
                style={{ marginRight: 15 }}
                value={formCode}
                onChange={handleCodeChange}
                />
            </Box>
            <Box display="flex" alignItems="center" style={{ marginTop: 16 }}>
                <TextareaAutosize
                    placeholder="Description"
                    variant="outlined"
                    fullWidth
                    style={{
                        width: '100%',
                        marginRight: 15,
                        minHeight: '56px',
                        padding: '8px',
                        border: '1px solid #ced4da',
                        borderRadius: '4px',
                        resize: 'none',
                        fontFamily: 'Roboto',
                        fontSize: '16px',
                    }}
                    value={formDesc}
                    onChange={handleDescChange}
                />
            </Box>
            <Box marginTop={3} display="flex" alignItems="center">
                {isMachineModaEdit ?
                    <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleMachineUpdate}>
                        <SaveIcon style={{ marginRight: '15px' }} />Update
                    </Button> :
                    <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleMachineSave}>
                        <SaveIcon style={{ marginRight: '15px' }} />Save
                    </Button>
                }
              <Button variant="contained" color="error" disableElevation onClick={handleClose}>Close</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default LineItem;
