export class AuditStatusDto {
    constructor(auditId, statusId) {
        this.auditId = auditId;
        this.statusId = statusId;
    }

    static fromJSON(json) {
        return new AuditStatusDto(
            json.auditId,
            json.statusId
        );
    }
}