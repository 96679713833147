import React from 'react';
import {
  Box,
  LinearProgress
} from '@mui/material';

const useStyles = ({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
 });

const SplashScreen = () => {

    return (
    <div style={useStyles}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
}

export default SplashScreen;
