export class ApiUrl {
    // dev rada
    // static _baseUrl = "https://localhost:7054";
    // prod rada
    // static _baseUrl = "http://200.1.1.38:5127";

    // cert cnhi
    static _baseUrl = "https://eutetechappsvcc04.azurewebsites.net";

    // prod cnh
    //static _baseUrl = "https://eutetechappsvcp04.azurewebsites.net";

    //*****PROD CNH*******
    //static authRedirectUri = "https://eutetechappsvcp01.azurewebsites.net/login"

    //*****CERT CNH*******
    static authRedirectUri = "https://eutetechappsvcc01.azurewebsites.net/login"

    //*****DEV*******
    // static authRedirectUri = "http://localhost:3000/login"

    //*****RADA*******
    //static authRedirectUri = "https://terotechweb.azurewebsites.net/login"

    static _apiEndpoint = "/api";

    // Audit
    static auditEndpoint = this._baseUrl + this._apiEndpoint + "/Audit";
    
    // CheckList
    static checkListEndpoint = this._baseUrl + this._apiEndpoint + "/CheckList";
    static checkListIndicatorEndpoint = this._baseUrl + this._apiEndpoint + "/CheckListIndicator";
    static checkListSpecificIndicatorEndpoint = this._baseUrl + this._apiEndpoint + "/CheckListSpecificIndicator";
    static checkListRateEndpoint = this._baseUrl + this._apiEndpoint + "/CheckListRate";

    // User
    static userEndpoint = this._baseUrl + this._apiEndpoint + "/User";

    // PlantLayout
    static plantEndpoint = this._baseUrl + this._apiEndpoint + "/Plant";
    static departmentEndpoint = this._baseUrl + this._apiEndpoint + "/Department";
    static areaEndpoint = this._baseUrl + this._apiEndpoint + "/Area";
    static lineEndpoint = this._baseUrl + this._apiEndpoint + "/Line";
    static machineEndpoint = this._baseUrl + this._apiEndpoint + "/Machine";

    // Permission
    static permissionEndpoint = this._baseUrl + this._apiEndpoint + "/Permission";

    // Role
    static roleEndpoint = this._baseUrl + this._apiEndpoint + "/Role";

    // AuditSubject
    static auditSubjectEndpoint = this._baseUrl + this._apiEndpoint + "/AuditSubject";

    // CheckListSubject
    static checkListSubjectEndpoint = this._baseUrl + this._apiEndpoint + "/CheckListSubject";

    // Subject
    static subjectEndpoint = this._baseUrl + this._apiEndpoint + "/Subject";
    static reportEndpoint = this._baseUrl + this._apiEndpoint + "/Report";
}