import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, TextField, Select, MenuItem, FormControl, Checkbox, FormControlLabel, Skeleton, Alert } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import Snackbar from '@mui/material/Snackbar';
import appColor from '../../Style/appColor';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ApiUrl } from '../../ApiUrl';
import * as XLSX from 'xlsx';
import { Permission } from '../../Model/Permission';
import { Role } from '../../Model/Role';
import { CreateRolePermisionDto } from '../../Model/Dto/CreateRolePermisionDto';
import { useAuth } from "../../Hooks/AuthProvider";
import { GetApp } from '@mui/icons-material';

function RolePermissionPage() {
    const auth = useAuth();
    const [permissionsList, setPermissionsList] = useState([]);
    const [rolesList, setRolesList] = useState([]);
    const [selectedRoleId, setSelectedRoleId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedCheckBox, setSelectedCheckBox] = useState(false);

    const [typeAlert, setTypeAlert] = useState('');
    const [messageAlert, setMessageAlert] = useState('');
    // Modal Permission
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
    const [isPermissionModalEdit, setIsPermissionModalEdit] = useState(false);
    const [formPermissionId, setFormPermissionId] = useState('');
    const [formPermissionName, setFormPermissionName] = useState('');
    const [formPermissionNameError, setFormPermissionNameError] = useState(false);
    const handlePermissionModalOpen = () => { setIsPermissionModalOpen(true); setIsPermissionModalEdit(false); }
    const handlePermissionEditModalOpen = (permission) => { setIsPermissionModalOpen(true); setIsPermissionModalEdit(true); setFormPermissionName(permission.name); setFormPermissionId(permission.id); }
    const handlePermissionModalClose = () => { setIsPermissionModalOpen(false); setFormPermissionName(''); setFormPermissionId(''); setFormPermissionNameError(false); };
    const handleFormPermissionNameChange = (event) => { setFormPermissionName(event.target.value); };
    const isPermissionChecked = (permissionId, selectedRoleId, formRolePermissionObj) => {
        if (formRolePermissionObj.permissionByRole.hasOwnProperty(selectedRoleId)) {
            const permissionsForRole = formRolePermissionObj.permissionByRole[selectedRoleId];
            return permissionsForRole.some(permission => permission.id === permissionId);
        }
        return false;
    };
    const handlePermissionSave = async () => {
        const permission =  new Permission();
        permission.name = formPermissionName;

        let isNameError = false;

        if(!formPermissionName.trim()) {
            setFormPermissionNameError(true);
            isNameError = true;
        } else {
            setFormPermissionNameError(false);
            isNameError = false;
        }

        if(isNameError) {
            return;
        }

        fetch(ApiUrl.permissionEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(permission),
        })
        .then(() => {
            handlePermissionModalClose();
            getPermissionsList();
        })
        .catch(error => console.error(error));
    }
    const handlePermissionUpdate = async () => {
        const permission =  new Permission();
        permission.id = formPermissionId;
        permission.name = formPermissionName;

        let isNameError = false;

        if(!formPermissionName.trim()) {
            setFormPermissionNameError(true);
            isNameError = true;
        } else {
            setFormPermissionNameError(false);
            isNameError = false;
        }

        if(isNameError) {
            return;
        }

        fetch(ApiUrl.permissionEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(permission),
        })
        .then(() => {
            handlePermissionModalClose();
            getPermissionsList();
        })
        .catch(error => console.error(error));
    }

    // Role Permission
    const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
    const [isRoleModalEdit, setIsRoleModalEdit] = useState(false);
    const [formRoleId, setFormRoleId] = useState('');
    const [formRoleName, setFormRoleName] = useState('');
    const [formRoleNameError, setFormRoleNameError] = useState(false);
    const [formRoleCurrentPermission, setFormRoleCurrentPermission] = useState('');
    const [formRolePermissionList, setFormRolePermissionList] = useState([]);
    const [formRolePermissionObj, setFormRolePermissionObj] = useState({});

    const handleRoleModalOpen = () => {
        setSelectedRoleId(0)
        const newRole = {
            id: 0
        };
        setFormRolePermissionObj(prevState => ({
            ...prevState,
            permissionByRole: {
                ...prevState.permissionByRole,
                0: [], 
            },
        }));
        //setRolesList([...rolesList, newRole]);
        setIsRoleModalOpen(true);
        setIsRoleModalEdit(false);
    }
    const handleRoleEditModalOpen = (role) => {
        setIsRoleModalOpen(true);
        setIsRoleModalEdit(true);
        setFormRoleName(role.name);
        setFormRoleId(role.id);
        setSelectedRoleId(role.id)

    }
    const handleRoleModalClose = () => { setIsRoleModalOpen(false); setFormRoleName(''); setFormRoleNameError(false); };
    const handleFormRoleNameChange = (event) => { setFormRoleName(event.target.value); };
    //const handleFormRolePermissionListChange = (event) => {
    //    const permission = new Permission();
    //    permission.id = event.target.value;
    //    permission.name = permissionsList.find(item => item.id === parseInt(event.target.value)).name;
    //    formRolePermissionList.push(permission);
    //    setFormRoleCurrentPermission(event.target.value);
    //};
    const handleFormRolePermissionListChange = (permissionId) => {
        setFormRolePermissionObj(prevState => {
            const newState = { ...prevState };


            if (!newState.permissionByRole[selectedRoleId]) {
                newState.permissionByRole[selectedRoleId] = [];
            }

            const permissionIndex = newState.permissionByRole[selectedRoleId].findIndex(permission => permission.id === permissionId);

            if (permissionIndex !== -1) {
                newState.permissionByRole[selectedRoleId].splice(permissionIndex, 1);
            } else {
                newState.permissionByRole[selectedRoleId].push({
                    id: permissionId,
                });
            }


            setSelectedCheckBox(newState.permissionByRole[selectedRoleId].length > 0);

            return newState;
        });
    };

    const handleRoleSave = async () => {
        setSelectedCheckBox(false);
        const role =  new Role();
        role.name = formRoleName;
        if (auth) {
            role.createdBy = auth.user?.id
        }
        let isNameError = false;

        if(!formRoleName.trim()) {
            setFormRoleNameError(true);
            isNameError = true;
        } else {
            setFormRoleNameError(false);
            isNameError = false;
        }

        if(isNameError) {
            return;
        }

        fetch(ApiUrl.roleEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(role),
        })
        .then(response => response.json())
        .then((json) => {
            formRolePermissionObj.permissionByRole[0].forEach((permissionRole) => {
                const createRolePermisionDto = new CreateRolePermisionDto();
                createRolePermisionDto.roleId = json.id;
                createRolePermisionDto.permissionId = permissionRole.id;

                fetch(ApiUrl.roleEndpoint + "/RolePermission", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(createRolePermisionDto),
                })
                .catch(error => {
                    console.log(error);
                    setOpen(true);
                    setTypeAlert("error");
                    setMessageAlert("Error method post for add role");
                })
            })

        })
        .then(() => {
            handleRoleModalClose();
            getRolesList();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Role added successfully");
        })
        .catch(error => {
            console.error(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error adding role");
        });
    }
    const handleRoleUpdate = async () => {
        const role =  new Role();
        role.id = formRoleId;
        role.name = formRoleName;
        if (auth) {
            role.modifiedBy = auth.user?.id
        }
        let isNameError = false;

        if(!formRoleName.trim()) {
            setFormRoleNameError(true);
            isNameError = true;
        } else {
            setFormRoleNameError(false);
            isNameError = false;
        }

        if(isNameError) {
            return;
        }

        fetch(ApiUrl.roleEndpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(role),
        })
        .then(() => {
            fetch(ApiUrl.roleEndpoint + "/RolePermission", {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(role),
            })
        })
        .then(() => {
            formRolePermissionObj.permissionByRole[role.id].forEach((permissionRole) => {
                const createRolePermisionDto = new CreateRolePermisionDto();
                createRolePermisionDto.roleId = role.id;
                createRolePermisionDto.permissionId = permissionRole.id;

                fetch(ApiUrl.roleEndpoint + "/RolePermission", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(createRolePermisionDto),
                })
                .catch(error => {
                    console.log(error);
                    setOpen(true);
                    setTypeAlert("error");
                    setMessageAlert("Error method post updating role");
                });
            })
        })
        .then(() => {
            handleRoleModalClose();
            getRolesList();
            setOpen(true);
            setTypeAlert("success");
            setMessageAlert("Role updated successfully");
        })
        .catch(error => {
            console.error(error);
            setOpen(true);
            setTypeAlert("error");
            setMessageAlert("Error updating role");
        });
    }

    useEffect(() => {
        getPermissionsList();
        getRolesList();
    }, []);


    function getPermissionsList() {
        setLoading(true);
        fetch(ApiUrl.permissionEndpoint)
            .then(response => response.json())
            .then(json => {
                setPermissionsList(json);
                setLoading(false);
            } )
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }
    function getRolesList() {
        fetch(ApiUrl.roleEndpoint)
            .then(response => response.json())
            .then(json => { setRolesList(json); getGroupedPermissionsByRole(json) })
            .catch(error => console.error(error));
    }
    //async function getPermissionsByRole(roleId) {
    //    fetch(ApiUrl.roleEndpoint + "/RolePermission/" + roleId)
    //        .then(response => response.json())
    //        .then(json => { setFormRolePermissionList(json); })
    //        .catch(error => console.error(error));
    //}
    
    function getGroupedPermissionsByRole(rolesList) {
       const permissionByRole = rolesList.map(role => {
           return fetch(ApiUrl.roleEndpoint + "/RolePermission/" + role.id)
               .then(response => response.json())
               .then(json => ({
                [role.id]: json
            }))
        })

        Promise.all(permissionByRole)
            .then(permissionByRoleArray => {
                const filteredPermissionByRoleArray = permissionByRoleArray.filter(permission => permission !== null);

                const permissionByRole = filteredPermissionByRoleArray.reduce((acc, obj) => ({ ...acc, ...obj }), {});
                setFormRolePermissionObj(prevState => ({
                    ...prevState,
                    permissionByRole
                }));
            })
            .catch(error => console.error('Error fetching permissions for all roles:', error));
    }

    
    function CustomToolbarPermission() {
        return (
            <GridToolbarContainer>
                <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcelPermission()}>
                   <GetApp style={{marginRight: 10}}/>
                   EXPORT
               </Button>
            </GridToolbarContainer>
        );
    }
    
    function CustomToolbarRole() {
        return (
            <GridToolbarContainer>
                <Button style={{ cursor: 'pointer', margin: '0 10px' }} onClick={() => exportToExcelRole()}>
                   <GetApp style={{marginRight: 10}}/>
                   EXPORT
               </Button>
            </GridToolbarContainer>
        );
    }

    const exportToExcelPermission = () => {
        const headers = permissionColumns.filter(column => column.field !== 'action').map(column => column.headerName);
  
        const worksheet = XLSX.utils.json_to_sheet([headers, ...permissionsList.map(rowData =>
            permissionColumns.map(column => {
                return rowData[column.field];
        })
        )], { skipHeader: true });
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'PermissionList');
        XLSX.writeFile(workbook, 'Permissionlist.xlsx');
      }; 

    const exportToExcelRole = () => {
        const headers = roleColumns.filter(column => column.field !== 'action').map(column => column.headerName);
  
        const worksheet = XLSX.utils.json_to_sheet([headers, ...rolesList.map(rowData =>
            roleColumns.map(column => {
                return rowData[column.field]; 
        })
        )], { skipHeader: true });
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'RoleList');
        XLSX.writeFile(workbook, 'Rolelist.xlsx');
      }; 

    const permissionColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 100
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 300
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => handlePermissionEditModalOpen(params.row)} />
                </div>
            )
        }
    ];

    const roleColumns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 100
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 300
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <div>
                    <EditIcon style={{ cursor: 'pointer' }} onClick={() => handleRoleEditModalOpen(params.row)} />
                </div>
            )
        }
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleCloseSnackbar = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    //<Button variant="contained" disableElevation style={{ marginBottom: 20 }} onClick={handlePermissionModalOpen}>
    //    <AddIcon style={{ marginRight: '15px' }} />Add new Permission
    //</Button>
    return (
        <Box>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleCloseSnackbar}>
                <Alert severity={typeAlert}>
                    {messageAlert}
                </Alert>
            </Snackbar>
            <Typography variant="h4" style={{ marginBottom: 20 }}>Roles and Permissions</Typography>
            {loading ? (
                <>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                </>
            ) : (
                <>
                    <Box display="flex" alignItems="top">
                        <Box style={{ marginRight: 40, marginTop:57 }}>
        
                            {permissionsList.length === 0 ?
                                <Typography variant="h6" style={{ marginRight: 30 }}>No Permissions yet</Typography>
                            :     
                                <DataGrid
                                    style={{ backgroundColor: appColor.white }}
                                    rows={permissionsList}
                                    columns={permissionColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    slots={{
                                        toolbar: CustomToolbarPermission,
                                    }}
                                    pageSizeOptions={[10]}
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableDensitySelector
                                    disableRowSelectionOnClick
                                />}
                        </Box>
                        <Box>
                            <Button variant="contained" disableElevation style={{ marginBottom: 20 }} onClick={handleRoleModalOpen}>
                                <AddIcon style={{ marginRight: '15px' }} />Add new Role
                            </Button>
        
                            {rolesList.length === 0 ?
                                <Typography variant="h6" style={{ marginRight: 30 }}>No Roles yet</Typography>
                            :
                                <DataGrid
                                    style={{ backgroundColor: appColor.white }}
                                    rows={rolesList}
                                    columns={roleColumns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                    }}
                                    slots={{
                                        toolbar: CustomToolbarRole,
                                    }}
                                    pageSizeOptions={[10]}
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableDensitySelector
                                    disableRowSelectionOnClick
                                />}
                        </Box>
                    </Box>
        
                    <Modal
                        open={isPermissionModalOpen}
                        onClose={handlePermissionModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            {isPermissionModalEdit ?
                                <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Permission</Typography> :
                                <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Permission</Typography>
                            }
                            <form>
                                <TextField
                                    error={formPermissionNameError}
                                    helperText={formPermissionNameError ? "Name is required" : ""}
                                    label="Name"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={formPermissionName}
                                    onChange={handleFormPermissionNameChange}
                                />
                                <Box marginTop={3} display="flex" alignItems="center">
                                    {isPermissionModalEdit ?
                                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handlePermissionUpdate}>
                                            <SaveIcon style={{ marginRight: '15px' }} />Update
                                        </Button> :
                                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handlePermissionSave}>
                                            <SaveIcon style={{ marginRight: '15px' }} />Save
                                        </Button>
                                    }
                                    <Button variant="contained" color="error" disableElevation onClick={handlePermissionModalClose}>Close</Button>
                                </Box>
                            </form>
                        </Box>
                    </Modal>
        
                    <Modal
                        open={isRoleModalOpen}
                        onClose={handleRoleModalClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            {isRoleModalEdit ?
                                <Typography id="modal-modal-title" variant="h5" component="h2">Edit current Role</Typography> :
                                <Typography id="modal-modal-title" variant="h5" component="h2">Add a new Role</Typography>
                            }
                            <FormControl fullWidth>
                                <TextField
                                    error={formRoleNameError}
                                    helperText={formRoleNameError ? "Name is required" : ""}
                                    label="Name"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={formRoleName}
                                    onChange={handleFormRoleNameChange}
                                    style={{ marginBottom: '20px' }}
                                />
                                {/*<p style={{ marginBottom: '5px' }}>Current Permissions for this Role</p>*/}
                                {/*<ul style={{ marginBottom: '20px' }}>*/}
                                {/*    {formRolePermissionList.map((permissionRole) => <li style={{marginLeft: '20px'}}>{permissionRole.name}</li>)}*/}
                                {/*</ul>*/}
                                {/*<p style={{ marginBottom: '5px' }}>Add a Permission to this Role</p>*/}
                                {/*<Select*/}
                                {/*    value={formRoleCurrentPermission}*/}
                                {/*    onChange={handleFormRolePermissionListChange}*/}
                                {/*>*/}
                                {/*    {permissionsList.map((permission) => <MenuItem key={permission.id} value={permission.id}>{permission.name}</MenuItem>)}*/}
                                {/*</Select>*/}
                                {selectedRoleId !== null && permissionsList.map(permission => (
                                    <FormControlLabel
                                        key={permission.id}
                                        control={<Checkbox onChange={() => handleFormRolePermissionListChange(permission.id)} checked={isPermissionChecked(permission.id, selectedRoleId, formRolePermissionObj)} />}
                                        label={permission.name}
                                    />
                                ))}
                                <Box marginTop={3} display="flex" alignItems="center">
                                    {isRoleModalEdit ?
                                        <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleRoleUpdate}>
                                            <SaveIcon style={{ marginRight: '15px' }} />Update
                                        </Button> :
                                        <Button variant="contained" color="success" disabled={!selectedCheckBox}   disableElevation style={{ marginRight: '15px' }} onClick={handleRoleSave}>
                                            <SaveIcon style={{ marginRight: '15px' }} />Save
                                        </Button>
                                    }
                                    <Button variant="contained" color="error" disableElevation onClick={handleRoleModalClose}>Close</Button>
                                </Box>
                            </FormControl>
                        </Box>
                    </Modal>
                </>
            )}
        </Box>
    );
}

export default RolePermissionPage;
