import React, { useState } from 'react';
import { List, ListItemButton, ListItemText, Collapse, ListItemIcon, Box } from '@mui/material';
import menuItems from './menuItems';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import appColor from '../../Style/appColor';

function VerticalMenu() {
    const [openSubMenus, setOpenSubMenus] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);

    const toggleSubMenu = (id) => {
        setOpenSubMenus(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const handleSubMenuItemClick = (subItem) => {
        setSelectedItem(subItem);
    };

    const isActive = (item) => selectedItem === item;

    const menuElements = menuItems.pages.map((item) =>
        <Box key={item.id} style={{ marginBottom: 10 }}>
            <ListItemButton onClick={() => toggleSubMenu(item.id)}>
                <ListItemIcon>
                    <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.name} />
                <ListItemIcon style={{ minWidth: '0px' }}>
                    {openSubMenus[item.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemIcon>
            </ListItemButton>
            <Collapse in={openSubMenus[item.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {item.children.map((childItem) => (
                        <ListItemButton
                            key={childItem.id}
                            component={Link}
                            to={childItem.id}
                            onClick={() => handleSubMenuItemClick(childItem.id)}
                            selected={isActive(childItem.id)}
                            sx={{ bgcolor: isActive(childItem.id) ? appColor.primaryColor : null, '& .MuiListItemIcon-root, & .MuiListItemText-primary': { color: isActive(childItem.id) ? appColor.primaryColor : null }, paddingLeft: 4 }}
                        >
                            <ListItemIcon>
                                <childItem.icon />
                            </ListItemIcon>
                            <ListItemText primary={childItem.name} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </Box>
    );

    return (
        //<Box style={{ width: '300px', height: '100vh', position: 'fixed', zIndex: 99, borderRight: '2px dashed rgba(145, 158, 171, 0.2)', backgroundColor: 'rgb(249, 250, 251)' }}>
        //    {menuElements}
        //</Box>
        <Box>
            {menuElements}
        </Box>
    );
}

export default VerticalMenu;