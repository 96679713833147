import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, Select, InputLabel, MenuItem, FormControl, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuditCheckListCorrectiveAction } from '../../Model/AuditCheckListCorrectiveAction';
import { useNavigate } from 'react-router-dom';
import appColor from '../../Style/appColor';
import { ApiUrl } from '../../ApiUrl';
import { useParams } from "react-router";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CheckListType } from '../../Model/Enum/CheckListType';
import SaveIcon from '@mui/icons-material/Save';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Tooltip from '@mui/material/Tooltip';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const buttonStyle = {
    height: 150,
    width: 150,
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: 1,
    '&:hover': {
        borderColor: '#000',
    },
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function CompileAuditCheckListPage() {
    const { auditId } = useParams();

    const navigate = useNavigate();
    const navigateBack = () => navigate('/audit-progress');

    const [audit, setAudit] = useState();
    const [checkListDisabled, setCheckListDisabled] = useState([]);
    const [indicatorSelected, setIndicatorSelected] = useState({});
    const [specificIndicatorSelected, setSpecificIndicatorSelected] = useState({});
    const [indicatorScore, setIndicatorScore] = useState([]);
    const [users, setUsers] = useState([]);
    const [auditCheckListId, setAuditCheckListId] = useState(0);

    const [auditCheckListCorrectiveActions, setAuditCheckListCorrectiveActions] = useState([]);
    const [viewOnly, setViewOnly] = useState(false)
    const [isCompileModalOpen, setIsCompileModalOpen] = useState(false);
    const [machineOrAreaCode, setMachineOrAreaCode] = useState("");
    const [checkListSelected, setCheckListSelected] = useState({});
    const [indiCompileSelected, setIndiCompileSelected] = useState({});
    //added
    const [areaId,setSelectedAreaId] = useState(0);
    const [lineId,setSelectedLineId] = useState(0);
    const [machineId,setSelectedMachineId] = useState(0);


    const [formData, setFormData] = useState({
        id:null,
        indicatorId: '',
        specificIndicatorId: '',
        leadAuditor: '',
        date: dayjs(new Date()),
        auditorRemarks: '',
        actionRequestedByAuditor: '',
        responsabile: '',
        dueDate: null,
        replyOfResponsibleOnAction: '',
        replyAcceptanceByAuditor: '',
        images: {
            image1: null,
            image2: null,
            image3: null,
            image4: null,
            image5: null
        }
    });
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleDateChange = (date, name) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: date
        }));
    };

    const handleImageChange = (event, imageNumber) => {
        const files = event.target.files;
        if (files.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setFormData(prevState => ({
                    ...prevState,
                    images: {
                        ...prevState.images,
                        [`image${imageNumber}`]: e.target.result
                    }
                }));
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const getCheckColor = (indicator, typeIndicator) => {
        //edited
        if (typeIndicator === 'indicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.indicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                if (correctiveAction.status === 'Created') {
                    return '#db0a0a';
                }
                if (correctiveAction.status === 'Completed') {
                    return '#db6c0a';
                }
                if (correctiveAction.status === 'Finished') {
                    return '#2e7d32';
                }
                return true;
            }
        }
        if (typeIndicator === 'specificIndicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                if (correctiveAction.status === 'Created') {
                    return '#db0a0a';
                }
                if (correctiveAction.status === 'Completed') {
                    return '#db6c0a';
                }
                if (correctiveAction.status === 'Finished') {
                    return '#2e7d32';
                }
            }
        }

        return '#2e7d32';
    }


    const hasCorrectiveAction = (indicator, typeIndicator) => {
        //added
        if (typeIndicator === 'indicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.indicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                return true;
            }
        }
        if (typeIndicator === 'specificIndicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode);

            if (correctiveAction) {
                return true;
            }
        }

        return false;
    }


    const handleSubmit = () => {
        const newErrors = {};
        if (formData.auditorRemarks.trim() === '') {
            newErrors.auditorRemarks = 'Auditor Remarks is required';
        }
        if (formData.responsabile.trim() === '') {
            newErrors.responsabile = 'Responsabile is required';
        }
        if (!formData.dueDate) {
            newErrors.dueDate = 'Due date is required';
        }
        if (formData.actionRequestedByAuditor.trim() === '') {
            newErrors.actionRequestedByAuditor = 'Action Requested By Auditor is required';
        }
        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }
        const auditCheckListCorrectiveAction = new AuditCheckListCorrectiveAction();

        if (formData.id !== null) {
            auditCheckListCorrectiveAction.id = formData.id
        }
        auditCheckListCorrectiveAction.auditCheckListId = auditCheckListId;
        auditCheckListCorrectiveAction.indicatorId = formData.indicatorId;
        auditCheckListCorrectiveAction.specificIndicatorId = formData.specificIndicatorId;
        auditCheckListCorrectiveAction.leadAuditor = formData.leadAuditor;
        auditCheckListCorrectiveAction.date = formData.date;
        auditCheckListCorrectiveAction.auditorRemarks = formData.auditorRemarks;
        auditCheckListCorrectiveAction.actionRequestedByAuditor = formData.actionRequestedByAuditor;
        auditCheckListCorrectiveAction.responsabile = formData.responsabile;
        auditCheckListCorrectiveAction.dueDate = formData.dueDate;
        auditCheckListCorrectiveAction.replyOfResponsibleOnAction = formData.replyOfResponsibleOnAction
        auditCheckListCorrectiveAction.replyAcceptanceByAuditor = formData.replyAcceptanceByAuditor;
        auditCheckListCorrectiveAction.image1 = formData.images.image1;
        auditCheckListCorrectiveAction.image2 = formData.images.image2;
        auditCheckListCorrectiveAction.image3 = formData.images.image3;
        auditCheckListCorrectiveAction.image4 = formData.images.image4;
        auditCheckListCorrectiveAction.image5 = formData.images.image5;
        //added
        auditCheckListCorrectiveAction.areaId = areaId;
        auditCheckListCorrectiveAction.lineId = lineId;
        auditCheckListCorrectiveAction.machineId = machineId;
        auditCheckListCorrectiveAction.asset = machineOrAreaCode;

        fetch(ApiUrl.auditEndpoint + "/AuditCheckListCorrectiveAction", {
            method: auditCheckListCorrectiveAction.id == null ? 'POST' : 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(auditCheckListCorrectiveAction),
        })
            .then(response => response.json())
            .then(() => {
                setIsCompileModalOpen(false);
                setFormData({
                    id: null,
                    indicatorId: '',
                    specificIndicatorId: '',
                    leadAuditor: '',
                    date: dayjs(new Date()),
                    auditorRemarks: '',
                    actionRequestedByAuditor: '',
                    responsabile: '',
                    dueDate: null,
                    replyOfResponsibleOnAction: '',
                    replyAcceptanceByAuditor: '',
                    images: {
                        image1: null,
                        image2: null,
                        image3: null,
                        image4: null,
                        image5: null
                    }
                });
                getAuditCheckListCorrectiveActions(checkListIdSelectedRow)
            })
            .catch(error => {
                console.error('Error submitting corrective action:', error);
            });
    }

    const handleCompileModalOpen = (indicator, typeIndicator) => {
        const checkList = checkListList.find(x => x.id === checkListIdSelectedRow)
        setCheckListSelected(checkList)
        setIndiCompileSelected(indicator)
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListId/" + auditId + "/" + checkListIdSelectedRow)
            .then(response => response.json())
            .then(json => setAuditCheckListId(json))
            .catch(error => console.error(error));

        const auditCheckList = auditCheckListCorrectiveActions.find(x => typeIndicator === 'indicator' ?
            x.indicatorId === indicator.id && x.asset === machineOrAreaCode :
            x.specificIndicatorId === indicator.id && x.asset === machineOrAreaCode)
        if (auditCheckList) {
            setFormData({
                id: auditCheckList.id,
                leadAuditor: auditCheckList.leadAuditor,
                indicatorId: typeIndicator === 'indicator' ? auditCheckList.indicatorId : null,
                specificIndicatorId: typeIndicator === 'specificIndicator' ? auditCheckList.specificIndicatorId : null,
                date: dayjs(auditCheckList.date),
                auditorRemarks: auditCheckList.auditorRemarks,
                actionRequestedByAuditor: auditCheckList.actionRequestedByAuditor,
                responsabile: auditCheckList.responsabile,
                dueDate: dayjs(auditCheckList.dueDate),
                replyOfResponsibleOnAction: auditCheckList.replyOfResponsibleOnAction,
                replyAcceptanceByAuditor: auditCheckList.replyAcceptanceByAuditor,
                images: {
                    image1: `data:image/png;base64,${auditCheckList.image1}`,
                    image2: `data:image/png;base64,${auditCheckList.image2}`,
                    image3: `data:image/png;base64,${auditCheckList.image3}`,
                    image4: `data:image/png;base64,${auditCheckList.image4}`,
                    image5: `data:image/png;base64,${auditCheckList.image5}`
                }
            });
        } else {
            setFormData({
                id: null,
                leadAuditor: audit.leadAuditor.email,
                indicatorId: typeIndicator === 'indicator' ? indicator.id : null,
                specificIndicatorId: typeIndicator === 'specificIndicator' ? indicator.id : null,
                date: dayjs(new Date()),
                auditorRemarks: '',
                actionRequestedByAuditor: '',
                responsabile: '',
                dueDate: null,
                replyOfResponsibleOnAction: '',
                replyAcceptanceByAuditor: '',
                images: {
                    image1: null,
                    image2: null,
                    image3: null,
                    image4: null,
                    image5: null
                }
            });
        }
        setIsCompileModalOpen(true);

    }
    const handleCompileModalOpenViewOnly = (indicator, typeIndicator) => {
        const checkList = checkListList.find(x => x.id === checkListIdSelectedRow)
        setCheckListSelected(checkList)
        setIndiCompileSelected(indicator)
        fetch(ApiUrl.auditEndpoint + "/AuditCheckListId/" + auditId + "/" + checkListIdSelectedRow)
            .then(response => response.json())
            .then(json => setAuditCheckListId(json))
            .catch(error => console.error(error));
        if (typeIndicator === 'indicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.indicatorId === indicator.id);

            if (correctiveAction) {
                setFormData({
                    leadAuditor: audit.leadAuditor.email,
                    indicatorId: typeIndicator === 'indicator' ? indicator.id : null,
                    specificIndicatorId: typeIndicator === 'specificIndicator' ? indicator.id : null,
                    date: dayjs(correctiveAction.date),
                    auditorRemarks: correctiveAction.auditorRemarks,
                    actionRequestedByAuditor: correctiveAction.actionRequestedByAuditor,
                    responsabile: correctiveAction.responsabile,
                    dueDate: dayjs(correctiveAction.dueDate),
                    replyOfResponsibleOnAction: correctiveAction.replyOfResponsibleOnAction,
                    replyAcceptanceByAuditor: correctiveAction.replyAcceptanceByAuditor,
                    images: {
                        image1: `data:image/png;base64,${correctiveAction.image1}`,
                        image2: `data:image/png;base64,${correctiveAction.image2}`,
                        image3: `data:image/png;base64,${correctiveAction.image3}`,
                        image4: `data:image/png;base64,${correctiveAction.image4}`,
                        image5: `data:image/png;base64,${correctiveAction.image5}`
                    }
                });
            }
        }
        if (typeIndicator === 'specificIndicator') {
            const correctiveAction = auditCheckListCorrectiveActions.find(x => x.specificIndicatorId === indicator.id);

            if (correctiveAction) {
                setFormData({
                    leadAuditor: audit.leadAuditor.email,
                    indicatorId: typeIndicator === 'indicator' ? indicator.id : '',
                    specificIndicatorId: typeIndicator === 'specificIndicator' ? indicator.id : '',
                    date: dayjs(correctiveAction.date),
                    auditorRemarks: correctiveAction.auditorRemarks,
                    actionRequestedByAuditor: correctiveAction.actionRequestedByAuditor,
                    responsabile: correctiveAction.responsabile,
                    dueDate: dayjs(correctiveAction.dueDate),
                    replyOfResponsibleOnAction: correctiveAction.replyOfResponsibleOnAction,
                    replyAcceptanceByAuditor: correctiveAction.replyAcceptanceByAuditor,
                    images: {
                        image1: `data:image/png;base64,${correctiveAction.image1}`,
                        image2: `data:image/png;base64,${correctiveAction.image2}`,
                        image3: `data:image/png;base64,${correctiveAction.image3}`,
                        image4: `data:image/png;base64,${correctiveAction.image4}`,
                        image5: `data:image/png;base64,${correctiveAction.image5}`
                    }
                });
            }
        }
        setViewOnly(true)
        setIsCompileModalOpen(true)
    }
    const handleCompileModalClose = () => {
        setIsCompileModalOpen(false)
        setViewOnly(false)
        setFormData({
            id:null,
            auditCheckListId: '',
            specificIndicatorId: '',
            indicatorId: '',
            leadAuditor: '',
            date: dayjs(new Date()),
            auditorRemarks: '',
            actionRequestedByAuditor: '',
            responsabile: '',
            dueDate: null,
            replyOfResponsibleOnAction: '',
            replyAcceptanceByAuditor: '',
            images: {
                image1: null,
                image2: null,
                image3: null,
                image4: null,
                image5: null
            }
        })
        setErrors({});
    }
    const getAuditById = () => {
        //added
        fetch(ApiUrl.auditEndpoint + "/" + auditId)
            .then(response => response.json())
            .then(json => {
                setAudit(json);
                if (json.area.length > 0) {
                    setAuditButtonSelectedId(json.area[0].id);
                    var codeAndDesc = `${json.area[0].name}${json.area[0].code ? ` (${json.area[0].code})` : ''}`
                    setMachineOrAreaCode(codeAndDesc);
                    setSelectedAreaId(json.area[0].id)
                }
                if (json.line.length > 0) {
                    setAuditButtonSelectedId(json.line[0].id);
                    var codeAndDesc3 = `${json.line[0].name}${json.line[0].code ? ` (${json.line[0].code})` : ''}`
                    setMachineOrAreaCode(codeAndDesc3)
                    setSelectedAreaId(0)
                    setSelectedLineId(json.line[0].id)
                }
                if (json.machine.length > 0) {
                    setAuditButtonSelectedId(json.machine[0].id);
                    var codeAndDesc2 = `${json.machine[0].name}${json.machine[0].code ? ` (${json.machine[0].code})` : ''}`
                    setMachineOrAreaCode(codeAndDesc2);
                    setSelectedAreaId(0)
                    setSelectedLineId(0)
                    setSelectedMachineId(json.machine[0].id);
                }
            })
            .catch(error => console.error(error));
    }
    const getCheckListDisabled = () => {
        fetch(ApiUrl.auditEndpoint + "/CheckListDisabledAudit/" + auditId)
            .then(response => response.json())
            .then(json => setCheckListDisabled(json))
            .catch(error => console.error(error));
    }

    const [checkListList, setCheckListList] = useState([]);
    const [checkListIdSelectedRow, setCheckListIdSelectedRow] = useState(0);
    const [auditButtonSelectedId, setAuditButtonSelectedId] = useState(0);

    const [checkListIndicatorList, setCheckListIndicatorList] = useState([]);
    const [checkListSpecificIndicatorList, setCheckListSpecificIndicatorList] = useState([]);
    const [checkListRateList, setCheckListRateList] = useState([]);

    const [auditRateIndicator, setAuditRateIndicator] = useState([]);
    const [auditRateSpecificIndicator, setAuditRateSpecificIndicator] = useState([]);

    const [auditRateIndicatorScore, setAuditRateIndicatorScore] = useState([]);
    const [auditRateSpecificIndicatorScore, setAuditRateSpecificIndicatorScore] = useState([]);

    const getCheckListIndicatorByCheckList = (id) => {
        setCheckListSpecificIndicatorList([]);
        setCheckListIndicatorList([]);
        setCheckListRateList([]);

        fetch(ApiUrl.checkListIndicatorEndpoint + "/GetAllByCheckList/" + id)
            .then(response => response.json())
            .then(json => {
                const filteredCheckListIndicator = json.filter(indicator => !checkListDisabled.includes(indicator.id));
                setCheckListIndicatorList(filteredCheckListIndicator.sort((a, b) => a.item.localeCompare(b.item)));

                const tempSpInPromises = json.map(async indicator => {
                    const response = await fetch(ApiUrl.checkListSpecificIndicatorEndpoint + "/GetAllByCheckListIndicator/" + indicator.id);
                    return await response.json();
                });

                json.map(async indicator => {
                    const response = await fetch(ApiUrl.checkListRateEndpoint + "/GetAllByCheckListIndicator/" + indicator.id);
                    const responseJson = await response.json();
                    const indicatorRate = responseJson.flat();
                    indicatorRate.forEach(element => {
                        checkListRateList.push(element)
                    });
                    handleRateListDuplicates();
                });
        
                return Promise.all(tempSpInPromises);
            })
            .then(tempSpIn => {
                const specificIndicator = tempSpIn.flat();
                setCheckListSpecificIndicatorList(specificIndicator.sort((a, b) => a.item.localeCompare(b.item)));

                const tempSpInRatePromises = specificIndicator.map(async spIn => {
                    const response = await fetch(ApiUrl.checkListRateEndpoint + "/GetAllByCheckListSpecificIndicator/" + spIn.id);
                    return await response.json();
                });

                return Promise.all(tempSpInRatePromises);
            })
            .then(tempSpInRate => {
                const specificIndicatorRate = tempSpInRate.flat();
                specificIndicatorRate.forEach(element => {
                    checkListRateList.push(element)
                });
            })
            .then(handleRateListDuplicates)
            .catch(error => console.error(error));
    }

    const handleRateListDuplicates = () => {
        const tempCheckListRateList = checkListRateList.reduce((acc, current) => {
            const exists = acc.some(item => item.id === current.id);
            return exists ? acc : [...acc, current];
        }, []);
        setCheckListRateList(tempCheckListRateList);
    }

    useEffect(getCheckListsByAudit, [auditId]);
    useEffect(getAuditById, [auditId]);
    useEffect(getCheckListDisabled, [auditId])
    useEffect(getUsers, [auditId]);
    function getUsers() {
        fetch(ApiUrl.userEndpoint)
            .then(response => response.json())
            .then(json => {
                setUsers(json)
            })
            .catch(error => console.error(error));
    }
    function getCheckListsByAudit() {
        fetch(ApiUrl.auditEndpoint + "/CheckListAudit/" + auditId)
            .then(response => response.json())
            .then(json => {
                const tempInPromises = json.map(async checkListId => {
                    const response = await fetch(ApiUrl.checkListEndpoint + "/" + checkListId);
                    return await response.json();
                });
                return Promise.all(tempInPromises);
            })
            .then(allCheckList => setCheckListList(allCheckList))
            .catch(error => console.error(error));
    }
    function getAuditCheckListCorrectiveActions(checkListId) {
        fetch(`${ApiUrl.auditEndpoint}/AuditCheckListCorrectiveAction/${auditId}/${checkListId}`)
            .then(response => response.json())
            .then(json => { setAuditCheckListCorrectiveActions(json) })
            .catch(error => console.error(error));
    }
    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 100
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 200
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            renderCell: (params) => CheckListType[params.value]
        },
        {
            field: 'subject',
            headerName: 'Subject',
            width: 200,
            valueGetter: (params) => params.row.subject.name
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 400
        }
    ];

    const handleCheckListRowSelected = (row) => {
        getCheckListIndicatorByCheckList(row[0])
        getAuditCheckListCorrectiveActions(row[0])
        setCheckListIdSelectedRow(row[0]);
    }

    function removeDuplicates(arr) {
        const uniqueMap = {};

        if(audit.area.length > 1) {
            arr.forEach(element => {
                const key = `${element.areaId}-${element.indicatorId}-${element.checkListId}`;
                uniqueMap[key] = element;
            });
        }

        if(audit.line.length > 1) {
            arr.forEach(element => {
                const key = `${element.lineId}-${element.indicatorId}-${element.checkListId}`;
                uniqueMap[key] = element;
            });
        }

        if(audit.machine.length > 0) {
            arr.forEach(element => {
                const key = `${element.machineId}-${element.indicatorId}-${element.checkListId}`;
                uniqueMap[key] = element;
            });
        }

        return Object.values(uniqueMap);
    }
    function removeSpecificDuplicates(arr) {
        const uniqueMap = {};
        
        if(audit.area.length > 1) {
            arr.forEach(element => {
                const key = `${element.areaId}-${element.specificIndicatorId}-${element.checkListId}`;
                uniqueMap[key] = element;
            });
        }

        if(audit.line.length > 1) {
            arr.forEach(element => {
                const key = `${element.lineId}-${element.specificIndicatorId}-${element.checkListId}`;
                uniqueMap[key] = element;
            });
        }

        if(audit.machine.length > 0) {
            arr.forEach(element => {
                const key = `${element.machineId}-${element.specificIndicatorId}-${element.checkListId}`;
                uniqueMap[key] = element;
            });
        }

        return Object.values(uniqueMap);
    }

    const handleFormSelectedIndicatorChange = (event, indicatorId) => {
        setIndicatorSelected({
            ...indicatorSelected,
            [indicatorId]: event.target.value,
        });

        if(audit.area.length > 1) {
            const object = {
                "areaId": auditButtonSelectedId,
                "indicatorId": indicatorId,
                "rateId": event.target.value,
                "checkListId": checkListIdSelectedRow
            };
            setAuditRateIndicator([...auditRateIndicator, object]);

            const rateObject = {
                "areaId": auditButtonSelectedId,
                "indicatorId": indicatorId,
                "checkListId": checkListIdSelectedRow,
                "score": (checkListRateList.filter(rate => rate.id === event.target.value)[0].rate * (checkListIndicatorList.filter(indicator => indicator.id === indicatorId)[0].weight / 100)).toFixed(2)
            };
            setAuditRateIndicatorScore([...auditRateIndicatorScore, rateObject]);
        }

        if(audit.line.length > 1) {
            const object = {
                "lineId": auditButtonSelectedId,
                "indicatorId": indicatorId,
                "rateId": event.target.value,
                "checkListId": checkListIdSelectedRow
            };
            setAuditRateIndicator([...auditRateIndicator, object]);

            const rateObject = {
                "lineId": auditButtonSelectedId,
                "indicatorId": indicatorId,
                "checkListId": checkListIdSelectedRow,
                "score": (checkListRateList.filter(rate => rate.id === event.target.value)[0].rate * (checkListIndicatorList.filter(indicator => indicator.id === indicatorId)[0].weight / 100)).toFixed(2)
            };
            setAuditRateIndicatorScore([...auditRateIndicatorScore, rateObject])

            const indicatorScoreObject = {
                "indicatorId": indicatorId,
                "score": rateObject.score
            };
            const existingIndex = indicatorScore.findIndex(item => item.indicatorId === indicatorId);
            if (existingIndex >= 0) {
                const newIndicatorScore = [...indicatorScore];
                newIndicatorScore[existingIndex] = object;
                setIndicatorScore(newIndicatorScore);
            } else {
                setIndicatorScore([...indicatorScore, indicatorScoreObject]);
            }
        }

        if(audit.machine.length > 0) {
            const object = {
                "machineId": auditButtonSelectedId,
                "indicatorId": indicatorId,
                "rateId": event.target.value,
                "checkListId": checkListIdSelectedRow
            };
            setAuditRateIndicator([...auditRateIndicator, object]);

            const rateObject = {
                "machineId": auditButtonSelectedId,
                "indicatorId": indicatorId,
                "checkListId": checkListIdSelectedRow,
                "score": (checkListRateList.filter(rate => rate.id === event.target.value)[0].rate * (checkListIndicatorList.filter(indicator => indicator.id === indicatorId)[0].weight / 100)).toFixed(2)
            };
            setAuditRateIndicatorScore([...auditRateIndicatorScore, rateObject]);

            const indicatorScoreObject = {
                "indicatorId": indicatorId,
                "score": rateObject.score
            };
            const existingIndex = indicatorScore.findIndex(item => item.indicatorId === indicatorId);
            if (existingIndex >= 0) {
                const newIndicatorScore = [...indicatorScore];
                newIndicatorScore[existingIndex] = object;
                setIndicatorScore(newIndicatorScore);
            } else {
                setIndicatorScore([...indicatorScore, indicatorScoreObject]);
            }
        }
    }
    const handleFormSelectedSpecificIndicatorChange = (event, specificIndicatorId) => {
        setSpecificIndicatorSelected({
            ...specificIndicatorSelected,
            [specificIndicatorId]: event.target.value,
        });

        if(audit.area.length > 1) {
            const object = {
                "areaId": auditButtonSelectedId,
                "specificIndicatorId": specificIndicatorId,
                "rateId": event.target.value,
                "checkListId": checkListIdSelectedRow
            };
            setAuditRateSpecificIndicator([...auditRateSpecificIndicator, object]);

            const rateObject = {
                "areaId": auditButtonSelectedId,
                "specificIndicatorId": specificIndicatorId,
                "checkListId": checkListIdSelectedRow,
                "score": (checkListRateList.filter(rate => rate.id === event.target.value)[0].rate * (checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.id === specificIndicatorId)[0].weight / 100)).toFixed(2)
            };
            setAuditRateSpecificIndicatorScore([...auditRateSpecificIndicatorScore, rateObject]);

            const indicatorScoreObject = {
                "specificIndicatorId": specificIndicatorId,
                "score": rateObject.score
            };
            const existingIndex = indicatorScore.findIndex(item => item.specificIndicatorId === specificIndicatorId);
            if (existingIndex >= 0) {
                const newIndicatorScore = [...indicatorScore];
                newIndicatorScore[existingIndex] = indicatorScoreObject;
                setIndicatorScore(newIndicatorScore);
            } else {
                setIndicatorScore([...indicatorScore, indicatorScoreObject]);
            }
        }

        if(audit.line.length > 1) {
            const object = {
                "lineId": auditButtonSelectedId,
                "specificIndicatorId": specificIndicatorId,
                "rateId": event.target.value,
                "checkListId": checkListIdSelectedRow
            };
            setAuditRateSpecificIndicator([...auditRateSpecificIndicator, object]);

            const rateObject = {
                "lineId": auditButtonSelectedId,
                "specificIndicatorId": specificIndicatorId,
                "checkListId": checkListIdSelectedRow,
                "score": (checkListRateList.filter(rate => rate.id === event.target.value)[0].rate * (checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.id === specificIndicatorId)[0].weight / 100)).toFixed(2)
            };
            setAuditRateSpecificIndicatorScore([...auditRateSpecificIndicatorScore, rateObject]);

            const indicatorScoreObject = {
                "specificIndicatorId": specificIndicatorId,
                "score": rateObject.score
            };
            const existingIndex = indicatorScore.findIndex(item => item.specificIndicatorId === specificIndicatorId);
            if (existingIndex >= 0) {
                const newIndicatorScore = [...indicatorScore];
                newIndicatorScore[existingIndex] = indicatorScoreObject;
                setIndicatorScore(newIndicatorScore);
            } else {
                setIndicatorScore([...indicatorScore, indicatorScoreObject]);
            }
        }

        if(audit.machine.length > 0) {
            const object = {
                "machineId": auditButtonSelectedId,
                "specificIndicatorId": specificIndicatorId,
                "rateId": event.target.value,
                "checkListId": checkListIdSelectedRow
            };
            setAuditRateSpecificIndicator([...auditRateSpecificIndicator, object]);

            const rateObject = {
                "machineId": auditButtonSelectedId,
                "specificIndicatorId": specificIndicatorId,
                "checkListId": checkListIdSelectedRow,
                "score": (checkListRateList.filter(rate => rate.id === event.target.value)[0].rate * (checkListSpecificIndicatorList.filter(specificIndicator => specificIndicator.id === specificIndicatorId)[0].weight / 100)).toFixed(2)
            };
            setAuditRateSpecificIndicatorScore([...auditRateSpecificIndicatorScore, rateObject])

            const indicatorScoreObject = {
                "specificIndicatorId": specificIndicatorId,
                "score": rateObject.score
            };
            const existingIndex = indicatorScore.findIndex(item => item.specificIndicatorId === specificIndicatorId);
            if (existingIndex >= 0) {
                const newIndicatorScore = [...indicatorScore];
                newIndicatorScore[existingIndex] = indicatorScoreObject;
                setIndicatorScore(newIndicatorScore);
            } else {
                setIndicatorScore([...indicatorScore, indicatorScoreObject]);
            }
        }
    }

    const addScoreToParents = (indicators, scores, idField, auditField) => {
        indicators.forEach(indicator => {
            const child = scores.find(score => score[auditField] === indicator[auditField] && score[idField] === indicator[idField] && score.checkListId === indicator.checkListId);
            indicator.score = child ? child.score : null;
        });
    }
    const handleCheckListRatingsSave = () => {
        if(audit.area.length > 1) {
            const tempAuditRateIndicator = auditRateIndicator.reduce((acc, current) => {
                const exists = acc.some(item => item.indicatorId === current.indicatorId && item.areaId === current.areaId);
                return exists ? acc : [...acc, current];
            }, []);

            const tempAuditRateSpecificIndicator = auditRateSpecificIndicator.reduce((acc, current) => {
                const exists = acc.some(item => item.specificIndicatorId === current.specificIndicatorId && item.areaId === current.areaId);
                return exists ? acc : [...acc, current];
            }, []);

            const tempAuditRateIndicatorScore = removeDuplicates(auditRateIndicatorScore)
            addScoreToParents(tempAuditRateIndicator, tempAuditRateIndicatorScore, "indicatorId", "areaId");

            const tempAuditRateSpecificIndicatorScore = removeSpecificDuplicates(auditRateSpecificIndicatorScore);
            addScoreToParents(tempAuditRateSpecificIndicator, tempAuditRateSpecificIndicatorScore, "specificIndicatorId", "areaId");

            handleCheckListRatingsApiSave(tempAuditRateIndicator, tempAuditRateSpecificIndicator);
        }

        if(audit.line.length > 1) {
            const tempAuditRateIndicator = auditRateIndicator.reduce((acc, current) => {
                const exists = acc.some(item => item.indicatorId === current.indicatorId && item.lineId === current.lineId);
                return exists ? acc : [...acc, current];
            }, []);

            const tempAuditRateSpecificIndicator = auditRateSpecificIndicator.reduce((acc, current) => {
                const exists = acc.some(item => item.specificIndicatorId === current.specificIndicatorId && item.lineId === current.lineId);
                return exists ? acc : [...acc, current];
            }, []);

            const tempAuditRateIndicatorScore = removeDuplicates(auditRateIndicatorScore)
            addScoreToParents(tempAuditRateIndicator, tempAuditRateIndicatorScore, "indicatorId", "lineId");

            const tempAuditRateSpecificIndicatorScore = removeSpecificDuplicates(auditRateSpecificIndicatorScore);
            addScoreToParents(tempAuditRateSpecificIndicator, tempAuditRateSpecificIndicatorScore, "specificIndicatorId", "lineId");

            handleCheckListRatingsApiSave(tempAuditRateIndicator, tempAuditRateSpecificIndicator);
        }

        if(audit.machine.length > 0) {
            const tempAuditRateIndicator = auditRateIndicator.reduce((acc, current) => {
                const exists = acc.some(item => item.indicatorId === current.indicatorId && item.machineId === current.machineId);
                return exists ? acc : [...acc, current];
            }, []);

            const tempAuditRateSpecificIndicator = auditRateSpecificIndicator.reduce((acc, current) => {
                const exists = acc.some(item => item.specificIndicatorId === current.specificIndicatorId && item.machineId === current.machineId);
                return exists ? acc : [...acc, current];
            }, []);

            const tempAuditRateIndicatorScore = removeDuplicates(auditRateIndicatorScore)
            addScoreToParents(tempAuditRateIndicator, tempAuditRateIndicatorScore, "indicatorId", "machineId");

            const tempAuditRateSpecificIndicatorScore = removeSpecificDuplicates(auditRateSpecificIndicatorScore);
            addScoreToParents(tempAuditRateSpecificIndicator, tempAuditRateSpecificIndicatorScore, "specificIndicatorId", "machineId");

            handleCheckListRatingsApiSave(tempAuditRateIndicator, tempAuditRateSpecificIndicator);
        }
    }
    const handleCheckListRatingsApiSave = (auditRateIndicator, auditRateSpecificIndicator) => {
        auditRateIndicator.forEach(rateIndicator => {
            const tempFullIndicatorRateJson = auditRateIndicator.filter(indicator => indicator.checkListId === rateIndicator.checkListId);
            const tempFullSpecificIndicatorRateJson = auditRateSpecificIndicator.filter(specificIndicator => specificIndicator.checkListId === rateIndicator.checkListId);
            const fullRateJson = tempFullIndicatorRateJson.concat(tempFullSpecificIndicatorRateJson);

            const object = {
                "auditId": auditId,
                "checkListId": rateIndicator.checkListId,
                "compiledIndicatorsJson": JSON.stringify(fullRateJson)
            };
            fetch(ApiUrl.auditEndpoint + "/CheckListAudit", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(object),
            })
        });
    }

    const changeSelectedAuditButton = (auditButtonId, value, item) => {
        setAuditButtonSelectedId(auditButtonId);
        setMachineOrAreaCode(value);
        getCheckListCompiledIndicators(auditButtonId);
        //added
        if (item === 'area') {
            setSelectedAreaId(auditButtonId)
        }
        if (item === 'line') {
            setSelectedLineId(auditButtonId)
        }
        if (item === 'machine') {
            setSelectedMachineId(auditButtonId)
        }
    }
    const renderAuditButtons = () => {
        //added
        if (audit === undefined) {
            return;
        }

        if(audit.area.length > 1) {
            return (
                <>
                    {audit.area.map(area =>
                        <Button key={area.id} variant={area.id === auditButtonSelectedId ? "contained" : "outlined"} disableElevation style={{ marginRight: '15px', marginBottom: '15px' }} onClick={() => changeSelectedAuditButton(area.id, `${area.name}${area.code ? ` (${area.code})` : ''}`, 'area')}>
                            {area.id === auditButtonSelectedId ? <RadioButtonCheckedIcon style={{ marginRight: 10 }} /> : <RadioButtonUncheckedIcon style={{ marginRight: 10 }} />}
                            {`${area.name}${area.code ? ` (${area.code})` : ''}`}
                        </Button>
                    )}
                </>
            )
        }

        if(audit.line.length > 1) {
            return (
                <>
                    {audit.line.map(line =>
                        <Button key={line.id} variant={line.id === auditButtonSelectedId ? "contained" : "outlined"} disableElevation style={{ marginRight: '15px', marginBottom: '15px' }} onClick={() => changeSelectedAuditButton(line.id, `${line.name}${line.code ? ` (${line.code})` : ''}`, 'line')}>
                            {line.id === auditButtonSelectedId ? <RadioButtonCheckedIcon style={{ marginRight: 10 }} /> : <RadioButtonUncheckedIcon style={{ marginRight: 10 }} />}
                            {`${line.name}${line.code ? ` (${line.code})` : ''}`}
                        </Button>
                    )}
                </>
            )
        }

        if(audit.machine.length > 1) {
            return (
                <>
                    {audit.machine.map(machine =>
                        <Button key={machine.id} variant={machine.id === auditButtonSelectedId ? "contained" : "outlined"} disableElevation style={{ marginRight: '15px', marginBottom: '15px' }} onClick={() => changeSelectedAuditButton(machine.id, `${machine.name}${machine.code ? ` (${machine.code})` : ''}`, 'machine')}>
                            {machine.id === auditButtonSelectedId ? <RadioButtonCheckedIcon style={{ marginRight: 10 }} /> : <RadioButtonUncheckedIcon style={{ marginRight: 10 }} />}
                            {`${machine.name}${machine.code ? ` (${machine.code})` : ''}`}
                        </Button>
                    )}
                </>
            )
        }

        if(audit.machine.length === 1) {
            return (
                <>
                    {audit.machine.map(machine =>
                        <Button key={machine.id} variant={machine.id === auditButtonSelectedId ? "contained" : "outlined"} disableElevation style={{ marginRight: '15px', marginBottom: '15px' }} onClick={() => changeSelectedAuditButton(machine.id, `${machine.name}${machine.code ? ` (${machine.code})` : ''}`)}>
                            {machine.id === auditButtonSelectedId ? <RadioButtonCheckedIcon style={{ marginRight: 10 }} /> : <RadioButtonUncheckedIcon style={{ marginRight: 10 }} />}
                            {`${machine.name}${machine.code ? ` (${machine.code})` : ''}`}
                        </Button>
                    )}
                </>
            )
        }
    }

    const showScoreForIndicator = (indicatorId) => {
        let score = "";

        const tempAuditRateIndicatorScore = removeDuplicates(auditRateIndicatorScore)

        if(audit.area.length > 1) {
            if(tempAuditRateIndicatorScore.filter(score =>
                score.indicatorId !== undefined &&
                score.areaId !== undefined &&
                score.indicatorId === indicatorId &&
                score.areaId === auditButtonSelectedId).length > 0)
            {
                score = tempAuditRateIndicatorScore.filter(score =>
                    score.indicatorId !== undefined &&
                    score.areaId !== undefined &&
                    score.indicatorId === indicatorId &&
                    score.areaId === auditButtonSelectedId)[0].score 
            }
        }

        if(audit.line.length > 1) {
            if(tempAuditRateIndicatorScore.filter(score =>
                score.indicatorId !== undefined &&
                score.lineId !== undefined &&
                score.indicatorId === indicatorId &&
                score.lineId === auditButtonSelectedId).length > 0)
            {
                score = tempAuditRateIndicatorScore.filter(score =>
                    score.indicatorId !== undefined &&
                    score.lineId !== undefined &&
                    score.indicatorId === indicatorId &&
                    score.lineId === auditButtonSelectedId)[0].score 
            }
        }

        if(audit.machine.length > 0) {
            if(tempAuditRateIndicatorScore.filter(score =>
                score.indicatorId !== undefined &&
                score.machineId !== undefined &&
                score.indicatorId === indicatorId &&
                score.machineId === auditButtonSelectedId).length > 0)
            {
                score = tempAuditRateIndicatorScore.filter(score =>
                    score.indicatorId !== undefined &&
                    score.machineId !== undefined &&
                    score.indicatorId === indicatorId &&
                    score.machineId === auditButtonSelectedId)[0].score 
            }
        }

        return score;
    }
    const showScoreForSpecificIndicator = (specificIndicatorId) => {
        let score = "";

        const tempAuditRateSpecificIndicatorScore = removeSpecificDuplicates(auditRateSpecificIndicatorScore)

        if(audit.area.length > 1) {
            if(tempAuditRateSpecificIndicatorScore.filter(score =>
                score.specificIndicatorId !== undefined &&
                score.areaId !== undefined &&
                score.specificIndicatorId === specificIndicatorId &&
                score.areaId === auditButtonSelectedId).length > 0)
            {
                score = tempAuditRateSpecificIndicatorScore.filter(score =>
                    score.specificIndicatorId !== undefined &&
                    score.areaId !== undefined &&
                    score.specificIndicatorId === specificIndicatorId &&
                    score.areaId === auditButtonSelectedId)[0].score 
            }
        }

        if(audit.line.length > 1) {
            if(tempAuditRateSpecificIndicatorScore.filter(score =>
                score.specificIndicatorId !== undefined &&
                score.lineId !== undefined &&
                score.specificIndicatorId === specificIndicatorId &&
                score.lineId === auditButtonSelectedId).length > 0)
            {
                score = tempAuditRateSpecificIndicatorScore.filter(score =>
                    score.specificIndicatorId !== undefined &&
                    score.lineId !== undefined &&
                    score.specificIndicatorId === specificIndicatorId &&
                    score.lineId === auditButtonSelectedId)[0].score 
            }
        }

        if(audit.machine.length > 0) {
            if(tempAuditRateSpecificIndicatorScore.filter(score =>
                score.specificIndicatorId !== undefined &&
                score.machineId !== undefined &&
                score.specificIndicatorId === specificIndicatorId &&
                score.machineId === auditButtonSelectedId).length > 0)
            {
                score = tempAuditRateSpecificIndicatorScore.filter(score =>
                    score.specificIndicatorId !== undefined &&
                    score.machineId !== undefined &&
                    score.specificIndicatorId === specificIndicatorId &&
                    score.machineId === auditButtonSelectedId)[0].score 
            }
        }

        return score;
    }
    const getCheckListCompiledIndicators = (auditButtonId) => {
        setIndicatorSelected({});
        setSpecificIndicatorSelected({});

        const newIndicatorSelected = {};
        const newSpecificIndicatorSelected = {};

        auditRateIndicator.forEach(rateIndicator => {
            if(audit.area.length > 1) {
                if(rateIndicator.indicatorId !== undefined && rateIndicator.areaId === auditButtonId) {
                    newIndicatorSelected[rateIndicator.indicatorId] = rateIndicator.rateId;
                }
            }
            if(audit.line.length > 1) {
                if(rateIndicator.indicatorId !== undefined && rateIndicator.lineId === auditButtonId) {
                    newIndicatorSelected[rateIndicator.indicatorId] = rateIndicator.rateId;
                }
            }
            if(audit.machine.length > 0) {
                if(rateIndicator.indicatorId !== undefined && rateIndicator.machineId === auditButtonId) {
                    newIndicatorSelected[rateIndicator.indicatorId] = rateIndicator.rateId;
                }
            }
        });

        auditRateSpecificIndicator.forEach(rateSpecificIndicator => {
            if(audit.area.length > 1) {
                if(rateSpecificIndicator.specificIndicatorId !== undefined && rateSpecificIndicator.areaId === auditButtonId) {
                    newSpecificIndicatorSelected[rateSpecificIndicator.specificIndicatorId] = rateSpecificIndicator.rateId;
                }
            }
            if(audit.line.length > 1) {
                if(rateSpecificIndicator.specificIndicatorId !== undefined && rateSpecificIndicator.lineId === auditButtonId) {
                    newSpecificIndicatorSelected[rateSpecificIndicator.specificIndicatorId] = rateSpecificIndicator.rateId;
                }
            }
            if(audit.machine.length > 0) {
                if(rateSpecificIndicator.specificIndicatorId !== undefined && rateSpecificIndicator.machineId === auditButtonId) {
                    newSpecificIndicatorSelected[rateSpecificIndicator.specificIndicatorId] = rateSpecificIndicator.rateId;
                }
            }
        });

        setIndicatorSelected(newIndicatorSelected);
        setSpecificIndicatorSelected(newSpecificIndicatorSelected);
    }
    const calculateIndicatorScore = (indicatorId) => {
        const specificIndicators = checkListSpecificIndicatorList.filter(si => si.checkListIndicator.id === indicatorId);
        const specificIndicatorScores = [];
        specificIndicators.forEach(si => {
            if (indicatorScore.find(score => score.specificIndicatorId === si.id) !== undefined) {
                specificIndicatorScores.push(parseFloat(indicatorScore.find(score => score.specificIndicatorId === si.id).score))
            }
        });
        return (specificIndicatorScores.reduce((a,v) =>  a = a + v, 0) * (checkListIndicatorList.filter(indicator => indicator.id === indicatorId)[0].weight / 100)).toFixed(2)
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" style={{ marginBottom: 20 }}>
                <Button variant="contained" disableElevation onClick={navigateBack}>
                    <ArrowBackIcon />
                </Button>
                <Typography variant="h4" style={{ marginLeft: 30 }}>Compile Audit's CheckList</Typography>
            </Box>
            {checkListList.length === 0 ?
                <Typography variant="h6" style={{ marginRight: 30 }}>No CheckList for this Audit</Typography>
                :
                <DataGrid
                    style={{ backgroundColor: appColor.white, marginBottom: 30 }}
                    rows={checkListList}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5
                            },
                        },
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        handleCheckListRowSelected(newRowSelectionModel);
                    }}
                    slots={{
                        toolbar: CustomToolbar,
                    }}
                    pageSizeOptions={[5]}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                />
            }
            {checkListIdSelectedRow === 0 ? '' :
                <Grid container spacing={3}>
                    <Grid item md={2}>
                        {renderAuditButtons()}
                    </Grid>
                    <Grid item md={10}>
                        <Paper elevation={0} style={{ padding: "15px", border: "1px solid lightgray" }}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>N</TableCell>
                                            <TableCell>Indicator</TableCell>
                                            <TableCell>Weighting (%)</TableCell>
                                            <TableCell>Rating</TableCell>
                                            <TableCell>Score</TableCell>
                                            <TableCell>Corrective Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {checkListIndicatorList.map((indicator) =>
                                            <React.Fragment key={`fr${indicator.id}`}>
                                                <TableRow key={indicator.id} style={{ backgroundColor: '#DDDDDD' }}>
                                                    <TableCell style={{ width: 100, fontWeight: 'bold' }}>{indicator.item}</TableCell>
                                                    <TableCell style={{ width: 250, fontWeight: 'bold' }}>{indicator.description}</TableCell>
                                                    <TableCell style={{ width: 150, fontWeight: 'bold' }}>{indicator.weight}%</TableCell>
                                                    <TableCell style={{ width: 400, fontWeight: 'bold' }}>
                                                        {checkListRateList.filter(rate => rate.checkListIndicator !== undefined && rate.checkListIndicator.id === indicator.id).length > 0 ? 
                                                            <FormControl size="small" fullWidth>
                                                                <Select
                                                                    value={indicatorSelected[indicator.id] || ''}
                                                                    onChange={(event) => handleFormSelectedIndicatorChange(event, indicator.id)}
                                                                >
                                                                {checkListRateList.filter(rate => rate.checkListIndicator !== undefined && rate.checkListIndicator.id === indicator.id)
                                                                    .sort((a,b) => a.rate - b.rate)
                                                                    .map(rate => <MenuItem key={rate.id} value={rate.id}>{rate.rate} - {rate.description}</MenuItem>)}
                                                                </Select>
                                                            </FormControl>
                                                        : ""}
                                                    </TableCell>
                                                    <TableCell style={{ width: 150 }}>
                                                    {checkListRateList.filter(rate => rate.checkListIndicator !== undefined && rate.checkListIndicator.id === indicator.id).length > 0 ? 
                                                        showScoreForIndicator(indicator.id) : calculateIndicatorScore(indicator.id)}
                                                    </TableCell>
                                                    <TableCell style={{ width: 150, fontWeight: 'bold' }}>
                                                        {hasCorrectiveAction(indicator, 'indicator') ?
                                                            <>
                                                                <Tooltip title="Edit Corrective Action" arrow>
                                                            {getCheckColor(indicator, 'indicator') === '#2e7d32' ?
                                                                <EngineeringIcon style={{ cursor: 'not-allowed', marginLeft: 15 }} />
                                                                        :
                                                                <EngineeringIcon onClick={() => handleCompileModalOpen(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                            }
                                                        </Tooltip>
                                                        <Tooltip title="View Corrective Action Details" arrow>
                                                                    <TaskAltIcon onClick={() => handleCompileModalOpenViewOnly(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15, color: getCheckColor(indicator, 'indicator') }} />
                                                                </Tooltip>
                                                            </>
                                                            :
                                                            <>
                                                                <Tooltip title="Add Corrective Action" arrow>
                                                                    <EngineeringIcon onClick={() => handleCompileModalOpen(indicator, 'indicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                </Tooltip>
                                                            </>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                {checkListSpecificIndicatorList
                                                    .filter(specificIndicator => specificIndicator.checkListIndicator.id === indicator.id)
                                                    .map((specificIndicator) => 
                                                        <TableRow key={specificIndicator.id} style={{ backgroundColor: '#EEEEEE' }}>
                                                            <TableCell style={{ width: 100 }}>{specificIndicator.item}</TableCell>
                                                            <TableCell style={{ width: 250 }}>{specificIndicator.description}</TableCell>
                                                            <TableCell style={{ width: 150 }}>{specificIndicator.weight}%</TableCell>
                                                            <TableCell style={{ width: 400 }}>
                                                                <FormControl size="small" fullWidth>
                                                                    <Select
                                                                        value={specificIndicatorSelected[specificIndicator.id] || ''}
                                                                        onChange={(event) => handleFormSelectedSpecificIndicatorChange(event, specificIndicator.id)}
                                                                    >
                                                                        {checkListRateList.filter(rate => rate.checkListSpecificIndicator !== undefined && rate.checkListSpecificIndicator.id === specificIndicator.id)
                                                                            .sort((a,b) => a.rate - b.rate)
                                                                            .map(rate => <MenuItem key={rate.id} value={rate.id}>{rate.rate} - {rate.description}</MenuItem>)}
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell style={{ width: 150 }}>
                                                                {showScoreForSpecificIndicator(specificIndicator.id)}
                                                            </TableCell>
                                                            <TableCell style={{ width: 150, fontWeight: 'bold' }}>
                                                                {hasCorrectiveAction(specificIndicator, 'specificIndicator') ?
                                                                    <>
                                                                        <Tooltip title="Edit Corrective Action" arrow>
                                                                    {getCheckColor(specificIndicator, 'specificIndicator') === '#2e7d32' ?
                                                                        <EngineeringIcon style={{ cursor: 'not-allowed', marginLeft: 15 }} />
                                                                                :
                                                                        <EngineeringIcon onClick={() => handleCompileModalOpen(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                    }
                                                                </Tooltip>
                                                                <Tooltip title="View Corrective Action Details" arrow>
                                                                            <TaskAltIcon onClick={() => handleCompileModalOpenViewOnly(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15, color: getCheckColor(specificIndicator, 'specificIndicator') }} />
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title="Add Corrective Action" arrow>
                                                                            <EngineeringIcon onClick={() => handleCompileModalOpen(specificIndicator, 'specificIndicator')} style={{ cursor: 'pointer', marginLeft: 15 }} />
                                                                        </Tooltip>
                                                                    </>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                }
                                            </React.Fragment>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            }
            <Modal
                open={isCompileModalOpen}
                onClose={handleCompileModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography style={{ color: '#1976d2', fontWeight: 'bold' }} id="modal-modal-title" variant="h5" component="h5">{checkListSelected ? checkListSelected.description : ''}</Typography>
                        <Typography style={{ color: '#1976d2', fontWeight: 'bold' }} id="modal-modal-title" variant="h5" component="h5">Code: {machineOrAreaCode}</Typography>
                    </Box>
                    <Typography style={{ color: '#1976d2' }} id="modal-modal-title" variant="h6" component="h6">{indiCompileSelected ? `${indiCompileSelected.item} - ${indiCompileSelected.description}` : ''}</Typography>

                    <Box marginTop={3} display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <TextField
                                        label="Lead Auditor"
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                        style={{ width: 325 }}
                                        fullWidth
                                        value={audit ? audit.leadAuditor.email : ''}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box marginLeft={3} paddingTop={1}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="Date"
                                            readOnly={viewOnly}
                                            value={formData.date}
                                            onChange={(date) => handleDateChange(date, 'date')}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                            <Box>
                                <TextField
                                    label="Auditor Remarks"
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: 570 }}
                                    name="auditorRemarks"
                                    value={formData.auditorRemarks}
                                    onChange={handleChange}
                                    disabled={viewOnly}
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    error={Boolean(errors.auditorRemarks)}
                                    helperText={errors.auditorRemarks}
                                />
                            </Box>
                        </Box>
                        <Box display="flex">
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    component="label"
                                    disabled={viewOnly}
                                    tabIndex={-1}
                                >
                                    {formData.images.image1 !== null ?
                                        <img src={formData.images.image1} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }

                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 1)} />
                                </Button>

                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    disabled={viewOnly}
                                    component="label"
                                    tabIndex={-1}
                                >
                                    {formData.images.image2 !== null ?
                                        <img src={formData.images.image2} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }
                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 2)} />
                                </Button>

                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    disabled={viewOnly}
                                    component="label"
                                    tabIndex={-1}
                                >
                                    {formData.images.image3 !== null ?
                                        <img src={formData.images.image3} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }
                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 3)} />
                                </Button>

                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    disabled={viewOnly}
                                    component="label"
                                    tabIndex={-1}
                                >
                                    {formData.images.image4 !== null ?
                                        <img src={formData.images.image4} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }
                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 4)} />
                                </Button>

                            </Box>
                            <Box marginTop={3} marginLeft={3} display="flex" alignItems="center">
                                <Button
                                    style={buttonStyle}
                                    role={undefined}
                                    disabled={viewOnly}
                                    component="label"
                                    tabIndex={-1}
                                >
                                    {formData.images.image5 !== null ?
                                        <img src={formData.images.image5} alt='' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                        : <AddAPhotoIcon style={{ fontSize: '3rem' }} />
                                    }
                                    <VisuallyHiddenInput accept="image/*" id={`image-upload-1`} type="file" onChange={(event) => handleImageChange(event, 5)} />
                                </Button>

                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Box>
                            <TextField
                                label="Action Requested By Auditor (syntetic)"
                                variant="outlined"
                                margin="normal"
                                name="actionRequestedByAuditor"
                                value={formData.actionRequestedByAuditor}
                                disabled={viewOnly}
                                onChange={handleChange}
                                style={{ width: 570 }}
                                fullWidth
                                error={Boolean(errors.actionRequestedByAuditor)}
                                helperText={errors.actionRequestedByAuditor}
                            />
                        </Box>
                        <Box marginLeft={3}>
                            <InputLabel style={{ fontSize: '0.7rem' }} id="demo-select-small-label">Responsible</InputLabel>
                            <Select
                                style={{ width: 325 }}
                                fullWidth
                                value={formData.responsabile}
                                name="responsabile"
                                onChange={handleChange}
                                error={Boolean(errors.responsabile)}
                                readOnly={viewOnly}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {users.map((user) => <MenuItem key={user.id} value={user.email}>{user.email}</MenuItem>)}
                            </Select>
                            {Boolean(errors.responsabile) && (
                                <p className={'MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root'}>
                                    {errors.responsabile}
                                </p>
                            )}
                        </Box>
                        <Box marginLeft={3} paddingTop={2}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Due Date"
                                    style={{ width: 200 }}
                                    value={formData.dueDate}
                                    onChange={(date) => handleDateChange(date, 'dueDate')}
                                    readOnly={viewOnly}
                                />
                                {Boolean(errors.dueDate) && (
                                    <p className={'MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root'}>
                                        {errors.dueDate}
                                    </p>
                                )}
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center">

                        <Box>
                            <TextField
                                label="Reply of responsible on action"
                                variant="outlined"
                                margin="normal"
                                style={{ width: 325 }}
                                value={formData.replyOfResponsibleOnAction}
                                disabled
                                fullWidth
                            />
                        </Box>
                        <Box marginLeft={3}>
                            <TextField
                                label="Reply acceptance by auditor"
                                variant="outlined"
                                margin="normal"
                                style={{ width: 325 }}
                                value={formData.replyAcceptanceByAuditor}
                                disabled
                                fullWidth
                            />
                        </Box>
                    </Box>
                    {viewOnly ?
                        <Box marginTop={3} display="flex" alignItems="center">
                            <Button variant="contained" color="info" disableElevation onClick={handleCompileModalClose}>Close</Button>
                        </Box>
                        :
                        <Box marginTop={3} display="flex" alignItems="center">
                            <Button variant="contained" color="success" disableElevation onClick={handleSubmit} style={{ marginRight: 15 }}><SaveIcon style={{ marginRight: 15 }} />
                                {formData.id === null ? 'Save' : 'Update'}
                            </Button>
                            <Button variant="contained" color="error" disableElevation onClick={handleCompileModalClose}>Cancel</Button>
                        </Box>
                    }

                </Box>
            </Modal>

            <Box marginTop={3}>
                <Button variant="contained" color="success" disableElevation style={{ marginRight: '15px' }} onClick={handleCheckListRatingsSave}>
                    <SaveIcon style={{ marginRight: '15px' }} />Save
                </Button>
            </Box>
        </Box>
    );
}

export default CompileAuditCheckListPage;
