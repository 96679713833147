export class AuditCheckListCorrectiveAction {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, auditCheckListId, indicatorId, leadAuditor, date, auditorRemarks, actionRequestedByAuditor,
        responsabile, dueDate, replyOfResponsibleOnAction, replyAcceptanceByAuditor, reasonForRejection, image1, image2, image3, image4, image5,
                areaId, lineId, machineId, asset, status) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.auditCheckListId = auditCheckListId;
        this.indicatorId = indicatorId;
        this.leadAuditor = leadAuditor;
        this.date = date;
        this.auditorRemarks = auditorRemarks;
        this.actionRequestedByAuditor = actionRequestedByAuditor;
        this.responsabile = responsabile;
        this.dueDate = dueDate;
        this.replyOfResponsibleOnAction = replyOfResponsibleOnAction;
        this.replyAcceptanceByAuditor = replyAcceptanceByAuditor;
        this.reasonForRejection = reasonForRejection;
        this.image1 = image1;
        this.image2 = image2;
        this.image3 = image3;
        this.image4 = image4;
        this.image5 = image5;
        this.areaId = areaId;
        this.lineId = lineId;
        this.machineId = machineId;
        this.asset = asset;
        this.status = status;
    }

    static fromJSON(json) {
        return new AuditCheckListCorrectiveAction(
            json.id,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.auditCheckListId,
            json.indicatorId,
            json.leadAuditor,
            json.date,
            json.auditorRemarks,
            json.actionRequestedByAuditor,
            json.responsabile,
            json.dueDate,
            json.replyOfResponsibleOnAction,
            json.replyAcceptanceByAuditor,
            json.reasonForRejection,
            json.image1,
            json.image2,
            json.image3,
            json.image4,
            json.image5,
            json.areId,
            json.lineId,
            json.machineId,
            json.asset,
            json.status
        );
    }
}
