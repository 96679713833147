export class Role {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, name) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.name = name;
    }

    static fromJSON(json) {
        return new Role(
            json.id,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.name
        );
    }
}