export class Plant {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, code, description, timeZone) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.code = code;
        this.description = description;
        this.timeZone = timeZone;
    }

    static fromJSON(json) {
        return new Plant(
            json.id,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.code,
            json.description,
            json.timeZone
        );
    }
}