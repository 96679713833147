export class CheckList {
    constructor(id, created, createdBy, modified, modifiedBy, deleted, ownerUser, status, type, subject, code, description) {
        this.id = id;
        this.created = created;
        this.createdBy = createdBy;
        this.modified = modified;
        this.modifiedBy = modifiedBy;
        this.deleted = deleted;
        this.ownerUser = ownerUser;
        this.status = status;
        this.type = type;
        this.subject = subject;
        this.code = code;
        this.description = description;
    }

    static fromJSON(json) {
        return new CheckList(
            json.id,
            json.created,
            json.createdBy,
            json.modified,
            json.modifiedBy,
            json.deleted,
            json.ownerUser,
            json.status,
            json.type,
            json.subject,
            json.code,
            json.description
        )
    }
}